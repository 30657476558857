import React from "react";
import { Button, HStack, ScrollView, Text, VStack } from "native-base";
import { PostReturnDto } from "../../services/api-service-sub-services/studies-api-service";
import { PostContainer } from "./post.container";
import { PostComponent } from "./post.component";
import { AntDesign } from "@expo/vector-icons";

const Post = PostContainer(PostComponent);

export type SinglePostScreenProps = {
  post?: PostReturnDto;
  onBackButtonPress: () => void;
  highlightedCommentId?: string;
};

export const SinglePostScreen = (props: SinglePostScreenProps) => {
  return (
    <VStack variant="scrollable-screen" safeAreaTop>
      <HStack
        px="1"
        pb="3"
        alignItems="center"
        justifyContent="space-between"
        borderBottomWidth="1"
        borderBottomColor="xLightGrey"
      >
        <Button variant="unstyled" onPress={props.onBackButtonPress}>
          <HStack space="0.5" alignItems="center">
            <AntDesign name="left" size={20} />
            <Text variant="small">Back</Text>
          </HStack>
        </Button>
      </HStack>
      <ScrollView
        automaticallyAdjustKeyboardInsets
        keyboardDismissMode="on-drag"
        keyboardShouldPersistTaps="handled"
      >
        {props.post && (
          <Post
            post={props.post}
            isCommentSectionVisibleByDefault={true}
            highlightedCommentId={props.highlightedCommentId}
          />
        )}
      </ScrollView>
    </VStack>
  );
};
