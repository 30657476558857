import React, { useEffect, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { selectUserProfile, selectUserStudyId } from "../../store/user.slice";
import {
  useStudiesControllerGetParticipantCodeQuery,
  useStudiesControllerGetStudyQuery,
} from "../../services/api-service-sub-services/studies-api-service";
import { NativeStackScreenProps } from "react-native-screens/native-stack";
import { AppStackParamList } from "../../navigation/navigators/app-stack.navigator";
import { Routes } from "../../navigation/routes";
import { ExitSurveyScreenProps } from "./exit-survey.screen";
import { useTrackEvent } from "../core/hooks/use-track-event";
import { events } from "../../constants/analytics.constants";
import { appSlice, selectTreatmentArmTitle } from "../../store/app.slice";
import { selectTriggeredPopups } from "../../store/analytics.slice";

type ContainerProps = NativeStackScreenProps<
  AppStackParamList,
  Routes.APP_STACK__EXIT_SURVEY
> & {};
export const ExitSurveyContainer = (
  Screen: React.FC<ExitSurveyScreenProps>
) => {
  return function _(props: ContainerProps) {
    const studyId = useAppSelector(selectUserStudyId);
    const profile = useAppSelector(selectUserProfile);
    const track = useTrackEvent();
    const { data: study } = useStudiesControllerGetStudyQuery(
      {
        id: studyId!,
      },
      {
        skip: !studyId,
        refetchOnMountOrArgChange: true,
      }
    );
    const treatmentArmTitle = useAppSelector(selectTreatmentArmTitle);
    const triggeredPopups = useAppSelector(selectTriggeredPopups);

    const participantCode = profile?.participantCode;

    const dispatch = useAppDispatch();

    useEffect(() => {
      track(events.userStartedSurvey, {
        type: "exit_survey",
      });

      dispatch(
        appSlice.actions.notificationFeatureConfigurationReceived({
          status: "disabled",
        })
      );
    }, []);

    useEffect(() => {
      props.navigation.addListener("beforeRemove", (e) => {
        e.preventDefault();
      });
    }, [props.navigation]);

    const { data: polledCode, isLoading: isPolledCodeLoading } =
      useStudiesControllerGetParticipantCodeQuery(
        {
          id: studyId!,
          code: participantCode!,
        },
        {
          pollingInterval: 1200,
          skip: !studyId || !participantCode,
        }
      );

    const qualtricsSurveyUrl = useMemo(() => {
      if (
        participantCode &&
        study?.exitSurveyId &&
        profile?.treatmentArm &&
        treatmentArmTitle !== "" // make sure that the treatment arm title is not empty
      ) {
        return `https://duke.qualtrics.com/jfe/form/${
          study.exitSurveyId
        }?participant_code=${participantCode}&treatment_arm_id=${
          profile.treatmentArm
        }&treatment_arm_name=${treatmentArmTitle}&triggered_popups=${triggeredPopups.join(
          ", "
        )}`;
      }
      return undefined;
    }, [participantCode, study, profile, treatmentArmTitle]);

    // redirect the user to another screen telling them they can logout
    useEffect(() => {
      if (study && !study.exitSurveyId) {
        props.navigation.navigate(Routes.APP_STACK__PENDING);
      }
    }, [study]);

    // redirect the user to another screen telling them they can logout
    useEffect(() => {
      if (polledCode && polledCode.isUsedForExitSurvey) {
        track(events.userCompletedSurvey);
        props.navigation.navigate(Routes.APP_STACK__PENDING);
      }
    }, [polledCode]);

    return (
      <Screen
        qualtricsSurveyURL={qualtricsSurveyUrl}
        isLoading={isPolledCodeLoading || !qualtricsSurveyUrl}
      />
    );
  };
};
