import { useAppDispatch, useAppSelector } from "../../../store/store";
import { selectUserId, userSlice } from "../../../store/user.slice";
import { useLazyUsersControllerGetProfileQuery } from "../../../services/api-service-sub-services/users-api-service";

export const useUpdateUserProfile = () => {
  const userId = useAppSelector(selectUserId);
  const dispatch = useAppDispatch();
  const [getUserProfile] = useLazyUsersControllerGetProfileQuery({
    selectFromResult: () => ({}),
  });
  return async () => {
    if (!userId) {
      return;
    }
    const profile = await getUserProfile({ id: userId }).unwrap();
    dispatch(userSlice.actions.userProfileFetched(profile));
  };
};
