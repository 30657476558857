import React, { useCallback, useRef, useState } from "react";
import {
  AspectRatio,
  Box,
  Button,
  CloseIcon,
  Column,
  HStack,
  Image,
  ScrollView,
  Text,
  TextArea,
  useTheme,
  View,
  VStack,
} from "native-base";
import { ParticipantProfileWithPropertiesDto } from "../../services/api-service-sub-services/users-api-service";
import { ProfileAvatar } from "../core/components/profile-avatar";
import dayjs from "dayjs";
import { Keyboard, Platform, TouchableOpacity } from "react-native";
import { RFValue } from "react-native-responsive-fontsize";
import { extractURLs } from "../core/utils/url.util";
import { LinkPreviewComponent } from "./link-preview.component";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { analyticsSlice, Modules } from "../../store/analytics.slice";
import { selectUserProfile, selectUserStudyId } from "../../store/user.slice";
import { useProfileValues } from "../profile/hooks/use-profile-values";
import { AntDesign, FontAwesome } from "@expo/vector-icons";
import { useMentions } from "react-native-controlled-mentions";
import { MentionSuggestionsList } from "./components/mentions-suggestion-list";
import { useFeatureAvailability } from "../core/hooks/use-feature-availability";

export type NewPostComponentProps = {
  profile?: ParticipantProfileWithPropertiesDto;
  onPostButtonPress: (content: string) => Promise<void>;
  onSelectPhotoButtonPress: () => Promise<void>;
  image: string;
  onRemovePhotoButtonPress: () => void;
  onCancelButtonPress: () => void;
};

export const NewPostScreen = (props: NewPostComponentProps) => {
  const dispatch = useAppDispatch();
  const profileValues = useProfileValues(props.profile?.properties);
  const debounceTimeout = 1200; // Set the duration (in milliseconds) to wait before triggering the action

  const isBotTaggingEnabled = useFeatureAvailability("bot-tagging");

  const studyId = useAppSelector(selectUserStudyId);
  const profile = useAppSelector(selectUserProfile);

  const debounceTimer = useRef<number>();
  const textAreaRef = useRef<HTMLTextAreaElement | null>(null);
  const postStartTimestampRef = useRef<string | null>(null);

  const [textAreaHeight, setTextAreaHeight] = useState<number>(30);
  const [postContent, setPostContent] = useState<string>("");
  const [url, setUrl] = useState<string | undefined>();
  const [isBusy, setIsBusy] = useState<boolean>(false);

  const theme = useTheme();

  const handleChangeText = (text: string) => {
    setPostContent(text);

    // Clear any previous timer
    if (debounceTimer.current) {
      clearTimeout(debounceTimer.current);
    }

    // Set a new timer to trigger the action after the specified duration
    debounceTimer.current = setTimeout(() => {
      const urls = extractURLs(text);
      if (urls.length > 0) {
        setUrl(urls[0]);
      } else {
        setUrl(undefined);
      }
    }, debounceTimeout) as any; // Use
  };

  const { textInputProps, triggers } = useMentions({
    value: postContent,
    onChange: handleChangeText,
    triggersConfig: {
      mention: {
        trigger: "@",
        textStyle: { fontWeight: "bold" },
      },
    },
  });

  const onClose = () => {
    Keyboard.dismiss();
    setPostContent("");
    textAreaRef.current?.blur();
    props.onCancelButtonPress();
  };

  const trackTimeWritingPost = useCallback(() => {
    if (postStartTimestampRef.current && profile?._id && studyId) {
      dispatch(
        analyticsSlice.actions.addItemToQueue({
          study: studyId,
          participant: profile._id,
          module: Modules.POSTS,
          meta: {
            description: "User time writing post",
            timestamp: dayjs.utc().toISOString(),
            timeWriting: Math.round(
              dayjs.utc().diff(postStartTimestampRef.current) / 1000
            ),
          },
        })
      );
    }

    postStartTimestampRef.current = null;
  }, [profile?._id, studyId]);

  return (
    <VStack variant="scrollable-screen" safeAreaTop>
      <HStack
        px="5"
        pt="5"
        pb="2"
        justifyContent="space-between"
        bg="white"
        borderBottomColor="medGrey"
        borderBottomWidth="1"
        alignItems="center"
      >
        <Box width="24" alignItems="flex-start">
          <TouchableOpacity
            onPress={() => {
              trackTimeWritingPost();
              onClose();
            }}
          >
            <CloseIcon />
          </TouchableOpacity>
        </Box>

        <Text bold alignSelf="center" variant="heading">
          Create Post
        </Text>
        <Box width="24" alignItems="flex-end" justifyContent="center">
          <Button
            isDisabled={postContent.length === 0}
            isLoading={isBusy}
            variant="subtle"
            _disabled={{
              opacity: 30,
            }}
            height="8"
            paddingY="0"
            alignItems="center"
            justifyContent="center"
            backgroundColor="primary"
            onPress={async () => {
              try {
                setIsBusy(true);
                trackTimeWritingPost();
                await props.onPostButtonPress(postContent);
                onClose();
              } catch (e: any) {
                const err = e as Error;
                console.error(err);
              } finally {
                setIsBusy(false);
              }
            }}
          >
            <Text
              color="white"
              variant="label"
              fontSize="10"
              bold
              letterSpacing="1"
            >
              POST
            </Text>
          </Button>
        </Box>
      </HStack>
      <ScrollView
        keyboardDismissMode="interactive"
        backgroundColor="#F4F6FA"
        flex="1"
      >
        <VStack space="5" px="5" pt="3">
          <HStack>
            <ProfileAvatar profile={props.profile} />
            <Column ml="3">
              <Text variant="heading">{profileValues.displayName}</Text>
              <Text variant="small" color="darkGrey">
                {dayjs().format("MMMM DD [at] h:mm A")}
              </Text>
            </Column>
          </HStack>
          <VStack space="4">
            <TextArea
              autoFocus
              px="0"
              ref={textAreaRef}
              placeholder="What do you want to share?"
              placeholderTextColor="darkGrey"
              h={textAreaHeight + 30}
              {...(Platform.OS !== "web" && {
                onContentSizeChange: (event) => {
                  setTextAreaHeight(event.nativeEvent.contentSize.height);
                },
              })}
              autoCompleteType={undefined}
              fontSize={RFValue(16, 750)}
              borderWidth="0"
              onFocus={() => {
                if (postStartTimestampRef.current === null) {
                  postStartTimestampRef.current = dayjs.utc().toISOString();
                }
              }}
              {...(isBotTaggingEnabled
                ? textInputProps
                : {
                    value: postContent,
                    onChangeText: handleChangeText,
                  })}
            />

            {/* conditionally renders the suggestion list of mentions based on the provided triggers */}
            <MentionSuggestionsList {...triggers.mention} />

            {url && (
              <View borderRadius="8px" overflow="hidden">
                <LinkPreviewComponent url={url} />
              </View>
            )}

            {props.image.length > 0 && (
              <View>
                <Box position="absolute" top={-7} left={-7} zIndex="10">
                  <TouchableOpacity onPress={props.onRemovePhotoButtonPress}>
                    <AntDesign
                      name="closecircle"
                      size={20}
                      color={theme.colors.primary as unknown as string}
                    />
                  </TouchableOpacity>
                </Box>
                <AspectRatio
                  alignSelf="flex-start"
                  borderRadius="8"
                  overflow="hidden"
                  width="40%"
                  ratio={1}
                >
                  <Image
                    src={props.image}
                    alt="post-image"
                    resizeMode="cover"
                  />
                </AspectRatio>
              </View>
            )}
          </VStack>
        </VStack>
      </ScrollView>

      <HStack position="absolute" bottom="5" left="5" space="3">
        {Platform.OS !== "web" && (
          <TouchableOpacity onPress={props.onSelectPhotoButtonPress}>
            <HStack
              alignItems="center"
              space="2"
              p="2"
              borderRadius="4"
              borderColor="darkGrey"
              borderWidth="1"
            >
              <FontAwesome
                name="photo"
                size={18}
                color={theme.colors.secondary as unknown as string}
              />
              <Text variant="small" fontSize="12" color="primary">
                Photo
              </Text>
            </HStack>
          </TouchableOpacity>
        )}
      </HStack>
    </VStack>
  );
};
