import {
  Box,
  Button,
  CloseIcon,
  HStack,
  IconButton,
  ScrollView,
  Text,
  useTheme,
  VStack,
} from "native-base";
import React, { useEffect, useMemo, useState } from "react";
import { ModalComponentProp } from "react-native-modalfy";
import { ModalStackParams } from "./modal-stack";
import { Dimensions } from "react-native";
import WebView from "react-native-webview";
import RenderHTML from "react-native-render-html";
import Constants from "expo-constants";
import IframeRenderer, { iframeModel } from "@native-html/iframe-plugin";
import BouncyCheckbox from "react-native-bouncy-checkbox/build/dist/BouncyCheckbox";

type Props = ModalComponentProp<ModalStackParams, void, "InstructionsModal">;
export const InstructionsModal = (props: Props) => {
  const { width } = Dimensions.get("window");
  const theme = useTheme();
  const [isConsentChecked, setIsConsentChecked] = useState(false);

  const filterContent = (content: string) => {
    let result = content;
    result = result.replace(/<p><br><\/p>/g, "");
    return result;
  };

  const { continueActionType, continueActionMeta } = props.modal.params ?? {};

  // set the default value of the [contentType] to HTML
  const contentType = props.modal.params?.type ?? "html";

  const handleClose = () => {
    props.modal.closeModal("InstructionsModal", props.modal.params?.onClose);
  };

  // close the modal automatically after the timer expires
  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (continueActionType === "time") {
      const additionalSeconds = continueActionMeta?.time || 5;
      timer = setTimeout(() => {
        handleClose();
      }, additionalSeconds * 1000);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [continueActionType, continueActionMeta]);

  const modalWidth = useMemo(() => width * 0.8, []);

  return (
    <VStack
      variant="screen"
      borderRadius="8"
      px="5"
      pt="3"
      pb="6"
      space="5"
      width={width * 0.95}
    >
      {/*only display the x icon once there is no configured continue button type*/}
      {!continueActionType && (
        <Box position="absolute" top="1" right="1" zIndex={10}>
          <IconButton
            icon={<CloseIcon color="medGrey" />}
            onPress={handleClose}
          />
        </Box>
      )}

      <VStack space="1">
        {contentType === "qualtrics" && (
          <Box height="600px" width="100%">
            <WebView
              incognito={true}
              source={{
                uri: `https://duke.qualtrics.com/jfe/form/${
                  props.modal.params!.instructions
                }`,
              }}
              style={{ height: 100, width: "100%" }}
              androidLayerType="software"
            />
          </Box>
        )}

        {contentType === "html" && (
          <ScrollView maxHeight="600px" width="100%">
            <RenderHTML
              WebView={WebView}
              contentWidth={width * 0.95 - 10}
              source={{
                html: filterContent(props.modal.params!.instructions),
              }}
              defaultTextProps={{
                style: {
                  fontSize: 16,
                },
              }}
              ignoredDomTags={["br"]}
              enableCSSInlineProcessing={true}
              enableExperimentalBRCollapsing={true}
              enableExperimentalGhostLinesPrevention={true}
              systemFonts={[...Constants.systemFonts]}
              tagsStyles={{
                p: {
                  marginBottom: 8,
                  marginTop: 8,
                },
                img: {
                  // @ts-expect-error - properly define the theme provider
                  backgroundColor: theme.colors.darkGrey,
                  objectFit: "contain",
                },
              }}
              renderers={{
                iframe: IframeRenderer,
              }}
              customHTMLElementModels={{
                iframe: iframeModel,
              }}
              renderersProps={{
                img: {
                  enableExperimentalPercentWidth: true,
                  initialDimensions: {
                    height: 100,
                    width: modalWidth,
                  },
                },
                iframe: {
                  scalesPageToFit: true,
                  webViewProps: {},
                },
              }}
            />
          </ScrollView>
        )}
      </VStack>

      {continueActionType === "button" && (
        <VStack alignItems="flex-end">
          <Button variant="unstyled" size="sm" onPress={handleClose}>
            <Text fontSize="16">
              {continueActionMeta?.buttonLabel || "Continue"}
            </Text>
          </Button>
        </VStack>
      )}

      {continueActionType === "checkbox" && (
        <HStack alignItems="center" width="100%" justifyContent="space-between">
          <BouncyCheckbox
            textComponent={
              <Text fontFamily="body" ml="2" fontSize={16}>
                {continueActionMeta?.checkboxLabel || "I understand"}
              </Text>
            }
            fillColor={theme.colors.secondary as unknown as string}
            isChecked={isConsentChecked}
            useBuiltInState={false}
            size={18}
            onPress={(isChecked) => setIsConsentChecked(!isChecked)}
          />
          {isConsentChecked && (
            <Button variant="unstyled" size="sm" onPress={handleClose}>
              <Text fontSize="16">
                {continueActionMeta?.buttonLabel || "Continue"}
              </Text>
            </Button>
          )}
        </HStack>
      )}
    </VStack>
  );
};
