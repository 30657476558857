import { defaultTheme } from "../theme/default";
import { ModalProvider } from "react-native-modalfy";
import { modalStack } from "../modules/core/modals/modal-stack";
import { NavContainer } from "../navigation/navigation-container";
import { NativeBaseProvider } from "native-base";
import React from "react";
import { useAppSelector } from "../store/store";
import { selectStudy } from "../store/app.slice";
import { IColorHues } from "native-base/lib/typescript/theme/base/colors";

export const ThemeProvider = () => {
  const study = useAppSelector(selectStudy);

  // assign colors from the API to here
  if (study?.theming) {
    defaultTheme.colors = {
      ...defaultTheme.colors,
      ...study?.theming,
    };
  }
  if (study?.brandColor) {
    defaultTheme.colors.primary = study.brandColor as unknown as string &
      IColorHues;
  }

  return (
    <NativeBaseProvider theme={defaultTheme}>
      <ModalProvider stack={modalStack}>
        <NavContainer />
      </ModalProvider>
    </NativeBaseProvider>
  );
};
