import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { logoutAction } from "./app.slice";
import { RootState } from "./store";

const POSTS_SLICE_NAME = "post";

export type PostInteraction = {
  activeInteraction: string;
  counters: Record<string, number>;
};

export type State = {
  interactions: Record<string, PostInteraction>;
};

const initialState: State = {
  interactions: {},
};

export const postSlice = createSlice({
  name: POSTS_SLICE_NAME,
  initialState: initialState,
  reducers: {
    initializedPostInteractions(
      state: State,
      action: PayloadAction<{ postId: string; data: PostInteraction }>
    ) {
      if (!state.interactions[action.payload.postId]) {
        state.interactions[action.payload.postId] = action.payload.data;
      }
    },
    receivedInteractionUpdate(
      state: State,
      action: PayloadAction<{
        postId: string;
        data: {
          interaction: string;
        };
      }>
    ) {
      if (state.interactions[action.payload.postId]) {
        const postInteractions = state.interactions[action.payload.postId];
        const activeInteraction = postInteractions.activeInteraction;
        const nextInteraction = action.payload.data.interaction;

        if (activeInteraction === nextInteraction) {
          postInteractions.activeInteraction = "none";
          postInteractions.counters[nextInteraction] -= 1;
        } else {
          if (postInteractions.activeInteraction !== "none") {
            postInteractions.counters[activeInteraction] -= 1;
          }
          postInteractions.activeInteraction = nextInteraction;
          postInteractions.counters[nextInteraction] += 1;
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logoutAction, (state) => {
      state = initialState;
      return state;
    });
  },
});

// Define selectors alongside reducers:
// See: https://redux.js.org/usage/deriving-data-selectors#define-selectors-alongside-reducers
export const selectPostInteractions = (postId: string) => {
  return createSelector(
    [(state: RootState) => state.post.interactions],
    (interactions) => {
      if (!interactions[postId]) {
        return {
          activeInteraction: "none",
          counters: {
            like: 0,
            dislike: 0,
          },
        };
      }
      return interactions[postId];
    }
  );
};
