import { useModal } from "react-native-modalfy";
import { ModalStackParams } from "../modals/modal-stack";

type UseOpenErrorModalReturn = () => void;

export const useOpenErrorModal = (): UseOpenErrorModalReturn => {
  const { openModal, closeModal } = useModal<ModalStackParams>();

  return () =>
    openModal("ErrorModal", {
      onContinue: () => {
        closeModal("ErrorModal");
      },
      message:
        "We apologize, but it seems that the Invite Code you entered is invalid. Please double-check the code and try again. If you believe this is an error or need assistance, kindly contact one of our administrators right away for further assistance.",
    });
};
