import React from "react";
import { SettingsScreenProps } from "./settings.screen";
import { useTrackScreenFocus } from "../core/hooks/use-track-screen-focus";
import { events } from "../../constants/analytics.constants";
import { useLogout } from "../core/utils/hooks/use-logout";

type SettingsContainerProps = {};

export const SettingsContainer = (Screen: React.FC<SettingsScreenProps>) => {
  return function _(props: SettingsContainerProps) {
    useTrackScreenFocus(events.userOpenedSettingsScreen);
    const { logout } = useLogout();

    return <Screen onLogoutButtonPress={logout} />;
  };
};
