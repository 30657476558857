import * as React from "react";
import Svg, { Path, SvgProps } from "react-native-svg";

const SvgComponent = (props: SvgProps) => (
  <Svg width={79} height={85} fill="none" {...props}>
    <Path
      fill="#B8BBC6"
      d="M25.243 48.698a3.93 3.93 0 0 0-.425 1.685c-.443 2.276-2.655 3.742-4.68 4.905-6.637 3.844-13.29 7.62-19.775 11.698a50.569 50.569 0 0 0 17.688 12.892 51.014 51.014 0 0 0 21.537 4.254 50.989 50.989 0 0 0 21.327-5.184 50.508 50.508 0 0 0 17.1-13.647l-16.847-8.664a43.517 43.517 0 0 0-4.016-1.888 10.815 10.815 0 0 1-3.148-1.5c-1.396-1.163-1.056-3.051-1.43-4.652a2.867 2.867 0 0 0-.477-1.23c-.663-.827-2.008-.776-2.74-1.551a2.58 2.58 0 0 1-.578-1.686c-.04-.406.037-.815.221-1.18.221-.421.749-.624 1.123-.927.375-.303.783-.59 1.209-.877a5.076 5.076 0 0 0 2.11-2.258c.602-1.52.997-3.113 1.174-4.737.187-1.685-.187-3.657.528-5.225.714-1.567 2.144-2.191 2.467-3.944A9.273 9.273 0 0 0 57 20.127a10.692 10.692 0 0 0-1.14-1.685c-.409-.59-.137-1.298 0-1.972a18.37 18.37 0 0 0-.63-6.153 15.04 15.04 0 0 0-.579-2.124 7.265 7.265 0 0 0-2.944-3.472c-.664-.404-1.43-.59-2.093-.977a20.35 20.35 0 0 0-2.28-1.113A37.41 37.41 0 0 0 41.955.794a16.312 16.312 0 0 0-10.926.54 5.871 5.871 0 0 0-2.377 1.66A5.793 5.793 0 0 0 27.37 5.58a6.216 6.216 0 0 0-3.85.229 6.147 6.147 0 0 0-2.958 2.45 10.384 10.384 0 0 0-1.174 5.85c.25 1.802.59 3.591 1.021 5.36a2.022 2.022 0 0 1-.136 1.483c-.238.371-.68.556-.919.927a2.039 2.039 0 0 0-.204.91 11.048 11.048 0 0 0 1.021 6.743c.167.275.38.52.63.725.204.151.442.27.663.404a5.413 5.413 0 0 1 2.145 2.95c.374 1.079.306 2.225.595 3.371.29 1.146.528 1.854.851 2.714.287.657.73 1.236 1.293 1.685.58.54.92 1.147 1.702 1.383 0 .151.136.303.205.455.146.423.215.868.204 1.315.15.933-.005 1.89-.443 2.73-.68.759-2.127.489-2.774 1.433Z"
    />
  </Svg>
);
export default SvgComponent;
