import {
  Box,
  Button,
  HStack,
  Menu,
  Pressable,
  ScrollView,
  Spinner,
  Text,
  ThreeDotsIcon,
  VStack,
} from "native-base";
import { ParticipantProfileWithPropertiesDto } from "../../services/api-service-sub-services/users-api-service";
import { PostReturnDto } from "../../services/api-service-sub-services/studies-api-service";
import { ProfileMetadata } from "../core/components/profile-metadata";
import React from "react";
import { PostContainer } from "../posts/post.container";
import { PostComponent } from "../posts/post.component";
import { AntDesign, Entypo, SimpleLineIcons } from "@expo/vector-icons";
import { useProfileValues } from "./hooks/use-profile-values";
import { useFeatureAvailability } from "../core/hooks/use-feature-availability";

export type BotProfileScreenProps = {
  profile?: ParticipantProfileWithPropertiesDto;
  posts: PostReturnDto[];
  onBackButtonPress: () => void;
  isFollowedByMe?: boolean;
  onFollowUnFollowButtonPress: () => void;
  displayFollowUnFollowButton: boolean;
  onBlockButtonPress: (profileId: string) => void;
};

const Post = PostContainer(PostComponent);

export const BotProfileScreen = (props: BotProfileScreenProps) => {
  const postCount = props.posts.length;
  const profileValues = useProfileValues(props.profile?.properties);

  const isConnectionsEnabled = useFeatureAvailability("connections");

  if (!props.profile) {
    return (
      <VStack
        variant="screen"
        flex="1"
        alignItems="center"
        justifyContent="center"
        safeAreaTop
      >
        <Spinner />
      </VStack>
    );
  }

  return (
    <VStack variant="scrollable-screen" flex="1" safeAreaTop>
      <HStack
        px="1"
        alignItems="center"
        justifyContent="space-between"
        borderBottomWidth="1"
        borderBottomColor="xLightGrey"
      >
        <Button variant="unstyled" onPress={props.onBackButtonPress}>
          <HStack space="0.5" alignItems="center">
            <AntDesign name="arrowleft" size={20} />
            <Text variant="small" fontSize="14">
              Go back
            </Text>
          </HStack>
        </Button>
        <Box
          alignItems="center"
          borderLeftRadius="8"
          borderBottomRightRadius="8"
          bgColor="white"
          pr="2"
        >
          <Menu
            w="190"
            bgColor="white"
            trigger={(triggerProps) => {
              return (
                <Pressable
                  accessibilityLabel="More options menu"
                  {...triggerProps}
                >
                  <ThreeDotsIcon size="20px" />
                </Pressable>
              );
            }}
          >
            <VStack space="2" bgColor="white">
              {props.displayFollowUnFollowButton && isConnectionsEnabled && (
                <Pressable
                  onPress={props.onFollowUnFollowButtonPress}
                  pl="4"
                  py="0.5"
                  _pressed={{
                    bgColor: "medGrey",
                  }}
                >
                  <HStack space="2" alignItems="center">
                    <SimpleLineIcons
                      name={
                        props.isFollowedByMe ? "user-unfollow" : "user-follow"
                      }
                      size={16}
                    />
                    <Text fontSize="14" letterSpacing="0.3">
                      {props.isFollowedByMe ? "Unfollow" : "Follow"}{" "}
                      {profileValues.displayName}
                    </Text>
                  </HStack>
                </Pressable>
              )}
              <Pressable
                py="0.5"
                pl="4"
                onPress={() => {
                  props.onBlockButtonPress(props.profile!._id!);
                }}
                _pressed={{
                  bgColor: "medGrey",
                }}
              >
                <HStack space="2" alignItems="center">
                  <Entypo name="block" size={16} color="red" />
                  <Text fontSize="14" letterSpacing="0.3">
                    Block {profileValues.displayName}
                  </Text>
                </HStack>
              </Pressable>
            </VStack>
          </Menu>
        </Box>
      </HStack>

      <ScrollView bgColor="xLightGrey">
        <VStack space="3" backgroundColor="lightGrey" pb="2">
          <ProfileMetadata profile={props.profile} postCount={postCount} />
          {props.posts &&
            props.posts.length > 0 &&
            props.posts.map((post) => {
              return <Post key={post._id} post={post} />;
            })}
        </VStack>
      </ScrollView>
    </VStack>
  );
};
