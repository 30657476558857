import React from "react";
import { SinglePostScreenProps } from "./single-post.screen";
import { StackScreenProps } from "@react-navigation/stack";
import { AppStackParamList } from "../../navigation/navigators/app-stack.navigator";
import { Routes } from "../../navigation/routes";
import { useStudiesControllerFindPostByIdQuery } from "../../services/api-service-sub-services/studies-api-service";
import { useAppSelector } from "../../store/store";
import { selectUserStudyId } from "../../store/user.slice";
import { useFocusEffect } from "@react-navigation/native";
import { useTrackEvent } from "../core/hooks/use-track-event";
import { events } from "../../constants/analytics.constants";

type SinglePostContainerProps = StackScreenProps<
  AppStackParamList,
  Routes.APP_STACK__SINGLE_POST
>;
export const SinglePostContainer = (
  Screen: React.FC<SinglePostScreenProps>
) => {
  return function _(props: SinglePostContainerProps) {
    const { postId, highlightedCommentId } = props.route.params;
    const studyId = useAppSelector(selectUserStudyId);
    const { data: post } = useStudiesControllerFindPostByIdQuery(
      { id: studyId!, postId: postId },
      { skip: !studyId, selectFromResult: ({ data }) => ({ data }) }
    );

    const track = useTrackEvent();
    useFocusEffect(() => {
      track(events.userOpenedSinglePost, {
        postId: postId,
      });
    });

    return (
      <Screen
        post={post}
        onBackButtonPress={() => props.navigation.pop()}
        highlightedCommentId={highlightedCommentId}
      />
    );
  };
};
