import { Heading, Text, View, VStack } from "native-base";
import { TouchableHighlight } from "react-native";
import { defaultTheme } from "../../theme/default";
import { Entypo } from "@expo/vector-icons";
import React from "react";

export type SettingsScreenProps = {
  onLogoutButtonPress: () => void;
};

export const SettingsScreen = (props: SettingsScreenProps) => {
  return (
    <VStack
      space="3"
      flex="1"
      px="5"
      variant="screen"
      safeAreaTop
      justifyContent="space-between"
    >
      <VStack space="2">
        <Heading size="xl" fontWeight="bold" color="black">
          Settings
        </Heading>
        <Text fontSize="lg">Account Settings</Text>
        <VStack>
          <TouchableHighlight
            style={{
              display: "flex",
              borderBottomWidth: 1,
              borderBottomColor: defaultTheme.colors.lightGrey,
            }}
            underlayColor={defaultTheme.colors.lightGrey}
            onPress={props.onLogoutButtonPress}
          >
            <View
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
              px="5"
              py="2"
              w="100%"
            >
              <Text fontSize="md">Logout</Text>
              <Entypo name="chevron-thin-right" size={16} color="black" />
            </View>
          </TouchableHighlight>
        </VStack>
      </VStack>
    </VStack>
  );
};
