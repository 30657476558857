import * as React from "react";
import Svg, { Path, SvgProps } from "react-native-svg";

const SvgComponent = (props: SvgProps) => (
  <Svg width={24} height={24} fill="none" {...props}>
    <Path
      fill="#444A5F"
      stroke="#444A5F"
      strokeWidth={0.1}
      d="M11.435 2.803a6.814 6.814 0 0 0-.547.754 6.821 6.821 0 0 0-.547-.754C9.239 1.488 7.772.792 6.1.792 2.93.792.708 3.46.708 6.639c0 2.124 1.014 3.85 2.737 5.667 1.189 1.253 2.72 2.555 4.493 4.063.796.676 1.64 1.394 2.525 2.169l.39.341.034.03.033-.03.39-.341c.884-.774 1.729-1.492 2.524-2.168 1.772-1.507 3.303-2.808 4.492-4.061 1.724-1.818 2.74-3.545 2.74-5.67 0-3.184-2.227-5.847-5.392-5.847-1.672 0-3.138.696-4.24 2.01Zm1.012.797c.859-1.01 1.944-1.521 3.228-1.521 1.58 0 2.898.892 3.587 2.274H11.91c.14-.234.317-.495.537-.753ZM1.996 6.639c0-2.606 1.768-4.56 4.104-4.56 1.285 0 2.37.51 3.229 1.52.54.635.824 1.283.915 1.513v3.989H2.64c-.42-.793-.644-1.598-.644-2.462Zm8.892 10.56a315.81 315.81 0 0 0-2.412-2.067H13.3c-.788.67-1.572 1.339-2.411 2.068Zm-5.132-4.441H16.02c-.382.352-.79.717-1.22 1.09H6.977c-.43-.373-.837-.738-1.22-1.09Zm-2.268-2.374H18.287c-.28.359-.597.723-.948 1.09H4.436c-.35-.367-.667-.731-.948-1.09ZM19.136 9.1H11.53V8.01h8.051a5.828 5.828 0 0 1-.446 1.09ZM11.53 6.727V5.64h8.158a5.466 5.466 0 0 1 .09 1.087h-8.248Z"
    />
    <Path
      fill="#444A5F"
      stroke="#444A5F"
      strokeWidth={0.1}
      d="M4.37 4.403v-.05H3.083V5.64H4.37V4.403ZM4.37 6.776v-.05H3.083V8.013H4.37V6.776ZM6.744 6.776v-.05H5.457V8.013H6.744V6.776ZM6.744 4.403v-.05H5.457V5.64H6.744V4.403ZM9.118 4.403v-.05H7.83V5.64H9.118V4.403ZM9.118 6.776v-.05H7.83V8.013H9.118V6.776Z"
    />
  </Svg>
);
export { SvgComponent as PoliticalAffiliationIcon };
