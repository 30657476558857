import {
  Box,
  Heading,
  Pressable,
  Spinner,
  Text,
  View,
  VStack,
} from "native-base";
import React, { useCallback, useState } from "react";
import { Notification } from "../../services/api-service-sub-services/notifications-api-service";
import { NotificationItemComponent } from "./notification-item.component";
import { SwipeListView } from "react-native-swipe-list-view";
import { ListRenderItemInfo } from "react-native";
import { Feather } from "@expo/vector-icons";
import _ from "lodash";
import { EmptyTrayIcon } from "../core/icons/empty-tray-icon";

export type NotificationScreenProps = {
  notifications: Notification[];
  userId: string;
  isFetchingNotifications: boolean;
  updatedNotificationHashmap: { [key: string]: boolean };
  onBottomReached: () => Promise<void>;
  onNotificationClick: (
    notification: Notification,
    isSeen: boolean
  ) => Promise<void>;
  onNotificationSwipe: (notificationId: string) => Promise<void>;
};

export const NotificationScreen: React.FC<NotificationScreenProps> = ({
  updatedNotificationHashmap,
  onNotificationClick,
  isFetchingNotifications,
  userId,
  onBottomReached,
  ...props
}) => {
  const [swipeListWidth, setSwipeListWidth] = useState(0);

  const renderItem = useCallback(
    ({ item }: ListRenderItemInfo<Notification>) => {
      const isSeen =
        item._id in updatedNotificationHashmap
          ? updatedNotificationHashmap[item._id]
          : item.seenBy.some(({ user }) => user === userId);

      return (
        <Pressable
          backgroundColor="white"
          onPress={() => {
            onNotificationClick(item, isSeen);
          }}
        >
          <NotificationItemComponent notification={item} isSeen={isSeen} />
        </Pressable>
      );
    },
    [updatedNotificationHashmap, onNotificationClick, userId]
  );

  const renderHiddenItem = useCallback(
    ({ item }: ListRenderItemInfo<Notification>) => {
      const isSeen =
        item._id in updatedNotificationHashmap
          ? updatedNotificationHashmap[item._id]
          : item.seenBy.some(({ user }) => user === userId);

      return (
        <View
          backgroundColor="primary"
          w="100%"
          h="100%"
          display="flex"
          alignItems="flex-end"
          justifyContent="center"
        >
          <View
            pr={8}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Feather
              name={isSeen ? "eye-off" : "eye"}
              size={24}
              color="white"
            />
            <Text color="white">Mark as {isSeen ? "unread" : "read"}</Text>
          </View>
        </View>
      );
    },
    [updatedNotificationHashmap, userId]
  );

  const renderFooter = useCallback(() => {
    return (
      <Box h={8} display="flex" justifyContent="center">
        {isFetchingNotifications ? <Spinner /> : null}
      </Box>
    );
  }, [isFetchingNotifications]);

  const debounceOnEndReached = useCallback(
    _.debounce(() => onBottomReached(), 300),
    [onBottomReached]
  );

  if (props.notifications.length < 1) {
    return (
      <VStack alignItems="center" justifyContent="center" flex="1" mx="5">
        <EmptyTrayIcon />
        <Text mt="3" variant="heading" fontWeight="700">
          No notifications
        </Text>
        <Text fontSize="lg">Looks like there's nothing to show just yet</Text>
      </VStack>
    );
  }

  return (
    <VStack safeAreaTop variant="scrollable-screen">
      <Heading size="xl" px="5">
        Notifications
      </Heading>
      <SwipeListView
        disableRightSwipe
        friction={14}
        keyExtractor={(item) => item._id}
        data={props.notifications}
        renderItem={renderItem}
        renderHiddenItem={renderHiddenItem}
        swipeGestureEnded={(rowKey, data) => {
          const translatedXValue = Math.abs(data.translateX);
          const hasSwipedThirdOfTheWay = translatedXValue >= swipeListWidth / 3;

          if (hasSwipedThirdOfTheWay) {
            props.onNotificationSwipe(rowKey);
          }
        }}
        onContentSizeChange={(w) => setSwipeListWidth(w)}
        onEndReached={debounceOnEndReached}
        ListFooterComponentStyle={{
          paddingBottom: 8,
          backgroundColor: "white",
          display: "flex",
          alignItems: "center",
        }}
        // Don't render unless there's at least 1 item
        // Otherwise it will start at the bottom
        {...(props.notifications?.length && {
          ListFooterComponent: renderFooter,
        })}
      />
    </VStack>
  );
};
