import React from "react";
import { ProfileScreenProps } from "./profile.screen";
import { useStudiesControllerFindAllPostsQuery } from "../../services/api-service-sub-services/studies-api-service";
import { useAppSelector } from "../../store/store";
import { selectUserProfile, selectUserStudyId } from "../../store/user.slice";
import { StackScreenProps } from "@react-navigation/stack";
import { Routes } from "../../navigation/routes";
import { ProfileStackParamList } from "../../navigation/navigators/profile-stack-navigator";
import { AppStackParamList } from "../../navigation/navigators/app-stack.navigator";
import { useFocusEffect } from "@react-navigation/native";
import { useTrackEvent } from "../core/hooks/use-track-event";
import { events } from "../../constants/analytics.constants";

export type ProfileContainerProps = StackScreenProps<
  ProfileStackParamList & AppStackParamList,
  Routes.APP_STACK__MAIN__PROFILE__USER_PROFILE
>;

export const ProfileContainer = (Screen: React.FC<ProfileScreenProps>) => {
  return function _(props: ProfileContainerProps) {
    const studyId = useAppSelector(selectUserStudyId);
    const userProfile = useAppSelector(selectUserProfile);

    const { data: paginatedPosts } = useStudiesControllerFindAllPostsQuery(
      { id: studyId!, page: 1, limit: 100, profile: userProfile!._id },
      {
        selectFromResult: ({ data }) => ({ data }),
        skip: !studyId || !userProfile,
      }
    );

    const track = useTrackEvent();

    useFocusEffect(() => {
      track(events.userViewedOwnProfile);
    });

    return (
      <Screen
        posts={paginatedPosts?.docs || []}
        profile={userProfile!}
        postCount={paginatedPosts?.totalDocs || 0}
        onNewPostButtonPress={() => {
          track(events.useOpenedNewPostScreenFromProfile);
          props.navigation.navigate(Routes.APP_STACK__CREATE_POST);
        }}
      />
    );
  };
};
