import React, { useCallback, useEffect, useState } from "react";
import { InAppBrowserScreenProps } from "./in-app-browser.screen";
import { StackScreenProps } from "@react-navigation/stack";
import { AppStackParamList } from "../../navigation/navigators/app-stack.navigator";
import { Routes } from "../../navigation/routes";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { selectUserProfile, selectUserStudyId } from "../../store/user.slice";
import { analyticsSlice, Modules } from "../../store/analytics.slice";
import dayjs from "dayjs";
import { useTrackEvent } from "../core/hooks/use-track-event";
import { events } from "../../constants/analytics.constants";

type InAppBrowserContainerProps = StackScreenProps<
  AppStackParamList,
  Routes.APP_STACK__IN_APP_BROWSER
>;
export const InAppBrowserContainer = (
  Screen: React.FC<InAppBrowserScreenProps>
) => {
  return function _(props: InAppBrowserContainerProps) {
    const studyId = useAppSelector(selectUserStudyId);
    const profile = useAppSelector(selectUserProfile);
    const dispatch = useAppDispatch();
    const [currentURL, setCurrentURL] = useState(props.route.params.url);
    const track = useTrackEvent();

    const onInAppBrowserURLChange = useCallback(
      (url: string) => {
        if (!studyId || !profile) {
          return;
        }
        track(events.userNavigatedExternalLink, {
          url: url,
        });
        dispatch(
          analyticsSlice.actions.addItemToQueue({
            study: studyId,
            participant: profile._id,
            module: Modules.POSTS,
            meta: {
              description: `User changed URL`,
              timestamp: dayjs.utc().toISOString(),
              url: url,
            },
          })
        );
        setCurrentURL(url);
      },
      [dispatch, studyId, profile]
    );

    useEffect(() => {
      if (!studyId || !profile) {
        return;
      }
      dispatch(
        analyticsSlice.actions.addItemToQueue({
          study: studyId,
          participant: profile._id,
          module: Modules.POSTS,
          meta: {
            description: `User opened a post URL`,
            timestamp: dayjs.utc().toISOString(),
            url: currentURL,
          },
        })
      );
      track(events.userOpenedUrlFromApp, {
        url: currentURL,
      });
      return () => {
        track(events.userClosedInAppBrowser, {
          url: currentURL,
        });
        dispatch(
          analyticsSlice.actions.addItemToQueue({
            study: studyId,
            participant: profile._id,
            module: Modules.POSTS,
            meta: {
              description: `User closed in-app browser`,
              timestamp: dayjs.utc().toISOString(),
              url: currentURL,
            },
          })
        );
      };
    }, [studyId, profile]);

    return (
      <Screen
        url={props.route.params.url}
        userPressedCloseButton={() => props.navigation.pop()}
        onInAppBrowserURLChange={onInAppBrowserURLChange}
      />
    );
  };
};
