import React from "react";
import { Notification } from "../../services/api-service-sub-services/notifications-api-service";
import { Circle, HStack, Text, VStack } from "native-base";
import dayjs from "dayjs";
import { AntDesign } from "@expo/vector-icons";

interface Props {
  notification: Notification;
  isSeen?: Boolean;
}

export const NotificationItemComponent: React.FC<Props> = (props) => {
  return (
    <VStack
      opacity={props.isSeen ? 0.8 : 1}
      borderBottomColor="lightGrey"
      borderBottomWidth="1"
      px="5"
      py="2"
      space="2"
    >
      <VStack>
        <HStack alignItems="center" flex="1">
          {/** notification seen indicator */}
          {!props.isSeen && <Circle width="2" h="2" bg="primary" mr="2" />}

          {/** notification title */}
          <Text
            noOfLines={1}
            isTruncated
            maxWidth="100%"
            color="black"
            fontWeight="600"
            letterSpacing="0.4"
          >
            {props.notification.title}
          </Text>
        </HStack>

        <HStack space="1" alignItems="center" justifyContent="flex-start">
          <AntDesign name="calendar" size={16} color="#1F2330" />
          <Text variant="small" color="black">
            {dayjs(props.notification.date).format("MMMM DD [at] hh:mm A")}
          </Text>
        </HStack>
      </VStack>

      <Text noOfLines={2} isTruncated variant="small">
        {props.notification.body}
      </Text>
    </VStack>
  );
};
