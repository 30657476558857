import {
  CheckIcon,
  FormControl,
  ISelectItemProps,
  ISelectProps,
  ScrollView,
  Select,
  VStack,
  WarningOutlineIcon,
} from "native-base";
import React from "react";
import { Control, Controller } from "react-hook-form";
import { RegisterOptions } from "react-hook-form/dist/types/validator";

export type CoreSelectProps = {
  items: ISelectItemProps[];
  errorMessage?: string;
};

export const CoreSelect = (props: ISelectProps & CoreSelectProps) => {
  const { items, errorMessage, ...selectProps } = props;
  return (
    <ScrollView
      horizontal
      scrollEnabled={false}
      width="100%"
      contentContainerStyle={{
        flexGrow: 1,
      }}
    >
      {/*
       * Don't wrap Select inside FormControl since it will cause the keyboard to open
       * https://github.com/GeekyAnts/NativeBase/issues/5723
       */}
      <VStack flex="1">
        <Select
          flex="1"
          _selectedItem={{
            bg: "xLightGrey",
            endIcon: <CheckIcon size="5" color="black" />,
          }}
          mt={1}
          {...selectProps}
        >
          {items.map((item: ISelectItemProps) => {
            return (
              <Select.Item
                key={item.value}
                label={item.label}
                value={item.value}
              />
            );
          })}
        </Select>
        <FormControl isInvalid={!!errorMessage} flex="1">
          <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>
            {errorMessage}
          </FormControl.ErrorMessage>
        </FormControl>
      </VStack>
    </ScrollView>
  );
};

export type ControlledCoreSelectProps = {
  control: Control;
  name: string;
  placeholder: string;
  items: ISelectItemProps[];
  rules?: Omit<
    RegisterOptions,
    "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled"
  >;
};

export const ControlledCoreSelect = (props: ControlledCoreSelectProps) => {
  return (
    <Controller
      control={props.control}
      name={props.name}
      rules={props.rules}
      render={({ field, fieldState }) => {
        return (
          <CoreSelect
            errorMessage={fieldState.error?.message}
            placeholder={props.placeholder}
            onValueChange={field.onChange}
            onClose={field.onBlur}
            selectedValue={field.value}
            items={props.items}
          />
        );
      }}
    />
  );
};
