import React from "react";
import { HStack, Image, Spinner, Text, View, VStack } from "native-base";
import { StatusBar } from "expo-status-bar";
import { SplashIcon } from "../../../assets/images";
import { useAppSelector } from "../../../store/store";
import { selectStudy } from "../../../store/app.slice";

export const GlobalLoading = () => {
  const study = useAppSelector(selectStudy);

  return (
    <View
      variant="screen"
      bgColor="primary"
      justifyContent="center"
      alignItems="center"
    >
      <VStack space="5" alignItems="center" justifyContent="center">
        <Image
          source={study?.logoUrl ? { uri: study.logoUrl } : SplashIcon}
          height="72"
          width="72"
          alt="logo"
          resizeMode="contain"
        />
        <HStack space="2">
          <Spinner size="sm" color="white" />
          <Text variant="small" color="white">
            Loading...
          </Text>
        </HStack>
      </VStack>

      <StatusBar style="light" />
    </View>
  );
};
