import { RootState, store, useAppDispatch } from "../../../../store/store";
import { useAnalyticsControllerCreateManyMutation } from "../../../../services/api-service-sub-services/analytics-api-service";
import { useEffect, useRef } from "react";
import dayjs from "dayjs";
import { analyticsSlice } from "../../../../store/analytics.slice";

export const useAnalyticsUpdater = () => {
  const isUpdatingRef = useRef(false);
  const dispatch = useAppDispatch();
  const [updateAnalytics] = useAnalyticsControllerCreateManyMutation({
    selectFromResult: () => ({}),
  });

  useEffect(() => {
    const poll = setInterval(async () => {
      try {
        const queuedItems = (store.getState() as RootState).analytics
          .queuedItems;
        const currentTimestamp = dayjs.utc().toISOString();

        // Return if analytics is still being updated to prevent duplicates
        if (!queuedItems.length || isUpdatingRef.current) {
          return;
        }

        isUpdatingRef.current = true;

        await updateAnalytics({
          batchCreateAnalyticsItemDto: { items: queuedItems },
        }).unwrap();

        dispatch(
          analyticsSlice.actions.clearItemsInQueue({ currentTimestamp })
        );

        // Don't refactor this and use finally since we want to set this to false only if the api triggered
        isUpdatingRef.current = false;
      } catch (e) {
        isUpdatingRef.current = false;
      }
    }, 5000);

    return () => clearInterval(poll);
  }, [store, analyticsSlice.actions.clearItemsInQueue]);

  return null;
};
