import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./store";
import { logoutAction } from "./app.slice";

const NOTIFICATION_SLICE_NAME = "notification";
export type State = {
  badgeCount: number;
  isEnabled: boolean;
  receivedNotifIds: string[];
};

const initialState: State = {
  badgeCount: 0,
  isEnabled: false,
  receivedNotifIds: [],
};

export const notificationSlice = createSlice({
  name: NOTIFICATION_SLICE_NAME,
  initialState: initialState,
  reducers: {
    incrementBadgeCount(state: State, action: PayloadAction<number>) {
      state.badgeCount += action.payload;
    },
    decrementBadgeCount(state: State, action: PayloadAction<number>) {
      state.badgeCount -= action.payload;
    },
    resetBadgeCount(state: State) {
      state.badgeCount = 0;
    },
    setNotificationsStatus: (state, action: PayloadAction<boolean>) => {
      state.isEnabled = action.payload;
    },
    addNotifToReceived: (state: State, action: PayloadAction<string>) => {
      state.receivedNotifIds = [...state.receivedNotifIds, action.payload];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logoutAction, (state) => {
      state = initialState;
      return state;
    });
  },
});

export const selectBadgeCount = (state: RootState) =>
  state.notification.badgeCount;
export const selectIsNotificationsEnabled = (state: RootState) =>
  state.notification.isEnabled;
export const selectReceivedNotifIds = (state: RootState) =>
  state.notification.receivedNotifIds;
