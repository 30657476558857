import * as React from "react";
import Svg, { Path, SvgProps } from "react-native-svg";
import { useTheme } from "native-base";
import { ColorValue } from "react-native";

export const NoPostIcon = (props: SvgProps) => {
  const theme = useTheme();
  return (
    <Svg width={150} height={150} fill="none" viewBox="0 0 800 600" {...props}>
      <Path
        fill={theme.colors.primary as unknown as ColorValue}
        d="m10 583.713 109.587-179.588 93.752 68.935L10 583.713Z"
      />
      <Path
        fill={theme.colors.primary as unknown as ColorValue}
        d="M790.001 282.274c0 146.507-98.376 232.868-359.904 232.868-261.529 0-359.904-86.361-359.904-232.868C57.09 40.395 231.326 17 430.096 17c198.769 0 359.904 25.318 359.904 265.274Z"
      />
    </Svg>
  );
};
