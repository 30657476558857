import { useAppDispatch } from "../../../store/store";
import { userSlice } from "../../../store/user.slice";
import { useToast } from "native-base";
import {
  useParticipantProfileControllerBlockMutation
} from "../../../services/api-service-sub-services/profiles-api-service";
import { useToastErrorMessage } from "../../core/hooks/use-toast-error-message";
import Sentry from "sentry-expo";

/**
 * This hook returns a curried function that will calls the API to block a
 * bot's profile
 *
 */
export const useBlockBotProfile = () => {
  const dispatch = useAppDispatch();
  const toast = useToast();
  const [blockProfile] = useParticipantProfileControllerBlockMutation();
  const toastError = useToastErrorMessage();

  return async (profileId: string) => {
    try {
      await blockProfile({ profileId: profileId }).unwrap();
      dispatch(
        userSlice.actions.botAccountBlocked({ blockedProfileId: profileId })
      );
      toast.show({
        description: "The user has been blocked successfully!",
      });
    } catch (e) {
      const err = e as Error;
      toastError({
        message: "We are unable to block this user. Please try again later.",
      });
      Sentry.Native.captureException(err);
    }
  };
};
