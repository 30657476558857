import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { FeedScreenProps } from "./feed.screen";
import {
  PostReturnDto,
  useLazyStudiesControllerFindAllPostsQuery,
  useStudiesControllerCreateBotCommentMutation,
  useStudiesControllerCreateBotPostMutation,
  useStudiesControllerFindAllPostsQuery,
  useStudiesControllerGenerateBotInteractionMutation,
  useStudiesControllerGenerateFollowingActionMutation,
} from "../../services/api-service-sub-services/studies-api-service";
import { useAppSelector } from "../../store/store";
import {
  selectBlockedBotProfiles,
  selectUserCreatedDate,
  selectUserStudyId,
} from "../../store/user.slice";
import { useModal } from "react-native-modalfy";
import { ModalStackParams } from "../core/modals/modal-stack";
import {
  selectExperienceContentVariants,
  selectExperienceInstructions,
  selectExperiencePrePopulatedPosts,
} from "../../store/app.slice";
import { useToast } from "native-base";
import { useIsFocused } from "@react-navigation/native";
import lodash from "lodash";
import { getPostsTagsFilter } from "../core/utils/get-posts-treatment-arm-filter.util";
import dayjs from "dayjs";
import { StackScreenProps } from "@react-navigation/stack";
import { Routes } from "../../navigation/routes";
import { AppStackParamList } from "../../navigation/navigators/app-stack.navigator";
import { HomeStackParamList } from "../../navigation/navigators/home-stack.navigator";
import { useTrackEvent } from "../core/hooks/use-track-event";
import { events } from "../../constants/analytics.constants";

const LIMIT = 20;

export type FeedContainerProps = StackScreenProps<
  HomeStackParamList & AppStackParamList,
  Routes.APP_STACK__MAIN__FEED__HOME
>;

export const FeedContainer = (Screen: React.FC<FeedScreenProps>) => {
  return function _(props: FeedContainerProps) {
    const isFocused = useIsFocused();
    const toast = useToast();
    const { openModal } = useModal<ModalStackParams>();
    const track = useTrackEvent();
    const blockedBotProfiles = useAppSelector(selectBlockedBotProfiles);

    const currentPostsRef = useRef<PostReturnDto[]>([]);

    const studyId = useAppSelector(selectUserStudyId);
    const instructions = useAppSelector(selectExperienceInstructions);
    const contentVariants = useAppSelector(selectExperienceContentVariants);
    const prePopulatedPosts = useAppSelector(selectExperiencePrePopulatedPosts);
    const userCreatedDate = useAppSelector(selectUserCreatedDate);

    const { excludedTagsQuery, includedTagsQuery } =
      getPostsTagsFilter(contentVariants);

    // START SECTION - this section contains code that handles the interaction automation (bot liking etc for a post)
    const [generateInteraction] =
      useStudiesControllerGenerateBotInteractionMutation({
        selectFromResult: () => ({}),
      });

    /** Creates an interaction every 73 seconds */
    useEffect(() => {
      if (!studyId) {
        return;
      }
      const intervalId = setInterval(async () => {
        await generateInteraction({
          id: studyId,
        }).unwrap();
      }, 73000);
      return () => clearInterval(intervalId);
    }, [studyId]);
    // END SECTION

    // START SECTION - this section contains code that handles the following automation (bot following participant)
    const [generateFollowing] =
      useStudiesControllerGenerateFollowingActionMutation({
        selectFromResult: () => ({}),
      });

    /** Creates a following action every 91 seconds */
    useEffect(() => {
      if (!studyId) {
        return;
      }
      const intervalId = setInterval(async () => {
        await generateFollowing({
          id: studyId,
        }).unwrap();
      }, 240000);
      return () => clearInterval(intervalId);
    }, [studyId]);
    // END SECTION

    // Make initial state of page to 2 since the first page will always be polling
    const [page, setPage] = useState(2);
    const [newPosts, setNewPosts] = useState<PostReturnDto[]>([]);
    const [postsToShow, setPostsToShow] = useState<PostReturnDto[]>([]);
    const [isFetchingOlderPosts, setIsFetchingOlderPosts] = useState(false);
    const [isRefreshing, setIsRefreshing] = useState(false);

    // Don't use lazy query to poll since it still re-renders even with selectFromResult
    const { data, isLoading } = useStudiesControllerFindAllPostsQuery(
      {
        id: studyId!,
        limit: LIMIT,
        page: 1,
        ...(includedTagsQuery && { tags: includedTagsQuery }),
        ...(excludedTagsQuery && { exclude: excludedTagsQuery }),
        isMadeByResearcher: false,
      },
      {
        skip: !studyId || !isFocused,
        pollingInterval: 1200,
        selectFromResult: ({ data, isLoading }) => ({ data, isLoading }),
      }
    );

    const [findPosts] = useLazyStudiesControllerFindAllPostsQuery({
      selectFromResult: () => ({}),
    });

    // mutation for creating new bot posts
    const [createBotPost] = useStudiesControllerCreateBotPostMutation({
      selectFromResult: () => ({}),
    });

    const onBottomReached = useCallback(async () => {
      try {
        setIsFetchingOlderPosts(true);

        const shouldNotQuery =
          isFetchingOlderPosts || page > (data?.totalPages || 0) || !studyId;

        if (shouldNotQuery) {
          return;
        }

        const postResponses = await findPosts({
          id: studyId,
          limit: LIMIT,
          page,
          isMadeByResearcher: false,
        }).unwrap();

        setPage((prev) => prev + 1);
        setPostsToShow((prev) => {
          const updatedPosts = lodash.uniqBy(
            [...prev, ...postResponses.docs],
            "_id"
          );
          currentPostsRef.current = updatedPosts;

          return updatedPosts;
        });
      } catch (error: any) {
        toast.show({
          title: "Error",
          description: error.message || "Something went wrong.",
        });
      } finally {
        setIsFetchingOlderPosts(false);
      }
    }, [data?.totalPages, studyId, page, isFetchingOlderPosts]);

    useEffect(() => {
      if (!data?.docs) {
        return;
      }

      // Use a ref since we only want to trigger this useEffect if data updated
      const currentPosts = [...currentPostsRef.current];

      if (!currentPosts.length) {
        currentPostsRef.current = data.docs;
        setPostsToShow(data.docs);
      } else {
        const newPosts = data.docs.filter(
          (doc) => !currentPosts.some((post) => post._id === doc._id)
        );

        setNewPosts((prev) => lodash.uniqBy([...newPosts, ...prev], "_id"));
      }
    }, [data?.docs]);

    /** This hooks creates a new bot post every 130 seconds */
    useEffect(() => {
      if (!studyId) {
        return;
      }

      const intervalId = setInterval(async () => {
        await createBotPost({
          id: studyId,
        }).unwrap();
      }, 130_000);

      return () => clearInterval(intervalId);
    }, [studyId]);

    /// START SECTION - this section creates a comment on a random post
    const [createBotComment] = useStudiesControllerCreateBotCommentMutation({
      // selectFromResult: () => ({}),
    });

    /** This hook creates a new bot comment every 65 seconds */
    useEffect(() => {
      if (!studyId) {
        return;
      }

      const intervalId = setInterval(async () => {
        await createBotComment({
          id: studyId,
        }).unwrap();
      }, 65_000);

      return () => clearInterval(intervalId);
    }, []);

    /** This hook displays the Treatment Arm instructions when this container is loaded to the view */
    useEffect(() => {
      if (instructions && instructions.length > 0) {
        track(events.userReadTreatmentArmInstructions, {
          instructions: instructions,
        });
        openModal("InstructionsModal", {
          instructions: instructions,
        });
      }
    }, [instructions]);

    // create a timer that runs every 3 seconds
    const [tick, setTick] = useState(0);
    useEffect(() => {
      const timer = setInterval(() => {
        setTick((tick) => tick + 1);
      }, 30000);
      return () => clearInterval(timer);
    }, []);

    /** This hook combines all the pre-populated posts and the posts made by the bots */
    const posts = useMemo(() => {
      // get past posts
      const pastPrePopulatedPosts = prePopulatedPosts.filter((post) =>
        dayjs(post.date).isBefore(dayjs(userCreatedDate))
      );
      // get future posts
      const futurePrePopulatedPosts = prePopulatedPosts.filter((post) =>
        dayjs(post.date).isAfter(dayjs(userCreatedDate))
      );
      // combine future posts and new posts created by the user and the bots
      const newPosts = [...postsToShow, ...futurePrePopulatedPosts];
      const sortedNewPostsByDate = lodash.orderBy(newPosts, "date", "desc");
      const allPosts = [...sortedNewPostsByDate, ...pastPrePopulatedPosts];
      const unique = lodash.uniqBy(allPosts, "_id");
      return lodash.filter(
        unique,
        (item) =>
          dayjs(item.date).isBefore(dayjs()) &&
          !blockedBotProfiles.some((id) => id === item.profile._id)
      );
    }, [
      postsToShow,
      prePopulatedPosts,
      userCreatedDate,
      tick,
      blockedBotProfiles,
    ]);

    return (
      <Screen
        posts={posts}
        numberOfNewPosts={newPosts.length}
        isRefreshing={isRefreshing}
        isFetchingPosts={isFetchingOlderPosts || isLoading}
        onBottomReached={onBottomReached}
        onNewPostButtonPress={() =>
          props.navigation.navigate(Routes.APP_STACK__CREATE_POST)
        }
        onRefresh={() => {
          setIsRefreshing(true);
          setNewPosts([]);
          setPostsToShow((prev) => {
            const updatedPosts = lodash.uniqBy([...newPosts, ...prev], "_id");
            currentPostsRef.current = updatedPosts;
            return updatedPosts;
          });
          track(events.userRefreshedFeed);
          setIsRefreshing(false);
        }}
      />
    );
  };
};
