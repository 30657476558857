import * as React from "react";
import Svg, { Mask, Path, SvgProps } from "react-native-svg";

const SvgComponent = (props: SvgProps) => (
  <Svg width={19} height={19} fill="none" {...props}>
    <Mask
      id="a"
      width={14}
      height={14}
      x={-0.47}
      y={-0.683}
      fill="#000"
      maskUnits="userSpaceOnUse"
    >
      <Path fill="#fff" d="M-.47-.683h14v14h-14z" />
      <Path d="M3.59 10.102 5.813 7.88a4.2 4.2 0 1 0-.846-.846L2.744 9.257l-.847-.847a.599.599 0 0 0-.846.847l.847.847-1.185 1.184a.598.598 0 1 0 .846.846l1.185-1.185.846.847a.6.6 0 0 0 1.03-.421.598.598 0 0 0-.183-.426l-.847-.847ZM8.32 1.535a2.993 2.993 0 1 1 0 5.986 2.993 2.993 0 0 1 0-5.986Z" />
    </Mask>
    <Path
      fill="#444A5F"
      d="M3.59 10.102 5.813 7.88a4.2 4.2 0 1 0-.846-.846L2.744 9.257l-.847-.847a.599.599 0 0 0-.846.847l.847.847-1.185 1.184a.598.598 0 1 0 .846.846l1.185-1.185.846.847a.6.6 0 0 0 1.03-.421.598.598 0 0 0-.183-.426l-.847-.847ZM8.32 1.535a2.993 2.993 0 1 1 0 5.986 2.993 2.993 0 0 1 0-5.986Z"
    />
    <Path
      stroke="#444A5F"
      strokeWidth={0.2}
      d="M3.59 10.102 5.813 7.88a4.2 4.2 0 1 0-.846-.846L2.744 9.257l-.847-.847a.599.599 0 0 0-.846.847l.847.847-1.185 1.184a.598.598 0 1 0 .846.846l1.185-1.185.846.847a.6.6 0 0 0 1.03-.421.598.598 0 0 0-.183-.426l-.847-.847ZM8.32 1.535a2.993 2.993 0 1 1 0 5.986 2.993 2.993 0 0 1 0-5.986Z"
      mask="url(#a)"
    />
    <Mask
      id="b"
      width={14}
      height={14}
      x={5.501}
      y={5.324}
      fill="#000"
      maskUnits="userSpaceOnUse"
    >
      <Path fill="#fff" d="M5.501 5.324h14v14h-14z" />
      <Path d="M17.896 6.324h-2.993a.599.599 0 1 0 0 1.197h1.548l-3.232 3.232a4.2 4.2 0 1 0 .846.847l3.232-3.233v1.548a.599.599 0 0 0 1.197 0V6.922a.598.598 0 0 0-.598-.598Zm-7.183 10.774a2.993 2.993 0 1 1 0-5.987 2.993 2.993 0 0 1 0 5.987Z" />
    </Mask>
    <Path
      fill="#444A5F"
      d="M17.896 6.324h-2.993a.599.599 0 1 0 0 1.197h1.548l-3.232 3.232a4.2 4.2 0 1 0 .846.847l3.232-3.233v1.548a.599.599 0 0 0 1.197 0V6.922a.598.598 0 0 0-.598-.598Zm-7.183 10.774a2.993 2.993 0 1 1 0-5.987 2.993 2.993 0 0 1 0 5.987Z"
    />
    <Path
      stroke="#444A5F"
      strokeWidth={0.2}
      d="M17.896 6.324h-2.993a.599.599 0 1 0 0 1.197h1.548l-3.232 3.232a4.2 4.2 0 1 0 .846.847l3.232-3.233v1.548a.599.599 0 0 0 1.197 0V6.922a.598.598 0 0 0-.598-.598Zm-7.183 10.774a2.993 2.993 0 1 1 0-5.987 2.993 2.993 0 0 1 0 5.987Z"
      mask="url(#b)"
    />
  </Svg>
);
export { SvgComponent as GenderIcon };
