import React, { useEffect, useState } from "react";
import {
  NunitoSans_200ExtraLight,
  NunitoSans_300Light,
  NunitoSans_400Regular,
  NunitoSans_600SemiBold,
  NunitoSans_700Bold,
  NunitoSans_800ExtraBold,
  NunitoSans_900Black,
  useFonts,
} from "@expo-google-fonts/nunito-sans";
import { Provider } from "react-redux";
import { persistor, store } from "./store/store";
import { PersistGate } from "redux-persist/integration/react";
import utc from "dayjs/plugin/utc";
import relativeTime from "dayjs/plugin/relativeTime";
import dayjs from "dayjs";
import * as SplashScreen from "expo-splash-screen";
import { GestureHandlerRootView } from "react-native-gesture-handler";
import * as Updates from "expo-updates";
import * as Sentry from "sentry-expo";
import { ThemeProvider } from "./providers/theme-provider";

dayjs.extend(utc);
dayjs.extend(relativeTime);

// Keep the splash screen visible while we fetch resources
SplashScreen.preventAutoHideAsync();

/**
 * The default entry point
 * @constructor
 */
export const App = () => {
  const [appIsReady, setIsAppReady] = useState<boolean>(false);
  const [updatesFetched, setUpdatesFetched] = useState<boolean>(false);

  const fetchAppUpdates = async () => {
    try {
      const update = await Updates.checkForUpdateAsync();
      if (update.isAvailable) {
        await Updates.fetchUpdateAsync();
        await Updates.reloadAsync();
      }
    } catch (error) {
      Sentry.Native.captureException(error);
    } finally {
      setUpdatesFetched(true);
    }
  };

  const [fontsLoaded] = useFonts({
    // eslint-disable-next-line camelcase
    NunitoSans_200ExtraLight,
    // eslint-disable-next-line camelcase
    NunitoSans_300Light,
    // eslint-disable-next-line camelcase
    NunitoSans_400Regular,
    // eslint-disable-next-line camelcase
    NunitoSans_600SemiBold,
    // eslint-disable-next-line camelcase
    NunitoSans_700Bold,
    // eslint-disable-next-line camelcase
    NunitoSans_800ExtraBold,
    // eslint-disable-next-line camelcase
    NunitoSans_900Black,
  });

  useEffect(() => {
    // fetch EAS updates on load
    fetchAppUpdates();
  }, []);

  useEffect(() => {
    if (fontsLoaded && updatesFetched) {
      setIsAppReady(true);
      SplashScreen.hideAsync();
    }
  }, [fontsLoaded, updatesFetched]);

  if (!appIsReady) {
    return null;
  }

  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <GestureHandlerRootView style={{ flex: 1 }}>
          <ThemeProvider />
        </GestureHandlerRootView>
      </PersistGate>
    </Provider>
  );
};
