export const generateUUID = (): string => {
  const chars = "0123456789abcdef";
  let uuid = "";

  for (let i = 0; i < 32; i++) {
    const randomIndex = Math.floor(Math.random() * 16);
    uuid += chars[randomIndex];
  }

  // Insert hyphens at the appropriate positions to create a UUID format
  uuid = `${uuid.substring(0, 8)}-${uuid.substring(8, 4)}-${uuid.substring(
    12,
    4
  )}-${uuid.substring(16, 4)}-${uuid.substring(20)}`;

  return uuid;
};
