import { api } from "../api-service";

export const STUDY_TAGS = {
  POSTS: "posts",
};

// This is to add tags in endpoints that need it
export const enhancedStudiesApiService = api.enhanceEndpoints({
  addTagTypes: [STUDY_TAGS.POSTS],
  endpoints: {
    studiesControllerFindAllPosts: {
      providesTags: [STUDY_TAGS.POSTS],
    },
  },
});
