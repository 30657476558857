import React, { useEffect } from "react";
import { PostComponentProps } from "./post.component";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { selectUserProfile, selectUserStudyId } from "../../store/user.slice";
import {
  useStudiesControllerCreateCommentForPostMutation,
  useStudiesControllerFindPostCommentsByPostIdQuery,
  useStudiesControllerUpsertInteractionForPostMutation,
} from "../../services/api-service-sub-services/studies-api-service";
import dayjs from "dayjs";
import { analyticsSlice, Modules } from "../../store/analytics.slice";
import { useIsFocused, useNavigation } from "@react-navigation/native";
import { useTreatmentArmExperience } from "../core/hooks/use-treatment-arm-experience";
import { Routes } from "../../navigation/routes";
import { useTrackEvent } from "../core/hooks/use-track-event";
import { events } from "../../constants/analytics.constants";
import { selectSimulatedProfiles } from "../../store/app.slice";
import { extractControlledMentions } from "./utils/mentions";
import { useReactivePopupsSideEffect } from "../core/hooks/use-reactive-popups-side-effect";
import { postSlice, selectPostInteractions } from "../../store/post.slice";

// TODO: REFACTOR THESE
export type PostContainerProps = Pick<
  PostComponentProps,
  | "post"
  | "defaultCommentContent"
  | "isCommentSectionVisibleByDefault"
  | "highlightedCommentId"
  | "onCommentButtonPress"
  | "onNewCommentTextChange"
>;

export type Interaction = {
  [key: string]: number;
};

export const PostContainer = (Component: React.FC<PostComponentProps>) => {
  return function _(props: PostContainerProps) {
    const track = useTrackEvent();
    const navigation = useNavigation();
    const isFocused = useIsFocused();
    const dispatch = useAppDispatch();
    const simulatedProfiles = useAppSelector(selectSimulatedProfiles);

    const studyId = useAppSelector(selectUserStudyId);
    const userProfile = useAppSelector(selectUserProfile);
    const treatmentArmExperience = useTreatmentArmExperience();
    const { triggerPopupSideEffect } = useReactivePopupsSideEffect();

    // comments
    const { data: paginatedComments, refetch: refetchComments } =
      useStudiesControllerFindPostCommentsByPostIdQuery(
        { id: studyId as string, postId: props.post._id, limit: 100 },
        // Return only the necessary data since this will be constantly querying
        {
          skip: !isFocused,
          pollingInterval: 900,
          selectFromResult: ({ data }) => ({ data }),
        }
      );

    // mutation for creating new comments
    const [postNewComment, { isLoading: isCommentPosting }] =
      useStudiesControllerCreateCommentForPostMutation({
        selectFromResult: ({ isLoading }) => ({ isLoading }),
      });

    // mutation for saving the interaction pressed by the user
    const [saveInteraction, { isLoading: isSavingInteraction }] =
      useStudiesControllerUpsertInteractionForPostMutation({
        selectFromResult: ({ isLoading }) => ({ isLoading }),
      });

    const mappedCustomInteractionsToCounterObject =
      treatmentArmExperience.interactions.customInteractions.reduce<{
        [key: string]: number;
      }>((object, item) => {
        object[item.name] = 0;
        return object;
      }, {});

    useEffect(() => {
      dispatch(
        postSlice.actions.initializedPostInteractions({
          postId: props.post._id,
          data: {
            activeInteraction: props.post.interactionByMe ?? "none",
            counters: {
              like: props.post.likes || 0,
              dislike: props.post.dislikes || 0,
              ...mappedCustomInteractionsToCounterObject,
              ...props.post.customInteractionsCount,
            },
          },
        })
      );
    }, []);

    const {
      counters: interactionCounters,
      activeInteraction: currentInteraction,
    } = useAppSelector(selectPostInteractions(props.post._id));

    const onInteractionButtonPress = async (interaction: string) => {
      if (!studyId || !userProfile || isSavingInteraction) {
        return;
      }
      try {
        const isCustomInteraction =
          treatmentArmExperience.interactions.customInteractions.some(
            (i) => i.name === interaction
          );

        await saveInteraction({
          id: studyId,
          postId: props.post._id,
          createInteractionDto: {
            action: interaction,
            profile: userProfile._id,
            affiliation:
              treatmentArmExperience.interactions.isWithVariants &&
              !isCustomInteraction
                ? treatmentArmExperience.interactions.variant!
                : "",
          },
        }).unwrap();

        dispatch(
          postSlice.actions.receivedInteractionUpdate({
            postId: props.post._id,
            data: {
              interaction: interaction,
            },
          })
        );

        dispatch(
          analyticsSlice.actions.addItemToQueue({
            study: studyId,
            participant: userProfile._id,
            module: Modules.INTERACTIONS,
            meta: {
              description: `User modified interaction to post`,
              interaction: interaction,
              postId: props.post._id,
              timestamp: dayjs.utc().toISOString(),
            },
          })
        );

        await triggerPopupSideEffect("when-user-engages-post");
      } catch (e) {
        console.error("interactions failed to submit", e);
      }
    };

    return (
      <Component
        customInteractions={
          treatmentArmExperience.interactions.customInteractions
        }
        onProfilePress={(profile = props.post.profile) =>
          // @ts-ignore
          navigation.navigate(Routes.APP_STACK__BOT_PROFILE, {
            profile: profile,
          })
        }
        interaction={interactionCounters}
        currentInteraction={currentInteraction}
        highlightedCommentId={props.highlightedCommentId}
        defaultCommentContent={props.defaultCommentContent}
        isCommentSectionVisibleByDefault={
          props.isCommentSectionVisibleByDefault
        }
        isCommentPosting={isCommentPosting}
        post={props.post}
        isInteractionsLoading={isSavingInteraction}
        comments={paginatedComments?.docs || []}
        onCommentButtonPress={props.onCommentButtonPress}
        onNewCommentTextChange={props.onNewCommentTextChange}
        onInteractionButtonPress={onInteractionButtonPress}
        onPostCommentButtonPress={async (content: string) => {
          try {
            if (!studyId || !userProfile) {
              return;
            }

            const { taggedProfiles, contentWithOrWithoutMentions } =
              extractControlledMentions(content, simulatedProfiles);

            await postNewComment({
              id: studyId,
              postId: props.post._id,
              createCommentDto: {
                content: contentWithOrWithoutMentions,
                date: dayjs().toISOString(),
                profile: userProfile._id,
                mediaUrls: [],
                taggedProfiles: taggedProfiles,
              },
            }).unwrap();
            refetchComments();
            triggerPopupSideEffect("when-user-creates-comment");
            track(events.userCreatedComment);
          } catch (e: any) {
            track(events.userUnableToCreateComment);
            console.log("ERROR", e as Error);
          }
        }}
        onShowCommentButtonPress={() => {
          if (!userProfile || !studyId || !paginatedComments) {
            return;
          }
          track(events.userOpenedCommentsThead, {
            postId: props.post._id,
          });
          dispatch(
            analyticsSlice.actions.addItemToQueue({
              participant: userProfile._id,
              module: Modules.COMMENTS,
              study: studyId,
              meta: {
                description: "User opened comment thread",
                timestamp: dayjs.utc().toISOString(),
                postId: props.post._id,
                // TODO: Handle pagination of comments in the future
                commentIds: paginatedComments?.docs.map(({ _id }) => _id) || [],
              },
            })
          );
        }}
      />
    );
  };
};
