import React from "react";
import {
  AspectRatio,
  Box,
  HStack,
  ScrollView,
  Text,
  View,
  VStack,
} from "native-base";
import { ProfileMetadata } from "../core/components/profile-metadata";
import { PostComponent } from "../posts/post.component";
import { PostReturnDto } from "../../services/api-service-sub-services/studies-api-service";
import { PostContainer } from "../posts/post.container";
import { ParticipantProfileWithPropertiesDto } from "../../services/api-service-sub-services/users-api-service";
import { Platform, TouchableOpacity } from "react-native";
import { ProfileAvatar } from "../core/components/profile-avatar";
import { NoPostIcon } from "../core/icons/no-post-icon";
import { PlaneIcon } from "../core/icons/plane-icon";

const Post = PostContainer(PostComponent);

export type ProfileScreenProps = {
  posts?: PostReturnDto[];
  profile: ParticipantProfileWithPropertiesDto;
  postCount: number;
  onNewPostButtonPress: () => void;
};

export const ProfileScreen = (props: ProfileScreenProps) => {
  return (
    <VStack variant="scrollable-screen" safeAreaTop>
      <VStack space="3" backgroundColor="lightGrey" pb="2">
        <ProfileMetadata profile={props.profile} postCount={props.postCount} />
        <Box overflow="hidden" pb="5px">
          <Box
            borderTopColor="xLightGrey"
            borderTopWidth="0.5px"
            bg="white"
            px="5"
            py="3"
            shadow={Platform.OS === "ios" ? "0" : "1"}
          >
            <TouchableOpacity
              onPress={props.onNewPostButtonPress}
              style={{
                display: "flex",
                flexDirection: "row",
                backgroundColor: "white",
              }}
            >
              <AspectRatio ratio={1} height="12">
                <ProfileAvatar
                  profile={props.profile}
                  length={Platform.OS === "web" ? "8" : "100%"}
                  small={true}
                />
              </AspectRatio>
              <HStack ml="3" pt="1" flex="1" justifyContent="space-between">
                <Text color="darkGrey">Share your thoughts here...</Text>
                <View alignSelf="center">
                  <PlaneIcon />
                </View>
              </HStack>
            </TouchableOpacity>
          </Box>
        </Box>
      </VStack>

      {!props.posts ||
        (props.posts.length === 0 && (
          <VStack alignItems="center" justifyContent="center" flex="1">
            <NoPostIcon />
            <Text variant="heading" fontWeight="700">
              No posts
            </Text>
            <Text fontSize="lg">
              Here's where your created posts will show up
            </Text>
          </VStack>
        ))}

      {props.posts && props.posts.length > 0 && (
        <ScrollView flex="1" bgColor="xLightGrey">
          <VStack space="3">
            {props.posts.map((post) => {
              return <Post key={post._id} post={post} />;
            })}
          </VStack>
        </ScrollView>
      )}
    </VStack>
  );
};
