import React from "react";
import { Box, Button, Flex, Image, Text, View } from "native-base";
import { StatusBar } from "expo-status-bar";
import { SplashIcon } from "../../assets/images";
import { useAppSelector } from "../../store/store";
import { selectAppVersion } from "../../store/app.slice";
import Constants from "expo-constants";
import dayjs from "dayjs";

export type WelcomeScreenProps = {
  onEnterMagicCodePress: () => void;
};

export const WelcomeScreen = (props: WelcomeScreenProps) => {
  const appVersion = useAppSelector(selectAppVersion);
  return (
    <View
      variant="screen"
      bgColor="primary"
      justifyContent="center"
      alignItems="center"
    >
      <Flex flex="1" />
      <Image
        source={SplashIcon}
        height="72"
        width="72"
        alt="logo"
        resizeMode="contain"
      />
      <Flex flex="1" />
      <Button onPress={props.onEnterMagicCodePress} variant="secondary">
        Enter Invite Code
      </Button>
      <Box py="2">
        <Text variant="small" fontSize="10" color="xLightGrey">
          Copyright © {dayjs().format("YYYY")} • v{appVersion}+
          {Constants.expoConfig?.extra?.buildNumber}
        </Text>
      </Box>
      <StatusBar style="light" />
    </View>
  );
};
