import React from "react";
import { Box, HStack, Menu, Pressable, Text } from "native-base";
import { useBlockBotProfile } from "../hooks/use-block-bot-profile";
import { Entypo } from "@expo/vector-icons";
import { IconThreeDot } from "../../../assets/svgs/icon-three-dot";

type Props = {
  profileId: string;
};
export const ProfileActions = (props: Props) => {
  const blockBotProfile = useBlockBotProfile();
  return (
    <Box
      w="90%"
      alignItems="center"
      borderLeftRadius="8"
      borderBottomRightRadius="8"
      bgColor="white"
    >
      <Menu
        w="190"
        trigger={(triggerProps) => {
          return (
            <Pressable
              accessibilityLabel="More options menu"
              {...triggerProps}
              mt="-2"
            >
              <IconThreeDot />
            </Pressable>
          );
        }}
      >
        <Box>
          <Pressable
            pl="4"
            py="0.5"
            _pressed={{
              bgColor: "medGrey",
            }}
            onPress={async () => {
              await blockBotProfile(props.profileId);
            }}
          >
            <HStack space="2" alignItems="center">
              <Entypo name="block" size={16} color="red" />
              <Text variant="small" fontSize="16">
                Block User
              </Text>
            </HStack>
          </Pressable>
        </Box>
      </Menu>
    </Box>
  );
};
