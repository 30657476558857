import { Center, Circle, Text } from "native-base";
import React from "react";

interface Props {
  count: number;
}

export const NotificationBadge: React.FC<Props> = (props) => {
  return (
    <Circle
      position="absolute"
      top={-2}
      right={-2}
      bg="primary"
      zIndex={2}
      size={4}
    >
      <Center>
        <Text color="white" fontSize={8}>
          {props.count > 9 ? "9+" : props.count}
        </Text>
      </Center>
    </Circle>
  );
};
