import { api } from "../api-service";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    extensionsControllerFindLambdaFunctions: build.query<
      ExtensionsControllerFindLambdaFunctionsApiResponse,
      ExtensionsControllerFindLambdaFunctionsRequest
    >({
      query: () => ({ url: `/extensions` }),
    }),
    extensionsControllerInvokeLambdaFunction: build.mutation<
      ExtensionsControllerInvokeLambdaFunctionApiResponse,
      ExtensionsControllerInvokeLambdaFunctionRequest
    >({
      query: (queryArg) => ({
        url: `/extensions/${queryArg.provider}/invocations`,
        method: "POST",
        body: queryArg.invokeLambdaExtensionDto,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type ExtensionsControllerFindLambdaFunctionsApiResponse =
  /** status 200 The list of functions from providers like AWS Lambda */ GetExtensionsResponseDto[];
export type ExtensionsControllerFindLambdaFunctionsRequest = void;
export type ExtensionsControllerInvokeLambdaFunctionApiResponse =
  /** status 201 The list of functions from providers like AWS Lambda */ undefined;
export type ExtensionsControllerInvokeLambdaFunctionRequest = {
  /** The provider of the lambda function */
  provider: any;
  invokeLambdaExtensionDto: InvokeLambdaExtensionDto;
};
export type GetExtensionsResponseDto = {
  name: string;
  description: string;
  provider: string;
};
export type ApiUnauthorizedResponseDto = {
  statusCode: number;
  message: string;
};
export type ApiNotFoundResponseDto = {
  statusCode: number;
  message: string;
};
export type BadRequestDto = {
  statusCode: number;
  message: string;
};
export type InvokeLambdaExtensionDto = {
  name: string;
};
export const {
  useExtensionsControllerFindLambdaFunctionsQuery,
  useLazyExtensionsControllerFindLambdaFunctionsQuery,
  useExtensionsControllerInvokeLambdaFunctionMutation,
} = injectedRtkApi;
