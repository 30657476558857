import * as React from "react";
import Svg, { Path, SvgProps } from "react-native-svg";

const SvgComponent = (props: SvgProps) => (
  <Svg width={24} height={24} fill="none" {...props}>
    <Path
      fill={props.color || "#83899B"}
      d="M20.236 21.284h-6.84a.897.897 0 0 1-.894-.894v-4.247c0-1.073-.85-1.922-1.922-1.922-1.073 0-1.922.85-1.922 1.922v4.247a.897.897 0 0 1-.894.894h-6.75a.897.897 0 0 1-.895-.894V8.946c0-.805.402-1.565 1.028-2.012L10.133.452a.872.872 0 0 1 1.028 0l8.986 6.482a2.498 2.498 0 0 1 1.028 2.012v11.489a.964.964 0 0 1-.939.85Zm-5.946-1.743h5.097V8.946c0-.224-.134-.447-.313-.581l-8.45-6.125-8.448 6.08c-.18.134-.313.357-.313.581v10.595h5.096v-3.353a3.682 3.682 0 0 1 3.666-3.666 3.682 3.682 0 0 1 3.665 3.666v3.398Z"
    />
  </Svg>
);
export { SvgComponent as NavHomeIcon };
