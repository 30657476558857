import React, { useCallback, useEffect, useState } from "react";
import { NotificationScreenProps } from "./notification.screen";
import {
  Notification,
  useLazyNotificationsControllerFindAllForUserQuery,
  useNotificationsControllerFindAllForUserQuery,
  useNotificationsControllerUpdateMutation,
} from "../../services/api-service-sub-services/notifications-api-service";
import { useAppDispatch, useAppSelector } from "../../store/store";
import {
  selectUserId,
  selectUserProfile,
  selectUserStudyId,
} from "../../store/user.slice";
import { useToast } from "native-base";
import lodash from "lodash";
import {
  notificationSlice,
  selectIsNotificationsEnabled,
} from "../../store/notification.slice";
import { useFocusEffect, useIsFocused } from "@react-navigation/native";
import { Routes } from "../../navigation/routes";
import { StackScreenProps } from "@react-navigation/stack";
import { NotificationStackParamList } from "../../navigation/navigators/notification-stack.navigator";
import { analyticsSlice, Modules } from "../../store/analytics.slice";
import dayjs from "dayjs";
import { useTrackEvent } from "../core/hooks/use-track-event";
import { events } from "../../constants/analytics.constants";

const LIMIT = 20;

export type NotificationContainerProps = StackScreenProps<
  NotificationStackParamList,
  Routes.APP_STACK__MAIN__NOTIFICATIONS__USER_NOTIFICATIONS
>;

export const NotificationContainer = (
  Screen: React.FC<NotificationScreenProps>
) =>
  function _(props: NotificationContainerProps) {
    const isFocused = useIsFocused();
    const toast = useToast();
    const track = useTrackEvent();

    const userId = useAppSelector(selectUserId);
    const studyId = useAppSelector(selectUserStudyId);
    const profile = useAppSelector(selectUserProfile);
    const isNotificationsEnabled = useAppSelector(selectIsNotificationsEnabled);

    const dispatch = useAppDispatch();

    // Make initial state of page to 2 since the first page will always be polling
    const [page, setPage] = useState(2);
    const [isFetchingOlderNotifications, setIsFetchingOlderNotifications] =
      useState(false);
    const [notifications, setNotifications] = useState<Notification[]>([]);
    // Updated notification hashmap to prevent conflicts with the array state because of the polling
    const [updatedNotificationHashmap, setUpdatedNotificationHashmap] =
      useState<{ [key: string]: boolean }>({});

    const [queryUserNotifications] =
      useLazyNotificationsControllerFindAllForUserQuery({
        selectFromResult: () => ({}),
      });
    const [updateNotification] = useNotificationsControllerUpdateMutation({
      selectFromResult: () => ({}),
    });

    // TODO: Combine this with use-global-notification-listener in the future
    const { data, isLoading } = useNotificationsControllerFindAllForUserQuery(
      { page: 1, limit: LIMIT },
      {
        skip: !isFocused || !isNotificationsEnabled,
        pollingInterval: 10000,
        selectFromResult: ({ data, isLoading }) => ({ data, isLoading }),
      }
    );

    const onBottomReached = useCallback(async () => {
      try {
        setIsFetchingOlderNotifications(true);

        const shouldNotQuery =
          isFetchingOlderNotifications || page > (data?.totalPages || 0);

        if (shouldNotQuery) {
          return;
        }

        const notifications = await queryUserNotifications({
          page,
          limit: LIMIT,
        }).unwrap();

        setPage((prev) => prev + 1);
        setNotifications((prev) =>
          lodash.uniqBy([...prev, ...notifications.docs], "_id")
        );
        track(events.userLoadedMoreNotifications);
      } catch (error: any) {
        toast.show({
          title: "Error",
          description: error.message || "Something went wrong.",
        });
      } finally {
        setIsFetchingOlderNotifications(false);
      }
    }, [data?.totalPages, isFetchingOlderNotifications, page]);

    useFocusEffect(() => {
      dispatch(notificationSlice.actions.resetBadgeCount());
    });

    // This handles polling of latest notifications
    useEffect(() => {
      if (data?.docs) {
        setNotifications((prev) =>
          lodash.uniqBy([...data.docs, ...prev], "_id")
        );
      }
    }, [data?.docs]);

    return (
      <Screen
        userId={userId!}
        notifications={notifications}
        updatedNotificationHashmap={updatedNotificationHashmap}
        isFetchingNotifications={isLoading || isFetchingOlderNotifications}
        onBottomReached={onBottomReached}
        onNotificationClick={async (notification, isSeen) => {
          const notificationMeta = notification.meta as {
            [key: string]: any;
          };

          if (!isSeen) {
            try {
              await updateNotification({
                id: notification._id,
                updateNotificationDto: { seen: true },
              }).unwrap();
              track(events.userReadNotification, {
                notificationId: notification._id,
                seen: true,
              });

              setUpdatedNotificationHashmap((prev) => ({
                ...prev,
                [notification._id]: true,
              }));
            } catch (error: any) {
              setUpdatedNotificationHashmap((prev) =>
                lodash.omit(prev, notification._id)
              );

              toast.show({
                title: "Error",
                description: error.message || "Something went wrong.",
              });
            }
          }

          if (profile?._id && studyId) {
            dispatch(
              analyticsSlice.actions.addItemToQueue({
                participant: profile?._id,
                module: Modules.NOTIFICATIONS,
                study: studyId,
                meta: {
                  description: "User opened notification",
                  timestamp: dayjs.utc().toISOString(),
                  ...(notificationMeta?.postId && {
                    postId: notificationMeta.postId,
                  }),
                  ...(notificationMeta?.commentId && {
                    targetCommentId: notificationMeta.commentId,
                  }),
                },
              })
            );
          }

          if (notificationMeta?.postId) {
            track(events.userOpenedNotification, {
              notificationId: notification._id,
              notificationType: "comment",
              commentId: notificationMeta.commentId,
              postId: notificationMeta.postId,
            });
            props.navigation.push(Routes.APP_STACK__SINGLE_POST, {
              postId: notificationMeta?.postId,
              highlightedCommentId: notificationMeta?.commentId,
            });
          }

          if (notificationMeta?.profileId) {
            track(events.userOpenedNotification, {
              notificationId: notification._id,
              notificationType: "new_follower",
              profileId: notificationMeta?.profileId,
            });
            props.navigation.push(Routes.APP_STACK__BOT_PROFILE, {
              profile: notificationMeta?.profileId,
            });
          }
        }}
        onNotificationSwipe={async (notificationId) => {
          try {
            const notification = notifications.find(
              (_notification) => _notification._id === notificationId
            );

            if (!notification) {
              throw new Error("Notification not found.");
            }

            const notificationMeta = notification.meta as {
              [key: string]: any;
            };

            const isSeen =
              notificationId in updatedNotificationHashmap
                ? updatedNotificationHashmap[notificationId]
                : notification.seenBy.some(({ user }) => user === userId);

            setUpdatedNotificationHashmap((prev) => ({
              ...prev,
              [notificationId]: !isSeen,
            }));

            if (profile?._id && studyId) {
              dispatch(
                analyticsSlice.actions.addItemToQueue({
                  participant: profile._id,
                  module: Modules.NOTIFICATIONS,
                  study: studyId,
                  meta: {
                    description: isSeen
                      ? "User marked notification as unread"
                      : "User marked notification as read",
                    timestamp: dayjs.utc().toISOString(),
                    ...(notificationMeta?.postId && {
                      postId: notificationMeta.postId,
                    }),
                    ...(notificationMeta?.commentId && {
                      targetCommentId: notificationMeta.commentId,
                    }),
                  },
                })
              );
              track(
                isSeen
                  ? events.userUnreadNotification
                  : events.userReadNotification,
                {
                  notificationId: notification._id,
                }
              );
            }

            await updateNotification({
              id: notification._id,
              updateNotificationDto: { seen: !isSeen },
            }).unwrap();
          } catch (error: any) {
            // Remove from hashmap if an error was thrown to prioritize isSeen using the array
            setUpdatedNotificationHashmap((prev) =>
              lodash.omit(prev, notificationId)
            );

            toast.show({
              title: "Error",
              description: error.message || "Something went wrong.",
            });
          }
        }}
      />
    );
  };
