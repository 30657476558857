import { useTrackEvent } from "./use-track-event";
import { useFocusEffect } from "@react-navigation/native";

export const useTrackScreenFocus = (event: string) => {
  const track = useTrackEvent();

  useFocusEffect(() => {
    track(event);
  });
};
