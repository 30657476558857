import { useFeatureFlag } from "posthog-react-native";

/**
 * A hook to check if a feature is enabled. This hook serves as the abstraction layer for the feature flagging library.
 * @param {string} flag - the feature flag to check
 */
export const useFeatureAvailability = (flag: FeatureFlags) => {
  const isFeatureEnabled = useFeatureFlag(flag);
  // evaluates to true if the feature is enabled (string, boolean), false otherwise (undefined)
  return !!isFeatureEnabled;
};

export type FeatureFlags = "bot-tagging" | "connections";
