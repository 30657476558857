import { NavigationContainer } from "@react-navigation/native";
import { StatusBar } from "expo-status-bar";
import React from "react";
import { AuthStackNavigator } from "./navigators/auth-stack.navigator";
import { useAppSelector } from "../store/store";
import { selectUserIsAuthenticated } from "../store/user.slice";
import { AppStackNavigator } from "./navigators/app-stack.navigator";
import * as Linking from "expo-linking";
import { Text } from "native-base";
import { PostHogProvider } from "posthog-react-native";
import Constants from "expo-constants";
const prefix = Linking.createURL("/");

export const NavContainer = () => {
  const linking = {
    prefixes: [prefix],
  };

  const isAuthenticated = useAppSelector(selectUserIsAuthenticated);

  return (
    <NavigationContainer linking={linking} fallback={<Text>Loading...</Text>}>
      <PostHogProvider
        apiKey={Constants.expoConfig?.extra?.posthogApiKey}
        options={{
          host: "https://app.posthog.com",
        }}
      >
        {isAuthenticated ? <AppStackNavigator /> : <AuthStackNavigator />}
        <StatusBar style="auto" />
      </PostHogProvider>
    </NavigationContainer>
  );
};
