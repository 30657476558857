import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query/react";
import { RootState } from "../store/store";
import Constants from "expo-constants";

const fetchDynamicBaseQuery: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const baseUrl = Constants.expoConfig?.extra?.apiBaseUrl;
  const dynamicBaseQueryWithHeaders = fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { getState }) => {
      // use getState instead of state to avoid using previous accessToken
      const token = (getState() as RootState).user.accessToken;
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  });

  return dynamicBaseQueryWithHeaders(args, api, extraOptions);
};

export const api = createApi({
  reducerPath: "api",
  baseQuery: fetchDynamicBaseQuery,
  endpoints: () => ({}),
});
