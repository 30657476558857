import { api } from "../api-service";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    notificationsControllerFindAll: build.query<
      NotificationsControllerFindAllApiResponse,
      NotificationsControllerFindAllRequest
    >({
      query: (queryArg) => ({
        url: `/notifications`,
        params: {
          page: queryArg.page,
          limit: queryArg.limit,
          date: queryArg.date,
        },
      }),
    }),
    notificationsControllerFindAllForUser: build.query<
      NotificationsControllerFindAllForUserApiResponse,
      NotificationsControllerFindAllForUserRequest
    >({
      query: (queryArg) => ({
        url: `/notifications/me`,
        params: { page: queryArg.page, limit: queryArg.limit },
      }),
    }),
    notificationsControllerUpdate: build.mutation<
      NotificationsControllerUpdateApiResponse,
      NotificationsControllerUpdateRequest
    >({
      query: (queryArg) => ({
        url: `/notifications/${queryArg.id}`,
        method: "PATCH",
        body: queryArg.updateNotificationDto,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type NotificationsControllerFindAllApiResponse =
  /** status 200 The requested paginated `Notification` documents are returned. */ NotificationPaginatedReturn;
export type NotificationsControllerFindAllRequest = {
  /** The page number to retrieve. */
  page?: number;
  /** The number of documents to retrieve. */
  limit?: number;
  date?: string;
};
export type NotificationsControllerFindAllForUserApiResponse =
  /** status 200 The requested paginated `Notification` documents are returned. */ NotificationPaginatedReturn;
export type NotificationsControllerFindAllForUserRequest = {
  /** The page number to retrieve. */
  page?: number;
  /** The number of documents to retrieve. */
  limit?: number;
};
export type NotificationsControllerUpdateApiResponse =
  /** status 200 OK */ Notification;
export type NotificationsControllerUpdateRequest = {
  /** The ID of the Notification. */
  id: string;
  updateNotificationDto: UpdateNotificationDto;
};
export type SeenByEntity = {
  user: string;
  date: string;
};
export type Notification = {
  _id: string;
  user: object;
  body: string;
  title: string;
  date: string;
  visible: boolean;
  watchers: string[];
  seenBy: SeenByEntity[];
  meta?: object;
};
export type NotificationPaginatedReturn = {
  totalDocs: number;
  offset: number;
  limit: number;
  totalPages: number;
  page?: number;
  pagingCounter: number;
  hasPrevPage: boolean;
  hasNextPage: boolean;
  prevPage?: number;
  nextPage?: number;
  docs: Notification[];
};
export type ApiUnauthorizedResponseDto = {
  statusCode: number;
  message: string;
};
export type ApiNotFoundResponseDto = {
  statusCode: number;
  message: string;
};
export type UpdateNotificationDto = {
  seen: boolean;
};
export const {
  useNotificationsControllerFindAllQuery,
  useLazyNotificationsControllerFindAllQuery,
  useNotificationsControllerFindAllForUserQuery,
  useLazyNotificationsControllerFindAllForUserQuery,
  useNotificationsControllerUpdateMutation,
} = injectedRtkApi;
