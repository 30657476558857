import React, { Fragment } from "react";
import {
  Button,
  Center,
  Heading,
  ScrollView,
  Skeleton,
  Text,
  View,
  VStack,
} from "native-base";
import { ControlledCoreSelect } from "../core/components/form/core-select";
import { CharacteristicEntity } from "../../services/api-service-sub-services/studies-api-service";
import {
  getFormInputType,
  ProfileCharacteristics,
} from "./profile-setup.container";
import { useForm } from "react-hook-form";
import { ControlledCoreInput } from "../core/components/form/core-input";
import _ from "lodash";
import { ControlledCoreAvatarSelect } from "../core/components/form/core-avatar-select";
import { useToastErrorMessage } from "../core/hooks/use-toast-error-message";
import { ModalStackParams } from "../core/modals/modal-stack";
import { useModal } from "react-native-modalfy";

export type ProfileSetupScreenProps = {
  profileCharacteristics: CharacteristicEntity[];
  avatars: string[];
  onUpdateProfileButtonPress: (
    properties: {
      name: string;
      value: string;
    }[]
  ) => Promise<void>;
  isBusy: boolean;
};

export const ProfileSetupScreen = (props: ProfileSetupScreenProps) => {
  const form = useForm();

  const modal = useModal<ModalStackParams>();
  const openProfilePicSelector = () =>
    modal.openModal("AvatarSelectorModal", {
      onSelect: (item) => {
        form.setValue("profile-picture", item);
      },
      avatars: props.avatars,
    });

  const toastError = useToastErrorMessage();

  return (
    <Fragment>
      <VStack safeArea variant="screen" flex="1">
        <ScrollView
          px="5"
          keyboardDismissMode="on-drag"
          automaticallyAdjustKeyboardInsets={true}
          showsVerticalScrollIndicator={false}
        >
          <VStack pb="5" pt="10">
            <VStack>
              <Heading>Set up your profile</Heading>
              <Text variant="P1" mb="5" textAlign="left">
                Please create a profile below that tells other users a little
                about yourself.
              </Text>
            </VStack>

            {props.profileCharacteristics.length === 0 && <LoadingSkeleton />}

            {props.profileCharacteristics.length > 0 && (
              <VStack width="100%" space="6">
                {props.profileCharacteristics.some(
                  (characteristic) => characteristic.name === "profile-picture"
                ) && (
                  <View>
                    <ControlledCoreAvatarSelect
                      rules={{
                        required: "Please choose an avatar.",
                      }}
                      onPress={openProfilePicSelector}
                      control={form.control}
                      name="profile-picture"
                    />
                  </View>
                )}

                {props.profileCharacteristics.map((characteristic) => {
                  const fieldDisplayName =
                    characteristic.displayName || characteristic.name;

                  if (getFormInputType(characteristic) === "input") {
                    return (
                      <View key={characteristic._id}>
                        <ControlledCoreInput
                          rules={{
                            required: `${_.startCase(
                              fieldDisplayName
                            )} is required.`,
                          }}
                          key={characteristic.name}
                          control={form.control}
                          placeholder={(() => {
                            if (fieldDisplayName.toLowerCase() === "username") {
                              return `Please create a ${_.lowerCase(
                                fieldDisplayName
                              )}`;
                            }
                            return `Please enter your ${_.lowerCase(
                              fieldDisplayName
                            )}`;
                          })()}
                          name={characteristic.name}
                        />
                      </View>
                    );
                  }
                  if (getFormInputType(characteristic) === "text-area") {
                    return (
                      <View key={characteristic._id}>
                        <ControlledCoreInput
                          rules={{
                            required: `${_.startCase(
                              fieldDisplayName
                            )} is required.`,
                          }}
                          key={characteristic.name}
                          control={form.control}
                          placeholder={
                            characteristic.name === ProfileCharacteristics.BIO
                              ? "Tell us a little about yourself"
                              : `Please enter your ${_.lowerCase(
                                  fieldDisplayName
                                )}`
                          }
                          name={characteristic.name}
                        />
                      </View>
                    );
                  }
                  if (getFormInputType(characteristic) === "select") {
                    return (
                      <View key={characteristic._id}>
                        <ControlledCoreSelect
                          rules={{
                            required: `${_.startCase(
                              fieldDisplayName
                            )} is required.`,
                          }}
                          key={characteristic.name}
                          control={form.control}
                          name={characteristic.name}
                          items={characteristic.pool.map((item) => ({
                            label: item,
                            value: item,
                          }))}
                          placeholder={`Please select your ${_.lowerCase(
                            fieldDisplayName
                          )}`}
                        />
                      </View>
                    );
                  }
                  return null;
                })}

                <Button
                  variant="primary"
                  isLoading={props.isBusy}
                  isDisabled={props.isBusy}
                  isLoadingText="Setting Up Profile..."
                  onPress={async () => {
                    const isValid = await form.trigger();
                    if (!isValid) {
                      toastError({
                        message:
                          "Please fill in all the required information to continue.",
                      });
                      return;
                    }
                    // submit the values if the form is valid
                    const handleSubmit = form.handleSubmit((values) => {
                      const properties: {
                        name: string;
                        value: string;
                      }[] = [];
                      Object.keys(values).map((obj) =>
                        properties.push({
                          name: obj,
                          value: values[obj],
                        })
                      );
                      props.onUpdateProfileButtonPress(properties);
                    });

                    await handleSubmit();
                  }}
                >
                  Continue
                </Button>
              </VStack>
            )}
          </VStack>
        </ScrollView>
      </VStack>
      {/*<BottomSheet*/}
      {/*  snapPoints={["60%", "80%"]}*/}
      {/*  enablePanDownToClose*/}
      {/*  index={-1}*/}
      {/*  ref={bottomSheetRef}*/}
      {/*  handleStyle={{*/}
      {/*    backgroundColor: "#F4F6FA",*/}
      {/*    borderTopLeftRadius: 20,*/}
      {/*    borderTopRightRadius: 20,*/}
      {/*  }}*/}
      {/*>*/}
      {/*  <BottomSheetScrollView*/}
      {/*    contentContainerStyle={{*/}
      {/*      backgroundColor: "#F4F6FA",*/}
      {/*    }}*/}
      {/*  >*/}
      {/*    <VStack space="5" px="5">*/}
      {/*      <VStack>*/}
      {/*        <Heading>Choose an Avatar</Heading>*/}
      {/*      </VStack>*/}
      {/*      <HStack flex="1" flexWrap="wrap" justifyContent="space-between">*/}
      {/*        {props.avatars.map((item, index) => {*/}
      {/*          return (*/}
      {/*            <TouchableOpacity*/}
      {/*              key={index}*/}
      {/*              onPress={() => {*/}
      {/*                form.setValue("profile-picture", item);*/}
      {/*                bottomSheetRef.current?.close();*/}
      {/*              }}*/}
      {/*            >*/}
      {/*              <Box*/}
      {/*                key={index}*/}
      {/*                m="3"*/}
      {/*                height="24"*/}
      {/*                width="24"*/}
      {/*                bgColor="xLightGrey"*/}
      {/*                borderRadius="100"*/}
      {/*                alignItems="center"*/}
      {/*                justifyContent="flex-end"*/}
      {/*                shadow="1"*/}
      {/*                overflow="hidden"*/}
      {/*              >*/}
      {/*                <Image*/}
      {/*                  backgroundColor="red.50"*/}
      {/*                  source={{ uri: item }}*/}
      {/*                  width="100%"*/}
      {/*                  alt="Avatar"*/}
      {/*                  height="100%"*/}
      {/*                  resizeMethod="scale"*/}
      {/*                  resizeMode="cover"*/}
      {/*                />*/}
      {/*              </Box>*/}
      {/*            </TouchableOpacity>*/}
      {/*          );*/}
      {/*        })}*/}
      {/*      </HStack>*/}
      {/*    </VStack>*/}
      {/*  </BottomSheetScrollView>*/}
      {/*</BottomSheet>*/}
    </Fragment>
  );
};

const LoadingSkeleton = () => {
  return (
    <Center w="100%">
      <VStack
        width="100%"
        overflow="hidden"
        rounded="md"
        _dark={{
          borderColor: "coolGray.500",
        }}
        _light={{
          borderColor: "coolGray.200",
        }}
        space="5"
      >
        <VStack space="3">
          <Skeleton.Text h="3" />
          <Skeleton h="10" />
        </VStack>
        <VStack space="3">
          <Skeleton.Text h="3" />
          <Skeleton h="10" />
        </VStack>
        <VStack space="3">
          <Skeleton.Text h="3" />
          <Skeleton h="10" />
        </VStack>
        <VStack space="3">
          <Skeleton.Text h="3" />
          <Skeleton h="10" />
        </VStack>
      </VStack>
    </Center>
  );
};
