import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  Box,
  Button,
  HStack,
  PresenceTransition,
  Spinner,
  Text,
  View,
  VStack,
} from "native-base";
import { PostComponent } from "../posts/post.component";
import { PostReturnDto } from "../../services/api-service-sub-services/studies-api-service";
import {
  AppState,
  AppStateStatus,
  Platform,
  TouchableOpacity,
  ViewToken,
} from "react-native";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { selectUserProfile, selectUserStudyId } from "../../store/user.slice";
import { PostContainer } from "../posts/post.container";
import dayjs from "dayjs";
import { CreateAnalyticsItemDto } from "../../services/api-service-sub-services/analytics-api-service";
import { analyticsSlice, Modules } from "../../store/analytics.slice";
import { FlashList, ListRenderItemInfo } from "@shopify/flash-list";
import _ from "lodash";
import { AntDesign } from "@expo/vector-icons";
import { useScrollToTop } from "@react-navigation/native";
import { NewPostPromptSmall } from "../posts/components/new-post-prompt-small";

const Post = PostContainer(PostComponent);

export type FeedScreenProps = {
  posts?: PostReturnDto[];
  numberOfNewPosts: number;
  isFetchingPosts: boolean;
  isRefreshing: boolean;
  onRefresh: () => void;
  onBottomReached: () => Promise<void>;
  onNewPostButtonPress: () => void;
};

export const FeedScreen = ({
  isFetchingPosts,
  onBottomReached,
  numberOfNewPosts,
  ...props
}: FeedScreenProps) => {
  const dispatch = useAppDispatch();

  const [isOnTopOfFeed, setIsOnTopOfFeed] = useState(true);

  // scroll to top when active tab is tapped
  const flashlistRef = useRef<FlashList<PostReturnDto> | null>(null);
  const viewedItemsRef = useRef<{ [key: string]: string | null }>({});
  const defaultContentHashmap = useRef<{
    [key: string]: {
      isCommentSectionVisibleByDefault: boolean;
      defaultCommentContent: string;
    };
  }>({});

  useScrollToTop(flashlistRef as any);

  const studyId = useAppSelector(selectUserStudyId);
  const userProfile = useAppSelector(selectUserProfile);

  const viewabilityConfigRef = useRef({
    minimumViewTime: 100,
    viewAreaCoveragePercentThreshold: 0,
  });
  const onViewableItemsChangedRef = useRef(
    ({ viewableItems }: { viewableItems: ViewToken[] }) => {
      const postsToAddToQueue: CreateAnalyticsItemDto[] = [];

      viewableItems.forEach(({ item }) => {
        const dateString = viewedItemsRef.current[item._id];
        const currentISOString = dayjs.utc().toISOString();

        if (dateString) {
          if (!userProfile || !studyId) {
            return;
          }

          // Record dwell time when they leave the post
          postsToAddToQueue.push({
            module: Modules.POSTS,
            participant: userProfile._id,
            study: studyId,
            meta: {
              description: "User dwell time on post",
              timestamp: currentISOString,
              postId: item._id,
              dwellTimeInSeconds: Math.round(
                dayjs.utc(currentISOString).diff(dateString) / 1000
              ),
            },
          });

          // Set post to null to reset the time
          viewedItemsRef.current[item._id] = null;
        } else {
          // Add start time of when they viewed the post
          viewedItemsRef.current[item._id] = currentISOString;
        }
      });

      if (postsToAddToQueue.length) {
        dispatch(analyticsSlice.actions.addItemsToQueue(postsToAddToQueue));
      }
    }
  );

  const renderItem = useCallback(
    ({ item }: ListRenderItemInfo<PostReturnDto>) => {
      return (
        <View key={item._id} shadow="1" bg="white">
          <Post
            post={item}
            defaultCommentContent={
              defaultContentHashmap.current[item._id]?.defaultCommentContent
            }
            isCommentSectionVisibleByDefault={Boolean(
              defaultContentHashmap.current[item._id]
                ?.isCommentSectionVisibleByDefault
            )}
            onCommentButtonPress={(isShown) => {
              defaultContentHashmap.current = {
                ...defaultContentHashmap.current,
                [item._id]: {
                  defaultCommentContent:
                    defaultContentHashmap.current[item._id]
                      ?.defaultCommentContent || "",
                  isCommentSectionVisibleByDefault: isShown,
                },
              };
            }}
            onNewCommentTextChange={(text) => {
              defaultContentHashmap.current = {
                ...defaultContentHashmap.current,
                [item._id]: {
                  defaultCommentContent: text,
                  isCommentSectionVisibleByDefault: Boolean(
                    defaultContentHashmap.current[item._id]
                      ?.isCommentSectionVisibleByDefault
                  ),
                },
              };
            }}
          />
        </View>
      );
    },
    []
  );

  const renderHeader = useCallback(() => {
    if (!numberOfNewPosts) return null;
    if (Platform.OS === "web") return null;

    return (
      <View
        p={0}
        h={10}
        borderRadius={0}
        bg="primary"
        alignItems="center"
        justifyContent="center"
      >
        <Text color="white">
          Pull to show {numberOfNewPosts} new post{numberOfNewPosts > 1 && "s"}
        </Text>
      </View>
    );
  }, [numberOfNewPosts, Platform]);

  const renderFooter = useCallback(() => {
    return (
      <Box h={8} display="flex" justifyContent="center">
        {isFetchingPosts ? <Spinner /> : null}
      </Box>
    );
  }, [isFetchingPosts]);

  const renderItemSeparator = useCallback(() => {
    return <View height="3" />;
  }, []);

  const debounceOnEndReached = useCallback(
    _.debounce(() => onBottomReached(), 300),
    [onBottomReached]
  );

  // Handles dwell time if user placed the app to the background
  useEffect(() => {
    const handleAppStateChange = (nextAppState: AppStateStatus) => {
      if (nextAppState !== "active") {
        const postsToAddToQueue: CreateAnalyticsItemDto[] = [];

        const viewedItemKeys = Object.keys(viewedItemsRef.current);

        // Add current viewed posts to the analytics
        viewedItemKeys.forEach((key) => {
          if (!userProfile || !studyId) {
            return;
          }

          const dateString = viewedItemsRef.current[key];
          const currentISOString = dayjs.utc().toISOString();

          if (dateString) {
            postsToAddToQueue.push({
              module: Modules.POSTS,
              participant: userProfile._id,
              study: studyId,
              meta: {
                description: "User dwell time on post",
                timestamp: currentISOString,
                postId: key,
                dwellTimeInSeconds: Math.round(
                  dayjs.utc(currentISOString).diff(dateString) / 1000
                ),
              },
            });
          }
        });

        if (postsToAddToQueue.length) {
          dispatch(analyticsSlice.actions.addItemsToQueue(postsToAddToQueue));
        }

        // Clear all the viewed items
        viewedItemsRef.current = {};
      }
    };

    const listener = AppState.addEventListener("change", handleAppStateChange);

    return () => {
      listener.remove();
    };
  }, [userProfile, studyId]);

  return (
    <Fragment>
      <VStack variant="scrollable-screen" flex="1" display="flex" safeAreaTop>
        <Box overflow="hidden" pb="5px">
          {/*Displays the Share your thoughts here section*/}
          <NewPostPromptSmall
            onPress={props.onNewPostButtonPress}
            profile={userProfile}
          />
        </Box>

        {Platform.OS === "web" && numberOfNewPosts > 0 && (
          <TouchableOpacity onPress={props.onRefresh}>
            <Box bg="primary" alignItems="center" justifyContent="center">
              <Text color="white">
                Load {numberOfNewPosts} new post
                {numberOfNewPosts > 1 && "s"}
              </Text>
            </Box>
          </TouchableOpacity>
        )}

        <View flex="1" backgroundColor="xLightGrey" position="relative">
          {!!props.posts?.length && (
            <FlashList
              ref={flashlistRef}
              data={props.posts}
              contentContainerStyle={{
                paddingTop: !!numberOfNewPosts ? 0 : 12,
                paddingBottom: 16,
              }}
              keyboardShouldPersistTaps="handled"
              keyboardDismissMode="on-drag"
              automaticallyAdjustKeyboardInsets
              keyExtractor={({ _id }) => _id}
              maintainVisibleContentPosition={{ minIndexForVisible: 0 }}
              ItemSeparatorComponent={renderItemSeparator}
              renderItem={renderItem}
              ListHeaderComponent={renderHeader}
              onViewableItemsChanged={onViewableItemsChangedRef.current}
              viewabilityConfig={viewabilityConfigRef.current}
              onEndReached={debounceOnEndReached}
              ListFooterComponentStyle={{
                paddingBottom: 8,
                backgroundColor: "xLightGrey",
                display: "flex",
                alignItems: "center",
              }}
              estimatedItemSize={510}
              onRefresh={props.onRefresh}
              refreshing={props.isRefreshing}
              onScroll={(e) =>
                setIsOnTopOfFeed(e.nativeEvent.contentOffset.y <= 40)
              }
              scrollEventThrottle={16}
              // Don't render unless there's at least 1 item
              // Otherwise it will start at the bottom
              {...(props.posts?.length && {
                ListFooterComponent: renderFooter,
              })}
            />
          )}

          <View
            position="absolute"
            top={2}
            w="full"
            display="flex"
            alignItems="center"
          >
            <PresenceTransition
              visible={!isOnTopOfFeed && numberOfNewPosts > 1}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1, transition: { duration: 250 } }}
            >
              <Button
                p={0}
                h={8}
                w={32}
                bg="primary"
                onPress={() => {
                  flashlistRef.current?.scrollToOffset({
                    offset: 0,
                    animated: true,
                  });
                  props.onRefresh();
                }}
              >
                <HStack space={1} alignItems="center" justifyContent="center">
                  <AntDesign name="arrowup" size={16} color="white" />
                  <Text color="white">New Posts</Text>
                </HStack>
              </Button>
            </PresenceTransition>
          </View>
        </View>
      </VStack>
    </Fragment>
  );
};
