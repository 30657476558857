export enum Routes {
  AUTH_STACK = "auth",
  AUTH_STACK__WELCOME = "welcome",
  AUTH_STACK__MAGIC_CODE = "verification",
  // Application Stack

  // profile setup screen
  APP_STACK__PROFILE_SETUP = "profile-setup",
  // onboarding survey screen
  APP_STACK__ONBOARDING_SURVEY = "onboarding",
  // exit survey screen
  APP_STACK__EXIT_SURVEY = "exit-survey",
  // loading screen
  APP_STACK__PENDING = "pending",
  APP_STACK__CREATE_POST = "post/create",
  APP_STACK__SINGLE_POST = "post",
  APP_STACK__IN_APP_BROWSER = "browser",
  APP_STACK__BOT_PROFILE = "feed-home-profile",

  // main tab bar
  APP_STACK__MAIN = "main",

  // 1. Feed Section
  APP_STACK__MAIN__FEED = "feed",
  APP_STACK__MAIN__FEED__HOME = "feed-home",

  // 2. Profile Section
  APP_STACK__MAIN__PROFILE = "profile",
  APP_STACK__MAIN__PROFILE__USER_PROFILE = "user-profile",

  // 3. Notifications Sections
  APP_STACK__MAIN__NOTIFICATIONS = "notifications",
  APP_STACK__MAIN__NOTIFICATIONS__USER_NOTIFICATIONS = "user-notifications",

  // 4. Settings Section
  APP_STACK__MAIN_SETTINGS = "settings",
}
