import React from "react";
import { WelcomeScreenProps } from "./welcome.screen";
import { StackScreenProps } from "@react-navigation/stack";
import { AuthStackParamList } from "../../navigation/navigators/auth-stack.navigator";
import { Routes } from "../../navigation/routes";

type ContainerProps = StackScreenProps<
  AuthStackParamList,
  Routes.AUTH_STACK__WELCOME
>;

export const WelcomeContainer = (Screen: React.FC<WelcomeScreenProps>) => {
  return function _(props: ContainerProps) {
    return (
      <Screen
        onEnterMagicCodePress={() => {
          props.navigation.navigate(Routes.AUTH_STACK__MAGIC_CODE, {});
        }}
      />
    );
  };
};
