import React from "react";
import { PendingScreenProps } from "./pending.screen";
import { useLogout } from "../core/utils/hooks/use-logout";

export const PendingContainer = (Screen: React.FC<PendingScreenProps>) => {
  return function _() {
    const { logout } = useLogout();
    return <Screen onLogoutButtonPress={logout} />;
  };
};
