import React, { Fragment, useEffect, useMemo, useState } from "react";
import {
  AspectRatio,
  Box,
  Button,
  Circle,
  Column,
  HStack,
  Image,
  Row,
  Text,
  useTheme,
  VStack,
} from "native-base";
import { ProfileAvatar } from "../core/components/profile-avatar";
import { AntDesign } from "@expo/vector-icons";
import {
  CharacteristicEntity,
  CommentReturnDto,
  PostReturnDto,
} from "../../services/api-service-sub-services/studies-api-service";
import { extractURLs } from "../core/utils/url.util";
import dayjs from "dayjs";
import WebView from "react-native-webview";
import getVideoId from "get-video-id";
import { LinkPreviewComponent } from "./link-preview.component";
import { PostCommentsComponent } from "./post-comments.component";
import { useTreatmentArmExperience } from "../core/hooks/use-treatment-arm-experience";
import { useProfileValues } from "../profile/hooks/use-profile-values";
import { Platform, TouchableOpacity } from "react-native";
import { useAppSelector } from "../../store/store";
import { selectUserProfile } from "../../store/user.slice";
import { useModal } from "react-native-modalfy";
import { ModalStackParams } from "../core/modals/modal-stack";
import { Interaction } from "./post.container";
import { ProfileActions } from "../profile/components/profile-actions";
import { ParticipantProfileWithPropertiesDto } from "../../services/api-service-sub-services/users-api-service";
import { extractMentions, MENTION_REGEX } from "./utils/mentions";

export type PostComponentProps = {
  post: PostReturnDto;
  comments: CommentReturnDto[];
  isCommentPosting: boolean;
  isCommentSectionVisibleByDefault?: boolean;
  defaultCommentContent?: string;
  highlightedCommentId?: string;
  currentInteraction: string;
  interaction: Interaction;
  onNewCommentTextChange?: (text: string) => void;
  onCommentButtonPress?: (isShown: boolean) => void;
  onShowCommentButtonPress: () => void;
  onInteractionButtonPress: (interaction: string) => void;
  onPostCommentButtonPress: (content: string) => Promise<void>;
  isInteractionsLoading: boolean;
  onProfilePress: (
    profile?: string | ParticipantProfileWithPropertiesDto
  ) => void;
  customInteractions?: CharacteristicEntity[];
};

export const PostComponent = (props: PostComponentProps) => {
  const theme = useTheme();
  const { openModal } = useModal<ModalStackParams>();
  const profileValues = useProfileValues(props.post.profile.properties);
  const treatmentArmExperience = useTreatmentArmExperience();

  /** This section contains the logic about the comments */
  const [isCommentSectionShown, setIsCommentSectionShown] = useState<boolean>(
    !!props.isCommentSectionVisibleByDefault
  );
  const [isCommentsOpenedOnce, setIsCommentsOpenedOnce] =
    useState<boolean>(false);
  const userProfile = useAppSelector(selectUserProfile);
  const [viewedComments, setViewedComments] = useState<CommentReturnDto[]>([]);

  useEffect(() => {
    if (isCommentSectionShown) {
      setIsCommentsOpenedOnce(true);
    }
  }, [isCommentSectionShown]);

  /** Returns a list of unseen comments */
  const unseenComments = useMemo<number>(() => {
    return (
      props.comments.filter(
        (comment) => comment.profile._id !== userProfile!._id
      ).length -
      viewedComments.filter(
        (comment) => comment.profile._id !== userProfile!._id
      ).length
    );
  }, [props.comments, viewedComments]);

  /** Returns the URL to be shown if any */
  const urls = useMemo(() => {
    return extractURLs(props.post.content);
  }, [props.post]);

  /** Returns the URL of the media to be shown if any */
  const media = useMemo<string | undefined>(() => {
    if (props.post.mediaUrls.length > 0) {
      return props.post.mediaUrls[0];
    }
    return undefined;
  }, [props.post]);

  /** Returns the media display type based on the order of video, url, image */
  const mediaDisplayType = useMemo<
    "embedded-video" | "url" | "image" | undefined
  >(() => {
    if (urls.length > 0) {
      const videoId = getVideoId(urls[0]);
      if (
        videoId.id &&
        (videoId.service === "youtube" || videoId.service === "vimeo")
      ) {
        return "embedded-video";
      }
      return "url";
    }
    if (media) {
      return "image";
    }
    return undefined;
  }, [urls, media]);

  /** Returns a correct embedding code for URLs that match YT and Vimeo links */
  const getEmbeddedVideoSource = (url: string) => {
    const video = getVideoId(url);
    if (video.id && video.service === "youtube") {
      return `https://www.youtube.com/embed/${video.id}`;
    }
    if (video.id && video.service === "vimeo") {
      return `https://player.vimeo.com/video/${video.id}`;
    }
    return "";
  };

  const primaryLabel = props.post.highlight;

  const postContentFragments = useMemo(() => {
    return extractMentions(
      props.post.content.replace(urls[0], "").trimStart().trimEnd()
    );
  }, [props.post.content]);

  return (
    <VStack bg="white" pb="3">
      {/** display the primary label here */}
      {primaryLabel && (
        <VStack space="1" py="2" mt="1">
          <Row
            alignItems="center"
            paddingLeft="5"
            justifyContent="flex-start"
            space="1"
            flexBasis="0"
          >
            {primaryLabel.imageUrl && (
              <Image
                src={primaryLabel.imageUrl}
                alt="image"
                height="5"
                minWidth="5"
                maxWidth="20"
                resizeMode="contain"
              />
            )}

            <Text
              variant="small"
              color={primaryLabel.color}
              bold
              letterSpacing="xl"
            >
              {!primaryLabel.isTitleHidden ? primaryLabel.title : ""}
            </Text>
          </Row>
        </VStack>
      )}

      <VStack
        {...(primaryLabel
          ? {
              borderWidth: "2.5",
              borderColor: primaryLabel.color,
            }
          : {})}
        mx="2.5"
        px="2.5"
        py="2.5"
        borderRadius="13"
      >
        <VStack space="3">
          <TouchableOpacity onPress={() => props.onProfilePress()}>
            <HStack>
              <ProfileAvatar profile={props.post.profile} />
              <Column ml="3">
                <Text variant="heading">{profileValues.displayName}</Text>
                <Text variant="small" color="darkGrey">
                  {dayjs(props.post.date).local().format("MMMM DD [at] h:mm A")}
                </Text>
                {/* this shows the second post label under the author's name */}
                {props.post.labels && props.post.labels.length > 1 && (
                  <VStack space="1" mt="1">
                    {props.post.labels.map((label) => {
                      return (
                        <Row
                          key={label._id}
                          alignItems="center"
                          justifyContent="flex-start"
                          space="1"
                          flexBasis="0"
                        >
                          {label.imageUrl && (
                            <Image
                              src={label.imageUrl}
                              alt="image"
                              height="4"
                              minWidth="4"
                              maxWidth="20"
                              resizeMode="contain"
                            />
                          )}
                          <Text
                            variant="small"
                            fontSize="12"
                            color={label.color}
                            bold
                            letterSpacing="xl"
                          >
                            {!label.isTitleHidden ? label.title : ""}
                          </Text>
                        </Row>
                      );
                    })}
                  </VStack>
                )}
              </Column>
              <Box position="absolute" top="2" right="0">
                <ProfileActions profileId={props.post.profile._id} />
              </Box>
            </HStack>
          </TouchableOpacity>

          <Text>
            {postContentFragments.map((fragment: string, index: number) => {
              if (MENTION_REGEX.test(fragment)) {
                // replaces the format @[username] to @username
                const mention = fragment.replace(/(\[)([^\]]+)(])/g, "$2");
                const mentionedProfile = props.post.taggedProfiles?.find(
                  (profile) => profile.matcher === fragment
                );
                if (mentionedProfile) {
                  return (
                    <Text
                      key={index}
                      color="secondary"
                      onPress={() => {
                        props.onProfilePress(mentionedProfile.profileId);
                      }}
                    >
                      {mention}
                    </Text>
                  );
                } else {
                  return <Text key={index}>{mention}</Text>;
                }
              }
              return <Text key={index}>{fragment}</Text>;
            })}
          </Text>
        </VStack>

        <VStack mt="3" space="3">
          {Platform.OS === "web" && mediaDisplayType === "embedded-video" && (
            <AspectRatio ratio={16 / 9}>
              <iframe src={getEmbeddedVideoSource(urls[0])} width="100%" />
            </AspectRatio>
          )}

          {mediaDisplayType === "embedded-video" && Platform.OS !== "web" && (
            <AspectRatio ratio={16 / 9}>
              <WebView
                source={{ uri: getEmbeddedVideoSource(urls[0]) }}
                useWebView2
              />
            </AspectRatio>
          )}
          {mediaDisplayType === "image" && (
            <TouchableOpacity
              onPress={() => {
                if (Platform.OS === "web") return;
                openModal("FullImagePreviewModal", {
                  imageURL: media!,
                });
              }}
            >
              <AspectRatio
                alignSelf="center"
                borderRadius="8"
                overflow="hidden"
                width="100%"
                mx="5"
                ratio={1}
              >
                <Image src={media} alt="post-image" resizeMode="cover" />
              </AspectRatio>
            </TouchableOpacity>
          )}
          {mediaDisplayType === "url" && <LinkPreviewComponent url={urls[0]} />}
        </VStack>
      </VStack>

      <VStack
        space="3"
        px="5"
        mt="3"
        borderTopColor="lightGrey"
        borderTopWidth="1"
        py="2"
      >
        <Row justifyContent="space-between">
          {treatmentArmExperience.interactions.isWithVariants && (
            <VStack flex="1">
              <Row flexWrap="wrap" space="2">
                {treatmentArmExperience.interactions.customInteractions.map(
                  (interaction) => {
                    return (
                      <HStack
                        key={interaction._id}
                        space="2"
                        alignItems="center"
                      >
                        <Text variant="small" fontSize="sm" fontWeight="500">
                          {interaction.name}
                        </Text>
                        <HStack alignItems="center" space="1">
                          <Text variant="small" fontSize="sm" bold>
                            {isNaN(props.interaction[interaction.name])
                              ? 0
                              : props.interaction[interaction.name]}
                          </Text>

                          <Image
                            height={4}
                            width={4}
                            src={interaction.icon}
                            alt="interaction"
                          />
                        </HStack>
                      </HStack>
                    );
                  }
                )}
              </Row>
              {(treatmentArmExperience.interactions.isLikesEnabled ||
                treatmentArmExperience.interactions.isDislikesEnabled) &&
                treatmentArmExperience.interactions.variants.map(
                  (variant, index) => {
                    const color = variant.value;
                    const initialLikes =
                      props.post.interactionVariants?.find((interaction) => {
                        return (
                          interaction.action === "like" &&
                          interaction.variant === variant.name
                        );
                      })?.count || 0;
                    const initialDislikes =
                      props.post.interactionVariants?.find((interaction) => {
                        return (
                          interaction.action === "dislike" &&
                          interaction.variant === variant.name
                        );
                      })?.count || 0;

                    let likes = initialLikes;
                    let dislikes = initialDislikes;
                    if (
                      treatmentArmExperience.interactions.variant ===
                      variant.name
                    ) {
                      likes += props.interaction.like;
                      dislikes += props.interaction.dislike;
                    }
                    return (
                      <HStack space="1.5" key={index} alignItems="center">
                        <Text variant="small" fontSize="sm" fontWeight="500">
                          {variant.name}
                        </Text>
                        <HStack space="2">
                          {/* likes */}
                          {treatmentArmExperience.interactions
                            .isLikesEnabled && (
                            <HStack alignItems="center" space="1">
                              <Text variant="small" fontSize="sm" bold>
                                {likes}
                              </Text>
                              <AntDesign name="like2" size={16} color={color} />
                            </HStack>
                          )}
                          {/* dislikes */}
                          {treatmentArmExperience.interactions
                            .isDislikesEnabled && (
                            <HStack alignItems="center" space="1">
                              <Text variant="small" fontSize="sm" bold>
                                {dislikes}
                              </Text>
                              <AntDesign
                                name="dislike2"
                                size={16}
                                color={color}
                              />
                            </HStack>
                          )}
                        </HStack>
                      </HStack>
                    );
                  }
                )}
            </VStack>
          )}
          <Row flexWrap="wrap" space="2">
            {!treatmentArmExperience.interactions.isWithVariants && (
              <Fragment>
                {treatmentArmExperience.interactions.customInteractions.map(
                  (interaction) => {
                    return (
                      <HStack
                        alignItems="center"
                        space="1"
                        key={interaction._id}
                      >
                        <Text variant="small">
                          {props.interaction[interaction.name]}
                        </Text>
                        <Image height={4} width={4} src={interaction.icon} />
                      </HStack>
                    );
                  }
                )}
                {/* likes */}
                {treatmentArmExperience.interactions.isLikesEnabled && (
                  <HStack alignItems="center" space="1">
                    <Text variant="small" fontSize="14" color="#8F9198">
                      {props.interaction.like}
                    </Text>
                    <AntDesign name="like2" size={14} color="#8F9198" />
                  </HStack>
                )}
                {/* dislikes */}
                {treatmentArmExperience.interactions.isDislikesEnabled && (
                  <HStack alignItems="center" space="1">
                    <Text variant="small" fontSize="14" color="#8F9198">
                      {props.interaction.dislike}
                    </Text>
                    <AntDesign name="dislike2" size={14} color="#8F9198" />
                  </HStack>
                )}
              </Fragment>
            )}
          </Row>

          <Row alignItems="flex-start" space="0">
            {/** custom interactions */}
            {treatmentArmExperience.interactions.customInteractions.map(
              (interaction) => {
                return (
                  <Button
                    key={interaction._id}
                    variant="unstyled"
                    alignItems="center"
                    justifyContent="center"
                    display="flex"
                    padding="1"
                    marginLeft="2"
                    bgColor={
                      props.currentInteraction === interaction.name
                        ? "primary"
                        : undefined
                    }
                    onPress={() =>
                      props.onInteractionButtonPress(interaction.name)
                    }
                    disabled={props.isInteractionsLoading}
                  >
                    <Image
                      src={interaction.icon}
                      height={22}
                      width={22}
                      alt="interaction"
                    />
                  </Button>
                );
              }
            )}

            {/** like */}
            {treatmentArmExperience.interactions.isLikesEnabled && (
              <Button
                variant="unstyled"
                alignItems="center"
                justifyContent="center"
                display="flex"
                padding="1"
                marginLeft="2"
                bg={props.currentInteraction === "like" ? "primary" : undefined}
                onPress={() => props.onInteractionButtonPress("like")}
                disabled={props.isInteractionsLoading}
              >
                <AntDesign
                  name="like2"
                  size={22}
                  color={
                    props.currentInteraction === "like" ? "white" : "#444A5F"
                  }
                />
              </Button>
            )}
            {/** dislike */}
            {treatmentArmExperience.interactions.isDislikesEnabled && (
              <Button
                disabled={props.isInteractionsLoading}
                variant="unstyled"
                alignItems="center"
                justifyContent="center"
                display="flex"
                padding="1"
                marginLeft="2"
                bgColor={
                  props.currentInteraction === "dislike" ? "primary" : undefined
                }
                onPress={() => props.onInteractionButtonPress("dislike")}
              >
                <AntDesign
                  name="dislike2"
                  size={22}
                  color={
                    props.currentInteraction === "dislike" ? "white" : "#444A5F"
                  }
                />
              </Button>
            )}
            {/** comments */}
            {treatmentArmExperience.interactions.isCommentsEnabled && (
              <HStack alignItems="center" space="1">
                <Button
                  variant="unstyled"
                  alignItems="center"
                  justifyContent="center"
                  display="flex"
                  padding="1"
                  marginLeft="2"
                  onPress={() => {
                    const _isCommentSectionsShown = !isCommentSectionShown;
                    setIsCommentSectionShown(_isCommentSectionsShown);
                    props.onCommentButtonPress?.(_isCommentSectionsShown);
                    if (_isCommentSectionsShown) {
                      props.onShowCommentButtonPress();
                    }
                    setViewedComments(props.comments);
                  }}
                >
                  {isCommentsOpenedOnce && unseenComments > 0 && (
                    <Box position="absolute" top="-4" left="-4" zIndex="1">
                      <Circle height="4" width="4" bgColor="primary">
                        <Text color="white" fontSize="10px" bold>
                          {unseenComments}
                        </Text>
                      </Circle>
                    </Box>
                  )}

                  <AntDesign
                    name="message1"
                    size={22}
                    color={
                      isCommentSectionShown
                        ? (theme.colors.primary as unknown as string)
                        : "#444A5F"
                    }
                  />
                </Button>
                <Text>{props.comments.length}</Text>
              </HStack>
            )}
          </Row>
        </Row>
      </VStack>

      {/** comment section is rendered here */}
      <PostCommentsComponent
        postId={props.post._id}
        defaultCommentContent={props.defaultCommentContent}
        highlightedCommentId={props.highlightedCommentId}
        comments={props.comments}
        onPostCommentButtonPress={props.onPostCommentButtonPress}
        onNewCommentTextChange={props.onNewCommentTextChange}
        isBusy={props.isCommentPosting}
        isVisible={isCommentSectionShown}
      />
    </VStack>
  );
};
