import * as React from "react";
import Svg, { Path, SvgProps } from "react-native-svg";

const SvgComponent = (props: SvgProps) => (
  <Svg width={24} height={24} fill="none" {...props}>
    <Path
      fill="#444A5F"
      d="M8.376.215A7.543 7.543 0 0 0 .84 7.75c0 3.98 6.33 9.444 7.048 10.054l.487.411.486-.411c.72-.61 7.049-6.074 7.049-10.054A7.543 7.543 0 0 0 8.376.215Zm0 11.303a3.768 3.768 0 1 1 0-7.536 3.768 3.768 0 0 1 0 7.536Z"
    />
    <Path
      fill="#444A5F"
      d="M8.376 10.01a2.26 2.26 0 1 0 0-4.52 2.26 2.26 0 0 0 0 4.52Z"
    />
  </Svg>
);
export { SvgComponent as LocationIcon };
