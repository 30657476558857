import { ConfiguredPopupDto } from "../../../services/api-service-sub-services/studies-api-service";

/**
 * Returns the modal type for the popup windows
 * @param popup
 */
export const getModalType = (popup: ConfiguredPopupDto) => {
  if (!!popup.popup.content) {
    return "html";
  } else if (!!popup.popup.survey) {
    return "qualtrics";
  }
  return "html";
};

export const getModalInstructions = (popup: ConfiguredPopupDto): string => {
  const modalType = getModalType(popup);
  if (modalType === "html") {
    return popup.popup.content!;
  } else if (modalType === "qualtrics") {
    return popup.popup.survey?.id!;
  }
  return "";
};
