import { api } from "../api-service";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    authControllerAuthenticateWithCode: build.mutation<
      AuthControllerAuthenticateWithCodeApiResponse,
      AuthControllerAuthenticateWithCodeRequest
    >({
      query: (queryArg) => ({
        url: `/auth/v2`,
        method: "POST",
        body: queryArg.authenticateWithCodeDto,
      }),
    }),
    authControllerSignInLocal: build.mutation<
      AuthControllerSignInLocalApiResponse,
      AuthControllerSignInLocalRequest
    >({
      query: (queryArg) => ({
        url: `/auth`,
        method: "POST",
        body: queryArg.signInDto,
      }),
    }),
    authControllerRefreshAuth: build.mutation<
      AuthControllerRefreshAuthApiResponse,
      AuthControllerRefreshAuthRequest
    >({
      query: (queryArg) => ({
        url: `/auth/refresh`,
        method: "POST",
        body: queryArg.refreshAuthDto,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type AuthControllerAuthenticateWithCodeApiResponse =
  /** status 200  */ SigninSuccessDto;
export type AuthControllerAuthenticateWithCodeRequest = {
  authenticateWithCodeDto: AuthenticateWithCodeDto;
};
export type AuthControllerSignInLocalApiResponse =
  /** status 200 Successfully signed in */ SigninSuccessDto;
export type AuthControllerSignInLocalRequest = {
  signInDto: SignInDto;
};
export type AuthControllerRefreshAuthApiResponse =
  /** status 200 Valid refresh token */ SigninSuccessDto;
export type AuthControllerRefreshAuthRequest = {
  refreshAuthDto: RefreshAuthDto;
};
export type SanitizedUserDto = {
  _id: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  roles: ("ADMIN" | "RESEARCHER" | "PARTICIPANT")[];
  profilePicture?: string;
  currentStudy?: string;
  currentTreatmentArm?: string;
  university?: string;
  deviceIds?: string[];
  createdAt: string;
  updatedAt: string;
};
export type SigninSuccessDto = {
  accessToken: string;
  refreshToken: string;
  user: SanitizedUserDto;
};
export type AuthenticateWithCodeDto = {
  code: string;
  deviceId?: string;
};
export type SignInDto = {
  email: string;
  password: string;
};
export type RefreshAuthDto = {
  refreshToken: string;
};
export const {
  useAuthControllerAuthenticateWithCodeMutation,
  useAuthControllerSignInLocalMutation,
  useAuthControllerRefreshAuthMutation,
} = injectedRtkApi;
