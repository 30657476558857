import { SimulatedProfile } from "../../../types/data-models";
import { TaggedProfilesEntity } from "../../../services/api-service-sub-services/studies-api-service";

/** Matches @[username] from any string */
export const MENTION_REGEX = /(@\[[^\]]+])/g;

/** Matches {@}[username](id) from any string */
export const RN_CONTROLLED_MENTIONS_REGEX = /{@}\[([^\]]+)\]\(([^)]+)\)/g;

/**
 * Extracts the mentions from a post content
 * @param {string} postContent
 */
export const extractMentions = (postContent: string): string[] => {
  // splitting the text into an array while preserving mention
  // the filter(Boolean) part removes any empty strings from the resulting array
  return postContent.split(MENTION_REGEX).filter(Boolean);
};

/**
 * Extracts the controlled mentions from a post content
 * @param {string} content - the content of the post or comment
 * @param {SimulatedProfile[]} simulatedProfiles - the simulated profiles
 *
 */
export const extractControlledMentions = (
  content: string,
  simulatedProfiles: SimulatedProfile[]
): {
  contentWithOrWithoutMentions: string;
  taggedProfiles: TaggedProfilesEntity[];
} => {
  // replaces all RN controlled mentions to regular mentions
  // ({@}[username](id) -> @[username]) to be supported
  // by the API and the web application
  const replacedContent = content.replace(
    RN_CONTROLLED_MENTIONS_REGEX,
    "@[$1]"
  );

  // matches all mentions in the content, specified by the
  // original format which is @[username]
  const matchersInContent = replacedContent.match(MENTION_REGEX) || [];

  // find the profile id for each mention, and add it to the taggedProfiles array
  // the array is used as a reference to the mentioned profiles from the content
  // to create dynamic links to open a profile's page
  const taggedProfiles: TaggedProfilesEntity[] = [];
  matchersInContent.forEach((matcher) => {
    if (
      simulatedProfiles.some((profile) => `@[${profile.username}]` === matcher)
    ) {
      const found = simulatedProfiles.find(
        (profile) => `@[${profile.username}]` === matcher
      );
      if (found) {
        taggedProfiles.push({
          matcher: `${matcher}`,
          profileId: found._id,
        });
      }
    }
  });

  return {
    taggedProfiles: taggedProfiles,
    contentWithOrWithoutMentions: replacedContent,
  };
};
