import React, { useCallback, useEffect } from "react";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import { Box, useToken } from "native-base";
import { NavHomeIcon } from "../../modules/core/icons/nav-home-icon";
import { NavProfileIcon } from "../../modules/core/icons/nav-profile-icon";
import { NavNotificationsIcon } from "../../modules/core/icons/nav-notifications-icon";
import { NavSettingsIcon } from "../../modules/core/icons/nav-settings-icon";
import { HomeStackNavigator } from "./home-stack.navigator";
import { ProfileStackNavigator } from "./profile-stack-navigator";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { Platform } from "react-native";
import { NotificationStackNavigator } from "./notification-stack.navigator";
import { useGlobalNotificationListener } from "../../modules/core/utils/hooks/use-global-notification.listener";
import {
  selectBadgeCount,
  selectIsNotificationsEnabled,
} from "../../store/notification.slice";
import { NotificationBadge } from "../../modules/notifications/notification-badge.component";
import { SettingsScreen } from "../../modules/settings/settings.screen";
import { StackScreenProps } from "@react-navigation/stack";
import { AppStackParamList } from "./app-stack.navigator";
import { Routes } from "../routes";
import {
  selectUserCreatedDate,
  selectUserProfile,
  userSlice,
} from "../../store/user.slice";
import dayjs from "dayjs";
import {
  selectStudy,
  selectTreatmentArmEvergreenTime,
  selectTreatmentArmExperience,
} from "../../store/app.slice";
import { useTimer } from "react-use-precision-timer";
import { useModal } from "react-native-modalfy";
import { ModalStackParams } from "../../modules/core/modals/modal-stack";
import {
  ConfiguredPopupDto,
  useStudiesControllerUpdateProfileMutation,
} from "../../services/api-service-sub-services/studies-api-service";
import { SettingsContainer } from "../../modules/settings/settings.container";
import {
  getModalInstructions,
  getModalType,
} from "../../modules/core/utils/get-modal-type";

const Tab = createBottomTabNavigator();
const Settings = SettingsContainer(SettingsScreen);

type AppTabNavigatorProps = StackScreenProps<
  AppStackParamList,
  Routes.APP_STACK__MAIN
>;
export const AppTabNavigator = (props: AppTabNavigatorProps) => {
  const { openModal } = useModal<ModalStackParams>();
  const [updateUserProfile] = useStudiesControllerUpdateProfileMutation();

  const notificationBadgeCount = useAppSelector(selectBadgeCount);
  const isNotificationsEnabled = useAppSelector(selectIsNotificationsEnabled);
  const profile = useAppSelector(selectUserProfile);
  const userCreatedDate = useAppSelector(selectUserCreatedDate);
  const experience = useAppSelector(selectTreatmentArmExperience);
  const study = useAppSelector(selectStudy);
  const evergreenTime = useAppSelector(selectTreatmentArmEvergreenTime);
  const dispatch = useAppDispatch();

  const callbackFunc = useCallback(() => {
    const now = dayjs();
    const relativeTimeBase = dayjs(
      profile?.finishedOnboardingSurveyAt || userCreatedDate
    );

    experience.timeBasedPopups.forEach((popup: ConfiguredPopupDto) => {
      const timeToAdd = popup.meta!.time!;
      const [hh, mm] = timeToAdd.split(":");
      const popupTime = relativeTimeBase
        .add(parseInt(hh), "hour")
        .add(parseInt(mm), "minutes");
      if (
        !now.isAfter(popupTime) &&
        !now.add(1, "second").isBefore(popupTime)
      ) {
        openModal("InstructionsModal", {
          type: getModalType(popup),
          instructions: getModalInstructions(popup),
        });
      }
    });
  }, [profile, experience.timeBasedPopups]);

  useTimer({ delay: 1000, startImmediately: true }, callbackFunc);

  useGlobalNotificationListener();

  // update when the user has entered the newsfeed
  useEffect(() => {
    const updateProfileEnteredNewsfeedValue = async () => {
      const updatedProfile = await updateUserProfile({
        id: study!._id,
        profileId: profile?._id!,
        updateProfileDto: {
          enteredNewsfeedAt: dayjs().toISOString(),
        },
      }).unwrap();

      dispatch(
        userSlice.actions.userProfileExitScheduleUpdated(updatedProfile)
      );
    };

    if (study && profile && !profile.enteredNewsfeedAt) {
      // update when the user entered the newsfeed
      updateProfileEnteredNewsfeedValue();
    }
  }, [profile, dispatch]);

  // redirect the user to the exit survey if there schedule has completed
  useEffect(() => {
    // exit survey schedule determines that the user has an exit survey to complete
    if (evergreenTime && profile?.enteredNewsfeedAt) {
      const interval = setInterval(() => {
        const currentTime = dayjs();
        const target = dayjs(profile.enteredNewsfeedAt).add(
          evergreenTime.amount,
          evergreenTime.unit
        );

        if (currentTime.isAfter(target)) {
          // Do something when the time has passed the specific target time
          // For example, show an alert or update the component's state
          // You can replace this with your desired action.
          props.navigation.replace(Routes.APP_STACK__EXIT_SURVEY);

          // Clear the interval if the condition is met
          clearInterval(interval);
        }
      }, 1000); // Check every second

      // Clean up the interval when the component unmounts
      return () => {
        clearInterval(interval);
      };
    }
  }, [profile, evergreenTime]);

  // tab icons fill
  const [focusedColor, unFocusedColor] = useToken("colors", [
    "primary",
    "darkGrey",
  ]);

  return (
    <Tab.Navigator
      backBehavior="history"
      detachInactiveScreens={Platform.OS === "web"}
      screenOptions={{
        tabBarHideOnKeyboard: true,
        tabBarItemStyle: {
          marginTop: Platform.OS === "ios" ? 20 : 0,
          paddingBottom: 10,
        },
        tabBarStyle: {
          minHeight: 70,
        },
        tabBarShowLabel: false,
        headerShown: false,
      }}
    >
      <Tab.Screen
        name={Routes.APP_STACK__MAIN__FEED}
        component={HomeStackNavigator}
        options={{
          tabBarIcon: ({ focused }) => (
            <NavHomeIcon color={focused ? focusedColor : unFocusedColor} />
          ),
        }}
      />

      <Tab.Screen
        name={Routes.APP_STACK__MAIN__PROFILE}
        component={ProfileStackNavigator}
        options={{
          tabBarIcon: ({ focused }) => (
            <NavProfileIcon color={focused ? focusedColor : unFocusedColor} />
          ),
        }}
      />

      {isNotificationsEnabled && (
        <Tab.Screen
          name={Routes.APP_STACK__MAIN__NOTIFICATIONS}
          component={NotificationStackNavigator}
          options={{
            tabBarIcon: ({ focused }) => (
              <Box position="relative">
                {notificationBadgeCount > 0 && (
                  <NotificationBadge count={notificationBadgeCount} />
                )}
                <NavNotificationsIcon
                  color={focused ? focusedColor : unFocusedColor}
                />
              </Box>
            ),
          }}
        />
      )}

      <Tab.Screen
        name={Routes.APP_STACK__MAIN_SETTINGS}
        component={Settings}
        options={{
          tabBarIcon: ({ focused }) => (
            <NavSettingsIcon color={focused ? focusedColor : unFocusedColor} />
          ),
        }}
      />
    </Tab.Navigator>
  );
};
