import * as React from "react";
import Svg, { Path, SvgProps } from "react-native-svg";

const SvgComponent = (props: SvgProps) => (
  <Svg width={24} height={24} fill="none" {...props}>
    <Path
      fill={props.color || "#83899B"}
      d="M10.616 2.708A.808.808 0 0 1 9.81 1.9v-.808a.808.808 0 0 1 1.615 0V1.9a.808.808 0 0 1-.808.808Z"
    />
    <Path
      fill={props.color || "#83899B"}
      d="M17.666 9.757a.808.808 0 0 1-.807-.808c0-3.282-2.666-6.08-5.942-6.234-3.28-.152-6.204 2.374-6.513 5.638a6.315 6.315 0 0 0-.028.596.808.808 0 0 1-1.615 0c0-.252.011-.504.035-.749.389-4.11 4.07-7.293 8.197-7.099 4.125.195 7.481 3.716 7.481 7.848a.808.808 0 0 1-.808.808ZM19.18 15.266a.808.808 0 0 1-.556-.221 5.678 5.678 0 0 1-1.767-4.106.808.808 0 0 1 1.616 0c0 1.105.46 2.174 1.262 2.933a.808.808 0 0 1-.555 1.394ZM2.054 15.266a.808.808 0 0 1-.555-1.394 4.054 4.054 0 0 0 1.262-2.933.808.808 0 0 1 1.615 0 5.675 5.675 0 0 1-1.767 4.106.808.808 0 0 1-.555.22ZM10.617 21.285c-2.19 0-4.039-1.85-4.039-4.039a.808.808 0 0 1 1.616 0c0 1.314 1.11 2.423 2.423 2.423s2.423-1.11 2.423-2.423a.808.808 0 0 1 1.615 0c0 2.189-1.85 4.039-4.038 4.039Z"
    />
    <Path
      fill={props.color || "#83899B"}
      d="M3.163 18.054a2.4 2.4 0 0 1-2.23-1.533 2.401 2.401 0 0 1 .565-2.65.808.808 0 1 1 1.11 1.174c-.353.335-.223.757-.175.878.048.12.243.516.73.516a.808.808 0 0 1 0 1.615ZM18.07 18.054a.808.808 0 0 1 0-1.615c.487 0 .682-.397.73-.519.049-.12.178-.54-.175-.876a.808.808 0 0 1 1.11-1.173 2.4 2.4 0 0 1 .567 2.647 2.402 2.402 0 0 1-2.233 1.536ZM3.567 11.746a.808.808 0 0 1-.807-.807v-1.99a.808.808 0 0 1 1.615 0v1.99a.808.808 0 0 1-.808.807ZM17.666 11.746a.808.808 0 0 1-.808-.807v-1.99a.808.808 0 0 1 1.616 0v1.99a.808.808 0 0 1-.808.807Z"
    />
    <Path
      fill={props.color || "#83899B"}
      d="M18.07 18.054H3.164a.808.808 0 0 1 0-1.615H18.07a.808.808 0 0 1 0 1.615Z"
    />
  </Svg>
);
export { SvgComponent as NavNotificationsIcon };
