import { Button, Flex, Image, Text, View, VStack } from "native-base";
import { StatusBar } from "expo-status-bar";
import React from "react";
import { SplashIcon } from "../../assets/images";

export type PendingScreenProps = {
  onLogoutButtonPress: () => void;
};

export const PendingScreen = (props: PendingScreenProps) => {
  return (
    <View
      variant="screen"
      bgColor="primary"
      justifyContent="center"
      alignItems="center"
    >
      <Flex flex="1" />
      <VStack space="3" alignItems="center">
        <Image
          source={SplashIcon}
          height="48"
          width="48"
          alt="logo"
          resizeMode="contain"
        />
        <Text color="white" letterSpacing="1" textAlign="center">
          Thank you for your participation. {"\n"}Your time has now ended.
        </Text>
      </VStack>
      <Flex flex="1" />
      <Button onPress={props.onLogoutButtonPress} variant="secondary">
        Logout
      </Button>
      <StatusBar style="light" />
    </View>
  );
};
