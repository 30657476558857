import { ContentVariantEntity } from "../../../services/api-service-sub-services/studies-api-service";

interface PostsTagsFilter {
  includedTagsQuery?: string;
  excludedTagsQuery?: string;
}

/**
 * The function `getPostsTagsFilter` takes an array of `ContentVariantEntity` objects and returns an
 * object with two properties: `includedTagsQuery` and `excludedTagsQuery`, which are strings
 * representing the tags to include and exclude in a query respectively.
 * @param {ContentVariantEntity[]} [contentVariants] - An optional array of ContentVariantEntity
 * objects.
 * @return {PostsTagsFilter}.
 */
export const getPostsTagsFilter = (
  contentVariants?: ContentVariantEntity[]
): PostsTagsFilter => {
  let includedTagsQuery;
  let excludedTagsQuery;

  if (!contentVariants || !contentVariants.length)
    return { includedTagsQuery, excludedTagsQuery };

  for (const contentVariant of contentVariants) {
    if (
      contentVariant.name === "show-hide-content" &&
      contentVariant.tags?.length
    ) {
      if (contentVariant.value === "shown") {
        includedTagsQuery = contentVariant.tags.join(",");
      }
      if (contentVariant.value === "hidden") {
        excludedTagsQuery = contentVariant.tags.join(",");
      }
    }
  }

  return { includedTagsQuery, excludedTagsQuery };
};
