import * as React from "react";
import Svg, { Path, SvgProps } from "react-native-svg";

const SvgComponent = (props: SvgProps) => (
  <Svg width={24} height={24} fill="none" {...props}>
    <Path
      fill={props.color || "#83899B"}
      stroke="#83899B"
      strokeWidth={0.2}
      d="M9.581.185a5.643 5.643 0 0 0-5.637 5.637 5.643 5.643 0 0 0 5.637 5.637 5.643 5.643 0 0 0 5.637-5.637A5.643 5.643 0 0 0 9.581.185Zm0 9.843a4.212 4.212 0 0 1-4.207-4.206 4.212 4.212 0 0 1 4.207-4.207 4.212 4.212 0 0 1 4.207 4.207 4.212 4.212 0 0 1-4.207 4.206ZM16.541 14.906c-1.534-1.558-3.57-2.417-5.73-2.417h-2.46c-2.16 0-4.196.859-5.73 2.417A8.17 8.17 0 0 0 .253 20.67c0 .395.32.716.715.716h17.226c.395 0 .716-.32.716-.716a8.17 8.17 0 0 0-2.369-5.763Zm.9 5.048H1.722c.353-3.388 3.193-6.034 6.63-6.034h2.46c3.437 0 6.277 2.646 6.63 6.034Z"
    />
  </Svg>
);
export { SvgComponent as NavProfileIcon };
