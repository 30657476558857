import {
  Box,
  Heading,
  HStack,
  Image,
  ScrollView,
  Text,
  VStack,
} from "native-base";
import React, { useMemo } from "react";
import { ModalComponentProp } from "react-native-modalfy";
import { ModalStackParams } from "../../core/modals/modal-stack";
import { Dimensions, TouchableOpacity } from "react-native";

type Props = ModalComponentProp<ModalStackParams, void, "AvatarSelectorModal">;
export const AvatarSelectorModal = (props: Props) => {
  const { width, height } = Dimensions.get("window");

  const pairs = useMemo(() => {
    const pairs = [];
    if (props.modal.params?.avatars) {
      const items = props.modal.params!.avatars!;
      for (let i = 0; i < items.length; i += 2) {
        pairs.push([items[i], items[i + 1]]);
      }
    }
    return pairs;
  }, [props.modal.params]);

  return (
    <VStack
      variant="screen"
      borderRadius="8"
      px="5"
      py="6"
      space="5"
      width={width * 0.8}
      height={height * 0.8}
    >
      <VStack>
        <Heading>Choose an Avatar</Heading>
        <Text variant="small" fontSize="md">
          This will be visible across your Spark Social experience.
        </Text>
      </VStack>
      <ScrollView px="1">
        {pairs.map((avatarPair, index) => {
          return (
            <HStack flex="1" justifyContent="space-between" key={index}>
              {avatarPair.map((avatar, index) => {
                if (!avatar) return null;
                return (
                  <TouchableOpacity
                    key={avatar}
                    onPress={() => {
                      props.modal.params?.onSelect(avatar);
                      props.modal.closeModal();
                    }}
                  >
                    <Box
                      m="3"
                      height="24"
                      width="24"
                      bgColor="xLightGrey"
                      borderRadius="100"
                      alignItems="center"
                      justifyContent="flex-end"
                      shadow="1"
                      overflow="hidden"
                    >
                      <Image
                        backgroundColor="red.50"
                        source={{ uri: avatar }}
                        width="100%"
                        alt="Avatar"
                        height="100%"
                        resizeMethod="scale"
                        resizeMode="cover"
                      />
                    </Box>
                  </TouchableOpacity>
                );
              })}
            </HStack>
          );
        })}
      </ScrollView>
    </VStack>
  );
};
