import { Platform, TouchableOpacity } from "react-native";
import { AspectRatio, HStack, Text, View } from "native-base";
import { ProfileAvatar } from "../../core/components/profile-avatar";
import React from "react";
import { ParticipantProfileWithPropertiesDto } from "../../../services/api-service-sub-services/users-api-service";
import { PlaneIcon } from "../../core/icons/plane-icon";

type NewPostPromptSmallProps = {
  onPress: () => Promise<void> | void;
  profile?: ParticipantProfileWithPropertiesDto;
};

export const NewPostPromptSmall = (props: NewPostPromptSmallProps) => {
  return (
    <View
      borderTopColor="xLightGrey"
      borderTopWidth="0.5px"
      bg="white"
      px="5"
      py="3"
      shadow={Platform.OS !== "ios" ? 9 : 3}
    >
      <TouchableOpacity
        onPress={props.onPress}
        style={{
          display: "flex",
          flexDirection: "row",
          backgroundColor: "white",
        }}
      >
        <AspectRatio ratio={1} height="9">
          <ProfileAvatar profile={props.profile} length="100%" small={true} />
        </AspectRatio>
        <HStack ml="3" pt="1" flex="1" justifyContent="space-between">
          <Text color="darkGrey">Share your thoughts here...</Text>
          <PlaneIcon />
        </HStack>
      </TouchableOpacity>
    </View>
  );
};
