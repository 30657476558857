import * as React from "react";
import Svg, { Path, SvgProps } from "react-native-svg";

const SvgComponent = (props: SvgProps) => (
  <Svg width={19} height={19} fill="none" {...props}>
    <Path
      fill="#fff"
      d="M14.805 3.45h-.381l-1.638-2.125A2.268 2.268 0 0 0 11.004.45H8.106a2.267 2.267 0 0 0-1.782.876L4.686 3.45h-.381A3.755 3.755 0 0 0 .555 7.2v7.5a3.755 3.755 0 0 0 3.75 3.75h10.5a3.754 3.754 0 0 0 3.75-3.75V7.2a3.755 3.755 0 0 0-3.75-3.75ZM7.512 2.241a.755.755 0 0 1 .594-.293h2.898a.756.756 0 0 1 .594.293l.931 1.207H6.58l.932-1.207Zm9.543 12.457a2.25 2.25 0 0 1-2.25 2.25h-10.5a2.25 2.25 0 0 1-2.25-2.25V7.2a2.25 2.25 0 0 1 2.25-2.25h10.5a2.25 2.25 0 0 1 2.25 2.25v7.5Z"
    />
    <Path
      fill="#fff"
      d="M9.555 6.45a4.5 4.5 0 1 0 0 9 4.5 4.5 0 0 0 0-9Zm0 7.5a3 3 0 1 1 0-6 3 3 0 0 1 0 6Z"
    />
  </Svg>
);
export default SvgComponent;
