import { useToast } from "native-base";
import { useTrackEvent } from "./use-track-event";
import { events } from "../../../constants/analytics.constants";

export type UseToastErrorMessageProps = { message: string };
export const useToastErrorMessage = () => {
  const toast = useToast();
  const track = useTrackEvent();
  return ({ message }: UseToastErrorMessageProps) => {
    track(events.userShownAnErrorMessage, { message: message });
    toast.show({
      avoidKeyboard: true,
      bgColor: "red.600",
      placement: "bottom",
      description: message,
    });
  };
};
