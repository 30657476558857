import * as React from "react";
import Svg, { Path, SvgProps } from "react-native-svg";
import { useToken } from "native-base";

const SvgComponent = (props: SvgProps) => {
  const [fillColor] = useToken("colors", ["medGrey"]);
  return (
    <Svg width={24} height={24} fill={fillColor} {...props}>
      <Path d="M12 14a2 2 0 1 0 0-4 2 2 0 0 0 0 4ZM19 14a2 2 0 1 0 0-4 2 2 0 0 0 0 4ZM5 14a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z" />
    </Svg>
  );
};
export { SvgComponent as IconThreeDot };
