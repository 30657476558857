import * as React from "react";
import Svg, { Path, SvgProps } from "react-native-svg";
import { useTheme } from "native-base";
import { ColorValue } from "react-native";

const SvgComponent = (props: SvgProps) => {
  const theme = useTheme();
  return (
    <Svg width={200} height={150} fill="none" viewBox="0 0 800 600" {...props}>
      <Path
        fill={theme.colors.primary as unknown as ColorValue}
        fillRule="evenodd"
        d="M83.57 47C42.938 47 10 79.938 10 120.57v284.154c0 40.632 32.938 73.57 73.57 73.57H339.57l46.766 65.985c9.117 12.862 28.391 12.295 36.734-1.083l40.48-64.902H716.43c40.632 0 73.57-32.938 73.57-73.57V120.57C790 79.938 757.062 47 716.43 47H83.57Z"
        clipRule="evenodd"
      />
      <Path
        fill={theme.colors.secondary as unknown as ColorValue}
        fillRule="evenodd"
        d="M484.694 286.998c12.739-11.546 20.836-28.855 20.836-48.202 0-34.762-26.137-62.942-58.378-62.942-19.367 0-36.532 10.169-47.152 25.824-10.619-15.655-27.784-25.824-47.151-25.824-32.242 0-58.378 28.18-58.378 62.942 0 23.956 12.413 44.786 30.683 55.421l77.092 65.619 83.076-72.624-.628-.214Z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
export { SvgComponent as EmptyTrayIcon };
