import React from "react";
import { createStackNavigator } from "@react-navigation/stack";
import { Routes } from "../routes";
import { NotificationScreen } from "../../modules/notifications/notification.screen";
import { NotificationContainer } from "../../modules/notifications/notification.container";
import { AppStackParamList } from "./app-stack.navigator";

export type NotificationStackParamList = {
  [Routes.APP_STACK__MAIN__NOTIFICATIONS__USER_NOTIFICATIONS]: {};
} & AppStackParamList;

const Notification = NotificationContainer(NotificationScreen);

const Stack = createStackNavigator<NotificationStackParamList>();

export const NotificationStackNavigator = () => {
  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: false,
      }}
    >
      <Stack.Screen
        name={Routes.APP_STACK__MAIN__NOTIFICATIONS__USER_NOTIFICATIONS}
        component={Notification}
      />
    </Stack.Navigator>
  );
};
