import { ParticipantProfileValue } from "../../../services/api-service-sub-services/studies-api-service";

export const extractProfileProperty = (
  name:
    | "first-name"
    | "last-name"
    | "bio/description"
    | "location"
    | "political-affiliation"
    | "age"
    | "gender"
    | "race"
    | "household-income"
    | "profile-picture",
  properties?: ParticipantProfileValue[]
): string | undefined => {
  if (!properties) {
    return undefined;
  }
  const property = properties.find((item) => item.name === name);
  if (property) {
    return property.value as string;
  }
  return undefined;
};

export const extractNameInitials = (
  properties?: ParticipantProfileValue[]
): string => {
  if (!properties) {
    return "AU";
  }

  const firstName = extractProfileProperty("first-name", properties);

  const lastName = extractProfileProperty("last-name", properties);

  const prefixes = [];
  // get the first name
  if (firstName) {
    prefixes.push(firstName.charAt(0));
  }
  // get the last name
  if (lastName) {
    prefixes.push(lastName.charAt(0));
  }
  // if there is at least one property
  if (prefixes.length > 0) {
    return prefixes.join("").toUpperCase();
  }
  // otherwise, return the "anonymous user" name
  return "AU";
};

export const extractFullName = (properties?: ParticipantProfileValue[]) => {
  if (!properties) {
    return "Anonymous User";
  }
  const firstName = extractProfileProperty("first-name", properties);
  const lastName = extractProfileProperty("last-name", properties);
  if (firstName || lastName) {
    return [firstName, lastName].filter((val) => !!val).join(" ");
  }
  return "Anonymous User";
};
