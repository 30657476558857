import { api } from "../api-service";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    participantProfileControllerFindOne: build.query<
      ParticipantProfileControllerFindOneApiResponse,
      ParticipantProfileControllerFindOneRequest
    >({
      query: (queryArg) => ({
        url: `/profiles/${queryArg.profileId}`,
        params: { myProfile: queryArg.myProfile },
      }),
    }),
    participantProfileControllerFollow: build.mutation<
      ParticipantProfileControllerFollowApiResponse,
      ParticipantProfileControllerFollowRequest
    >({
      query: (queryArg) => ({
        url: `/profiles/${queryArg.profileId}/follow`,
        method: "POST",
        body: queryArg.followDto,
      }),
    }),
    participantProfileControllerUnfollow: build.mutation<
      ParticipantProfileControllerUnfollowApiResponse,
      ParticipantProfileControllerUnfollowRequest
    >({
      query: (queryArg) => ({
        url: `/profiles/${queryArg.profileId}/unfollow`,
        method: "POST",
        body: queryArg.followDto,
      }),
    }),
    participantProfileControllerBlock: build.mutation<
      ParticipantProfileControllerBlockApiResponse,
      ParticipantProfileControllerBlockRequest
    >({
      query: (queryArg) => ({
        url: `/profiles/${queryArg.profileId}/block`,
        method: "POST",
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type ParticipantProfileControllerFindOneApiResponse =
  /** status 200  */ ProfileWithPropertiesAndFollowingDataDto;
export type ParticipantProfileControllerFindOneRequest = {
  profileId: string;
  myProfile?: string;
};
export type ParticipantProfileControllerFollowApiResponse =
  /** status 201  */ Following;
export type ParticipantProfileControllerFollowRequest = {
  profileId: string;
  followDto: FollowDto;
};
export type ParticipantProfileControllerUnfollowApiResponse =
  /** status 201  */ Following;
export type ParticipantProfileControllerUnfollowRequest = {
  profileId: string;
  followDto: FollowDto;
};
export type ParticipantProfileControllerBlockApiResponse =
  /** status 201  */ ParticipantProfile;
export type ParticipantProfileControllerBlockRequest = {
  profileId: string;
};
export type PromptOverride = {
  prompt: string;
  botId: string;
  type: "post" | "comment" | "interaction";
};
export type PostLabel = {
  _id: string;
  study: string;
  title: string;
  isTitleHidden?: boolean;
  imageUrl?: string;
  color?: string;
  createdAt: string;
  updatedAt: string;
  duplicatedFromId?: string;
};
export type ParticipantProfileValue = {
  _id: string;
  profile: string;
  name:
    | "first-name"
    | "last-name"
    | "bio/description"
    | "location"
    | "political-affiliation"
    | "age"
    | "gender"
    | "race"
    | "household-income"
    | "profile-picture";
  value: string | number | boolean;
  type?: string;
};
export type ProfileWithPropertiesAndFollowingDataDto = {
  _id: string;
  study: string;
  user?: string;
  treatmentArm?: string;
  tags?: string[];
  postPrompt?: string;
  commentPrompt?: string;
  interactionPrompt?: string;
  qualified: boolean;
  profileNumber?: string;
  participantCode?: string;
  followers: number;
  following: number;
  exitSurveySchedule?: string;
  finishedOnboardingSurveyAt?: string;
  enteredNewsfeedAt?: string;
  isAutoFollowingDisabled?: boolean;
  duplicatedFromId?: string;
  blockedProfiles?: string[];
  treatmentArmRestrictions?: object;
  promptOverrides?: PromptOverride[];
  labels?: PostLabel[];
  highlight?: PostLabel;
  properties: ParticipantProfileValue[];
  isFollowedByMe: boolean;
  isFollowingMe: boolean;
};
export type ApiUnauthorizedResponseDto = {
  statusCode: number;
  message: string;
};
export type ApiNotFoundResponseDto = {
  statusCode: number;
  message: string;
};
export type Following = {
  _id: string;
  createdAt: string;
  updatedAt: string;
  profile: string;
  follower: string;
};
export type FollowDto = {
  myProfile: string;
};
export type ParticipantProfile = {
  _id: string;
  study: string;
  user?: string;
  treatmentArm?: string;
  tags?: string[];
  postPrompt?: string;
  commentPrompt?: string;
  interactionPrompt?: string;
  qualified: boolean;
  profileNumber?: string;
  participantCode?: string;
  followers: number;
  following: number;
  exitSurveySchedule?: string;
  finishedOnboardingSurveyAt?: string;
  enteredNewsfeedAt?: string;
  isAutoFollowingDisabled?: boolean;
  duplicatedFromId?: string;
  blockedProfiles?: string[];
  treatmentArmRestrictions?: object;
  promptOverrides?: PromptOverride[];
};
export const {
  useParticipantProfileControllerFindOneQuery,
  useLazyParticipantProfileControllerFindOneQuery,
  useParticipantProfileControllerFollowMutation,
  useParticipantProfileControllerUnfollowMutation,
  useParticipantProfileControllerBlockMutation,
} = injectedRtkApi;
