import { api } from "../api-service";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    analyticsControllerCreateMany: build.mutation<
      AnalyticsControllerCreateManyApiResponse,
      AnalyticsControllerCreateManyRequest
    >({
      query: (queryArg) => ({
        url: `/analytics/bulk`,
        method: "POST",
        body: queryArg.batchCreateAnalyticsItemDto,
      }),
    }),
    analyticsControllerCreate: build.mutation<
      AnalyticsControllerCreateApiResponse,
      AnalyticsControllerCreateRequest
    >({
      query: (queryArg) => ({
        url: `/analytics`,
        method: "POST",
        body: queryArg.createAnalyticsItemDto,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type AnalyticsControllerCreateManyApiResponse =
  /** status 201  */ AnalyticsItem[];
export type AnalyticsControllerCreateManyRequest = {
  batchCreateAnalyticsItemDto: BatchCreateAnalyticsItemDto;
};
export type AnalyticsControllerCreateApiResponse =
  /** status 201  */ AnalyticsItem;
export type AnalyticsControllerCreateRequest = {
  createAnalyticsItemDto: CreateAnalyticsItemDto;
};
export type AnalyticsItem = {
  _id: string;
  participant: string;
  module: string;
  study: string;
  meta?: object;
  createdAt: string;
  updatedAt: string;
};
export type ApiUnauthorizedResponseDto = {
  statusCode: number;
  message: string;
};
export type ApiNotFoundResponseDto = {
  statusCode: number;
  message: string;
};
export type CreateAnalyticsItemDto = {
  participant: string;
  module: string;
  study: string;
  meta?: object;
};
export type BatchCreateAnalyticsItemDto = {
  items: CreateAnalyticsItemDto[];
};
export const {
  useAnalyticsControllerCreateManyMutation,
  useAnalyticsControllerCreateMutation,
} = injectedRtkApi;
