/**
 * Extracts URLs from a given string.
 * @param {string} str
 * @param {boolean} lowerCase
 * @return {string[]} The extracted URLs from the given string
 */
export const extractURLs = (
  str: string,
  lowerCase: boolean = false
): string[] => {
  const regexp =
    /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()'@:%_\+.~#?!&//=]*)/gi;

  if (str) {
    const urls = str.match(regexp);
    if (urls) {
      return lowerCase ? urls.map((item) => item.toLowerCase()) : urls;
    }
  }
  return [];
};
