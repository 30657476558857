import { Box, CloseIcon, HStack, IconButton, Text } from "native-base";
import React from "react";
import { Dimensions, GestureResponderEvent } from "react-native";

interface Props {
  title: string;
  body: string;
  onClose: (event: GestureResponderEvent) => void;
}

export const NotificationBubble: React.FC<Props> = (props) => {
  return (
    <Box width={Dimensions.get("screen").width} px={2}>
      <HStack
        bg="white"
        px={4}
        py={2}
        shadow="3"
        w="full"
        rounded="md"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box maxWidth="85%">
          <Text color="primary" fontWeight="bold" fontSize="sm">
            {props.title}
          </Text>
          <Text fontSize="xs" noOfLines={2} isTruncated>
            {props.body}
          </Text>
        </Box>
        <IconButton onPress={props.onClose} icon={<CloseIcon />}></IconButton>
      </HStack>
    </Box>
  );
};
