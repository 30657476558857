import React from "react";
import { MagicCodeScreenProps } from "./magic-code.screen";
import { useAuthControllerAuthenticateWithCodeMutation } from "../../services/api-service-sub-services/authentication-api-service";
import { useAppDispatch } from "../../store/store";
import { userSlice } from "../../store/user.slice";
import { generateUUID } from "../core/utils/uuid.util";
import AsyncStorage from "@react-native-async-storage/async-storage";
import * as Linking from "expo-linking";
import { useTrackEvent } from "../core/hooks/use-track-event";
import { events } from "../../constants/analytics.constants";
import * as Sentry from "sentry-expo";
import { useOpenErrorModal } from "../core/hooks/use-open-error-modal";

export const MagicCodeContainer = (Screen: React.FC<MagicCodeScreenProps>) =>
  function _() {
    const dispatch = useAppDispatch();
    const track = useTrackEvent();
    const openErrorModal = useOpenErrorModal();

    const [validateMagicCode, { isLoading: isMagicCodeValidating }] =
      useAuthControllerAuthenticateWithCodeMutation({
        selectFromResult: ({ isLoading }) => ({ isLoading }),
      });

    return (
      <Screen
        isBusy={isMagicCodeValidating}
        onContactSupportButtonPress={async () => {
          try {
            await Linking.openURL("mailto:info@spark-social.com");
          } catch (e) {}
        }}
        onVerificationCodeEntry={async (verificationCode: string) => {
          try {
            // create and store a fake device UUID since apple does not allow device id tracking
            let deviceUuid = generateUUID();
            const storedUuid = await AsyncStorage.getItem("SMA_DEVICE_ID");
            if (storedUuid) {
              deviceUuid = storedUuid;
            } else {
              await AsyncStorage.setItem("SMA_DEVICE_ID", deviceUuid);
            }

            const authResponse = await validateMagicCode({
              authenticateWithCodeDto: {
                code: verificationCode.toLowerCase(),
                deviceId: deviceUuid,
              },
            }).unwrap();

            dispatch(
              userSlice.actions.userAuthenticated({
                accessToken: authResponse.accessToken,
                refreshToken: authResponse.refreshToken,
                user: authResponse.user,
              })
            );
            track(
              events.userSignedIn,
              {
                participantCode: verificationCode,
              },
              true
            );
          } catch (e: any) {
            track(events.userEnteredInvalidInviteCode, {
              inviteCode: verificationCode,
            });
            Sentry.Native.captureException(e);
            openErrorModal();
          }
        }}
      />
    );
  };
