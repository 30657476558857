import React from "react";
import { Box, Button, HStack, Text, VStack } from "native-base";
import WebView from "react-native-webview";
import { AntDesign } from "@expo/vector-icons";
import { Platform } from "react-native";

export type InAppBrowserScreenProps = {
  url: string;
  userPressedCloseButton: () => void;
  onInAppBrowserURLChange: (url: string) => void;
};

export const InAppBrowserScreen = (props: InAppBrowserScreenProps) => {
  return (
    <VStack variant="screen" flex="1" safeAreaTop>
      <HStack
        space="3"
        px="5"
        borderBottomColor="medGrey"
        borderBottomWidth="0.5"
        py="3"
      >
        <Button p="0" variant="unstyled" onPress={props.userPressedCloseButton}>
          <AntDesign name="close" size={24} color="#444A5F" />
        </Button>
        <Box flex="1">
          <Text numberOfLines={1} overflow="hidden">
            {props.url}
          </Text>
        </Box>
      </HStack>
      {Platform.OS === "web" && <iframe src={props.url} style={{ flex: 1 }} />}
      {Platform.OS !== "web" && (
        <WebView
          androidLayerType="software"
          onNavigationStateChange={(event) => {
            props.onInAppBrowserURLChange(event.url);
          }}
          source={{ uri: props.url }}
          style={{
            flex: 1,
          }}
        />
      )}
    </VStack>
  );
};
