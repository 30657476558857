import React from "react";
import { createStackNavigator } from "@react-navigation/stack";
import { WelcomeContainer } from "../../modules/auth/welcome.container";
import { WelcomeScreen } from "../../modules/auth/welcome.screen";
import { MagicCodeContainer } from "../../modules/auth/magic-code.container";
import { MagicCodeScreen } from "../../modules/auth/magic-code.screen";
import { Routes } from "../routes";

export type AuthStackParamList = {
  [Routes.AUTH_STACK__WELCOME]: {};
  [Routes.AUTH_STACK__MAGIC_CODE]: {};
};

const Welcome = WelcomeContainer(WelcomeScreen);
const MagicCode = MagicCodeContainer(MagicCodeScreen);

const Stack = createStackNavigator<AuthStackParamList>();

export const AuthStackNavigator = () => {
  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: false,
      }}
    >
      <Stack.Screen name={Routes.AUTH_STACK__WELCOME} component={Welcome} />
      <Stack.Screen
        name={Routes.AUTH_STACK__MAGIC_CODE}
        component={MagicCode}
      />
    </Stack.Navigator>
  );
};
