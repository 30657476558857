import React from "react";
import { Box, Column, Row, Text } from "native-base";
import { ProfileAvatar } from "./profile-avatar";
import { ParticipantProfileWithPropertiesDto } from "../../../services/api-service-sub-services/users-api-service";
import { useProfileValues } from "../../profile/hooks/use-profile-values";
import { LocationIcon } from "../icons/location-icon";
import { PoliticalAffiliationIcon } from "../icons/politication-affiliation-icon";
import { GenderIcon } from "../icons/gender-icon";
import { useFeatureAvailability } from "../hooks/use-feature-availability";

type ProfileMetadataProps = {
  profile?: ParticipantProfileWithPropertiesDto;
  postCount: number;
};
export const ProfileMetadata = (props: ProfileMetadataProps) => {
  const profileValues = useProfileValues(props.profile?.properties);

  const isConnectionsEnabled = useFeatureAvailability("connections");

  return (
    <Column px="5" bg="white" pt="5" pb="1">
      <Row width="100%">
        <ProfileAvatar profile={props.profile} />
        <Row flex={1} alignItems="center">
          <Box
            {...(isConnectionsEnabled && { flex: 1 })}
            {...(!isConnectionsEnabled && { ml: 8 })}
          >
            <Text textAlign="center" variant="label">
              Posts
            </Text>
            <Text textAlign="center" variant="heading">
              {props.postCount}
            </Text>
          </Box>
          {isConnectionsEnabled && (
            <>
              <Box flex="1">
                <Text textAlign="center" variant="label">
                  Following
                </Text>
                <Text textAlign="center" variant="heading">
                  {props.profile?.following || 0}
                </Text>
              </Box>
              <Box flex="1">
                <Text textAlign="center" variant="label">
                  Followers
                </Text>
                <Text textAlign="center" variant="heading">
                  {props.profile?.followers || 0}
                </Text>
              </Box>
            </>
          )}
        </Row>
      </Row>
      <Box width="100%" pt="4">
        <Text fontWeight="bold" mb="2" variant="heading">
          {profileValues.displayName}
        </Text>
        {profileValues.bioDescription && (
          <Text variant="small" mb="3">
            {profileValues.bioDescription}
          </Text>
        )}
      </Box>
      <Row space="5" alignItems="center" mb="1">
        {profileValues.location && (
          <Row space="1" alignItems="center">
            <LocationIcon />
            <Text variant="small">{profileValues.location}</Text>
          </Row>
        )}
        {profileValues.politicalAffiliation && (
          <Row space="2" alignItems="center">
            <PoliticalAffiliationIcon />
            <Text variant="small">{profileValues.politicalAffiliation}</Text>
          </Row>
        )}
        {profileValues.gender && (
          <Row space="2" alignItems="center">
            <GenderIcon />
            <Text variant="small">{profileValues.gender}</Text>
          </Row>
        )}
      </Row>
    </Column>
  );
};
