import React from "react";
import {
  FormControl,
  IInputProps,
  Input,
  WarningOutlineIcon,
} from "native-base";
import { Control, Controller } from "react-hook-form";
import { RegisterOptions } from "react-hook-form/dist/types/validator";

export type CoreInputProps = IInputProps & {
  errorMessage?: string;
};

export const CoreInput = (props: CoreInputProps) => {
  const { errorMessage, ...inputProps } = props;

  return (
    <FormControl isInvalid={!!errorMessage} flex="1">
      <Input variant="primary" {...inputProps} fontSize="16" />
      <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>
        {errorMessage}
      </FormControl.ErrorMessage>
    </FormControl>
  );
};

export type ControlledCoreInputProps = {
  control: Control;
  name: string;
  placeholder: string;
  rules?: Omit<
    RegisterOptions,
    "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled"
  >;
};

export const ControlledCoreInput = (props: ControlledCoreInputProps) => {
  return (
    <Controller
      rules={props.rules}
      control={props.control}
      name={props.name}
      render={({ field, fieldState }) => {
        return (
          <CoreInput
            placeholder={props.placeholder}
            errorMessage={fieldState.error?.message}
            onChangeText={field.onChange}
            onBlur={field.onBlur}
          />
        );
      }}
    />
  );
};
