import React, { useEffect, useState } from "react";
import {
  Box,
  Flex,
  Heading,
  HStack,
  Input,
  KeyboardAvoidingView,
  Spinner,
  Text,
  VStack,
} from "native-base";
import { Keyboard, TouchableOpacity } from "react-native";

export type MagicCodeScreenProps = {
  onVerificationCodeEntry: (verificationCode: string) => Promise<void>;
  onContactSupportButtonPress: () => Promise<void>;
  isBusy: boolean;
};

export const MagicCodeScreen = (props: MagicCodeScreenProps) => {
  const [verificationCode, setVerificationCode] = useState<string>("");

  useEffect(() => {
    if (verificationCode.length === 6) {
      Keyboard.dismiss();
      setVerificationCode("");
      props.onVerificationCodeEntry(verificationCode);
    }
  }, [verificationCode]);

  return (
    <VStack flex="1" variant="screen" px="5" pt="10" safeArea>
      <KeyboardAvoidingView
        flex="1"
        behavior="height"
        justifyContent="space-between"
        alignItems="center"
      >
        <VStack space="3">
          <Heading variant="title1">Account Verification</Heading>
          <Text>
            Please enter your invite code. This is provided to you by one of our
            administrators.
          </Text>
        </VStack>
        <Box height="10" />
        {props.isBusy && <Spinner size="lg" />}
        {!props.isBusy && (
          <VStack width="100%" space="3">
            <Input
              value={verificationCode}
              onChangeText={(value) => {
                setVerificationCode(value.trim().toUpperCase());
              }}
              variant="filled"
              textAlign="center"
              textTransform="uppercase"
              fontWeight="extrabold"
              letterSpacing={verificationCode.length === 0 ? "2xl" : "22"}
              fontSize="24"
              autoCapitalize="characters"
              autoCorrect={false}
              autoComplete="off"
              placeholder="INVITE CODE"
              placeholderTextColor="medGrey"
              underlineColorAndroid="transparent"
            />
          </VStack>
        )}
        <Flex flex={1} />
        <HStack space="2" justifyContent="center" width="100%" mb="5">
          <Text textAlign="center" color="black">
            Having issues?
          </Text>
          <TouchableOpacity onPress={props.onContactSupportButtonPress}>
            <Text color="primary" bold>
              Contact Support
            </Text>
          </TouchableOpacity>
        </HStack>
      </KeyboardAvoidingView>
    </VStack>
  );
};
