import React from "react";
import { Box, FormControl, Image, WarningOutlineIcon } from "native-base";
import { IconAvatar, IconCamera } from "../../../../assets/svgs";
import { TouchableOpacity } from "react-native";
import { Control, Controller } from "react-hook-form";
import { RegisterOptions } from "react-hook-form/dist/types/validator";

export type CoreAvatarSelectorProps = {
  onPress: () => void;
  errorMessage?: string;
  value?: string;
};

export const CoreAvatarSelect = (props: CoreAvatarSelectorProps) => {
  return (
    <FormControl isInvalid={!!props.errorMessage} flex="1">
      <TouchableOpacity onPress={props.onPress}>
        <Box
          height="24"
          width="24"
          bgColor="xLightGrey"
          borderRadius="100"
          alignItems="center"
          justifyContent="flex-end"
        >
          {props.value && (
            <Box
              height="100%"
              width="100%"
              overflow="hidden"
              alignItems="center"
              justifyContent="center"
              borderRadius="100"
            >
              <Image
                backgroundColor="red.50"
                source={{ uri: props.value }}
                width="100%"
                alt="Avatar"
                height="100%"
                resizeMethod="scale"
                resizeMode="cover"
              />
            </Box>
          )}
          {!props.value && <IconAvatar />}
          <Box
            position="absolute"
            height="10"
            width="10"
            borderWidth="2"
            borderColor="white"
            right="0"
            bottom="0"
            bgColor="primary"
            borderRadius="100"
            alignItems="center"
            justifyContent="center"
          >
            <IconCamera />
          </Box>
        </Box>
      </TouchableOpacity>
      <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>
        {props.errorMessage}
      </FormControl.ErrorMessage>
    </FormControl>
  );
};

export type ControlledCoreAvatarSelectProps = {
  control: Control;
  name: string;
  rules?: Omit<
    RegisterOptions,
    "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled"
  >;
} & CoreAvatarSelectorProps;

export const ControlledCoreAvatarSelect = (
  props: ControlledCoreAvatarSelectProps
) => {
  return (
    <Controller
      rules={props.rules}
      control={props.control}
      name={props.name}
      render={({ field, fieldState }) => {
        return (
          <CoreAvatarSelect
            onPress={props.onPress}
            errorMessage={fieldState.error?.message}
            value={field.value}
          />
        );
      }}
    />
  );
};
