import React, { Fragment, useCallback, useRef, useState } from "react";
import {
  Box,
  Button,
  FlatList,
  HStack,
  Input,
  Text,
  View,
  VStack,
} from "native-base";
import { extractURLs } from "../core/utils/url.util";
import { ProfileAvatar } from "../core/components/profile-avatar";
import {
  extractFullName,
  extractProfileProperty,
} from "../profile/utils/profile.util";
import { LinkPreviewComponent } from "./link-preview.component";
import {
  CommentReturnDto,
  ParticipantProfileWithPropertiesDto,
} from "../../services/api-service-sub-services/studies-api-service";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { selectUserProfile, selectUserStudyId } from "../../store/user.slice";
import {
  Keyboard,
  ListRenderItemInfo,
  TouchableOpacity,
  ViewToken,
} from "react-native";
import { CreateAnalyticsItemDto } from "../../services/api-service-sub-services/analytics-api-service";
import dayjs from "dayjs";
import { analyticsSlice, Modules } from "../../store/analytics.slice";
import { useTreatmentArmExperience } from "../core/hooks/use-treatment-arm-experience";
import { useNavigation } from "@react-navigation/native";
import { Routes } from "../../navigation/routes";
import { PlaneIcon } from "../core/icons/plane-icon";
import { extractMentions, MENTION_REGEX } from "./utils/mentions";
import { useMentions } from "react-native-controlled-mentions";
import { MentionSuggestionsList } from "./components/mentions-suggestion-list";
import { useFeatureAvailability } from "../core/hooks/use-feature-availability";

type Props = {
  postId: string;
  defaultCommentContent?: string;
  comments: CommentReturnDto[];
  isBusy: boolean;
  isVisible: boolean;
  highlightedCommentId?: string;
  onPostCommentButtonPress: (content: string) => Promise<void>;
  onNewCommentTextChange?: (text: string) => void;
};

export const PostCommentsComponent = ({
  highlightedCommentId,
  defaultCommentContent = "",
  ...props
}: Props) => {
  const dispatch = useAppDispatch();

  const isBotTaggingEnabled = useFeatureAvailability("bot-tagging");

  const [commentContent, setCommentContent] = useState<string>(
    defaultCommentContent
  );

  const handleCommentContentChange = (text: string) => {
    props.onNewCommentTextChange?.(text);
    setCommentContent(text);
  };

  const { textInputProps, triggers } = useMentions({
    value: commentContent,
    onChange: handleCommentContentChange,
    triggersConfig: {
      mention: {
        trigger: "@",
        textStyle: { fontWeight: "bold" },
      },
    },
  });

  const profile = useAppSelector(selectUserProfile);
  const studyId = useAppSelector(selectUserStudyId);
  const userProfile = useAppSelector(selectUserProfile);
  const navigation = useNavigation();

  const previousViewedItemsRef = useRef<ViewToken[]>([]);
  const commentStartTimestampRef = useRef<string | null>(null);

  const viewabilityConfigRef = useRef({
    minimumViewTime: 100,
    viewAreaCoveragePercentThreshold: 0,
  });
  const onViewableItemsChangedRef = useRef(
    ({ viewableItems }: { viewableItems: ViewToken[] }) => {
      if (!userProfile || !studyId) {
        return;
      }

      const commentsToAddToQueue: CreateAnalyticsItemDto[] = [];

      // Filters out posts that haven't left the view yet to prevent duplicates while scrolling
      const itemsNotPreviouslyViewed = viewableItems.filter(({ item }) => {
        return !previousViewedItemsRef.current.some(
          ({ item: prevItem }) => item._id === prevItem._id
        );
      });

      itemsNotPreviouslyViewed.forEach(({ item }) => {
        commentsToAddToQueue.push({
          module: Modules.COMMENTS,
          participant: userProfile._id,
          study: studyId,
          meta: {
            description: "User viewed comment",
            timestamp: dayjs.utc().toISOString(),
            postId: props.postId,
            targetCommentId: item._id,
          },
        });
      });

      previousViewedItemsRef.current = viewableItems;

      if (commentsToAddToQueue.length) {
        dispatch(analyticsSlice.actions.addItemsToQueue(commentsToAddToQueue));
      }
    }
  );

  const treatmentArmExperience = useTreatmentArmExperience();

  const renderItem = useCallback(
    ({ item }: ListRenderItemInfo<CommentReturnDto>) => {
      const urls = extractURLs(item.content);

      let displayName = extractFullName(item.profile.properties);
      if (
        treatmentArmExperience.profile.isFirstNameEnabled &&
        !treatmentArmExperience.profile.isLastNameEnabled
      ) {
        displayName =
          extractProfileProperty("first-name", item.profile.properties) || "";
      } else if (
        !treatmentArmExperience.profile.isFirstNameEnabled &&
        treatmentArmExperience.profile.isLastNameEnabled
      ) {
        displayName =
          extractProfileProperty("last-name", item.profile.properties) || "";
      } else if (
        !treatmentArmExperience.profile.isFirstNameEnabled &&
        !treatmentArmExperience.profile.isLastNameEnabled
      ) {
        // display anonymous user
        displayName = extractFullName();
      }

      const handleProfilePress = (
        profile: ParticipantProfileWithPropertiesDto | string = item.profile
      ) => {
        // @ts-ignore
        navigation.navigate(Routes.APP_STACK__BOT_PROFILE, {
          profile: profile,
        });
      };

      const contentFragments = extractMentions(item.content);

      return (
        <HStack space="3" alignItems="flex-start" key={item._id}>
          <TouchableOpacity onPress={() => handleProfilePress()}>
            <ProfileAvatar profile={item.profile} length="12" />
          </TouchableOpacity>
          <Box
            bg={
              item._id === highlightedCommentId ? "primaries.100" : "xLightGrey"
            }
            flex="1"
            borderRadius="xl"
            p="3"
          >
            <HStack alignItems="flex-start" justifyContent="space-between">
              <Text bold>{displayName}</Text>
            </HStack>
            <Text>
              {contentFragments.map((fragment: string, index: number) => {
                if (MENTION_REGEX.test(fragment)) {
                  const mention = fragment.replace(/(\[)([^\]]+)(])/g, "$2");
                  const mentionedProfile = item.taggedProfiles?.find(
                    (profile) => profile.matcher === fragment
                  );

                  if (mentionedProfile) {
                    return (
                      <Text
                        key={index}
                        color="secondary"
                        onPress={() => {
                          handleProfilePress(mentionedProfile.profileId);
                        }}
                      >
                        {mention}
                      </Text>
                    );
                  } else {
                    return <Text key={index}>{mention}</Text>;
                  }
                }
                return <Text key={index}>{fragment}</Text>;
              })}
            </Text>
            {urls.length > 0 && (
              <Box mt="2" borderRadius="xl" overflow="hidden">
                <LinkPreviewComponent url={urls[0]} />
              </Box>
            )}
          </Box>
        </HStack>
      );
    },
    [highlightedCommentId]
  );

  // if it's not set as visible
  if (!props.isVisible) {
    return null;
  }

  return (
    <Fragment>
      {/** posts comments as a list */}
      <VStack px="5" mt="5" space="3">
        <FlatList
          data={props.comments}
          scrollEnabled={false}
          keyExtractor={({ _id }) => _id}
          ItemSeparatorComponent={() => <View height="3" />}
          renderItem={renderItem}
          viewabilityConfig={viewabilityConfigRef.current}
          onViewableItemsChanged={onViewableItemsChangedRef.current}
        />

        {/** new comment */}
        <VStack>
          <MentionSuggestionsList {...triggers.mention} />
          <HStack alignItems="flex-start">
            {/** avatar of the current user */}
            <ProfileAvatar profile={profile} length="12" />
            {/** input field */}
            <Input
              variant="primary"
              alignSelf="center"
              bg="xLightGrey"
              flex="1"
              borderRadius="xl"
              p="3"
              height="12"
              fontSize="16"
              placeholder="Write something..."
              onFocus={() => {
                if (commentStartTimestampRef.current === null) {
                  commentStartTimestampRef.current = dayjs.utc().toISOString();
                }
              }}
              onBlur={() => {
                if (
                  commentStartTimestampRef.current &&
                  profile?._id &&
                  studyId
                ) {
                  dispatch(
                    analyticsSlice.actions.addItemToQueue({
                      study: studyId,
                      participant: profile._id,
                      module: Modules.POSTS,
                      meta: {
                        description: "User time writing comment",
                        timestamp: dayjs.utc().toISOString(),
                        timeWriting: Math.round(
                          dayjs.utc().diff(commentStartTimestampRef.current) /
                            1000
                        ),
                        postId: props.postId,
                      },
                    })
                  );
                }

                commentStartTimestampRef.current = null;
              }}
              marginLeft="3"
              {...(isBotTaggingEnabled
                ? textInputProps
                : {
                    onChangeText: handleCommentContentChange,
                    value: commentContent,
                  })}
            />
            <Box alignSelf="center">
              <Button
                marginLeft="0"
                isDisabled={commentContent.length === 0}
                isLoading={props.isBusy}
                variant="unstyled"
                onPress={async () => {
                  await props.onPostCommentButtonPress(commentContent);
                  props.onNewCommentTextChange?.("");
                  setCommentContent("");
                  Keyboard.dismiss();
                }}
              >
                <PlaneIcon />
              </Button>
            </Box>
          </HStack>
        </VStack>
      </VStack>
    </Fragment>
  );
};
