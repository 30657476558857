import React, { useMemo, useState } from "react";
import { ProfileSetupScreenProps } from "./profile-setup.screen";
import { useAppDispatch, useAppSelector } from "../../store/store";
import {
  selectUserId,
  selectUserStudyId,
  userSlice,
} from "../../store/user.slice";
import {
  CreateRealParticipantProfileValuesDto,
  useLazyUsersControllerGetProfileQuery,
  useUsersControllerUpdateProfileMutation,
} from "../../services/api-service-sub-services/users-api-service";
import { NativeStackScreenProps } from "react-native-screens/native-stack";
import { AppStackParamList } from "../../navigation/navigators/app-stack.navigator";
import { Routes } from "../../navigation/routes";
import {
  CharacteristicEntity,
  useStudiesControllerGetStudyQuery,
} from "../../services/api-service-sub-services/studies-api-service";
import { useMediaControllerFindAllQuery } from "../../services/api-service-sub-services/media-api-service";
import { useTrackEvent } from "../core/hooks/use-track-event";
import { events } from "../../constants/analytics.constants";

export const ProfileCharacteristics = {
  FIRST_NAME: "first-name",
  LAST_NAME: "last-name",
  BIO: "bio/description",
  LOCATION: "location",
  POLITICAL_AFFILIATION: "political-affiliation",
  AGE: "age",
  GENDER: "gender",
  RACE: "race",
  HOUSEHOLD_INCOME: "household-income",
  PROFILE_PICTURE: "profile-picture",
};

export const getFormInputType = (characteristic: CharacteristicEntity) => {
  if (characteristic.name === ProfileCharacteristics.PROFILE_PICTURE) {
    return characteristic.name;
  }
  if (characteristic.name === ProfileCharacteristics.BIO) {
    return "text-area";
  }
  if (!!characteristic.pool.length) {
    return "select";
  }
  return "input";
};

type ContainerProps = NativeStackScreenProps<
  AppStackParamList,
  Routes.APP_STACK__PROFILE_SETUP
> & {};

const arrangeCharacteristics = (
  characteristicEntities: CharacteristicEntity[]
): CharacteristicEntity[] => {
  const firstNameObj = characteristicEntities.find(
    (item) => item.name === "first-name"
  );
  const lastNameObj = characteristicEntities.find(
    (item) => item.name === "last-name"
  );
  const updatedArr = characteristicEntities.filter(
    (item) => item.name !== "first-name" && item.name !== "last-name"
  );

  if (lastNameObj) {
    updatedArr.unshift(lastNameObj);
  }

  if (firstNameObj) {
    updatedArr.unshift(firstNameObj);
  }

  return updatedArr;
};

export const ProfileSetupContainer = (
  Screen: React.FC<ProfileSetupScreenProps>
) => {
  return function _(props: ContainerProps) {
    const dispatch = useAppDispatch();
    const track = useTrackEvent();
    const [isBusy, setIsBusy] = useState<boolean>(false);
    const userCurrentStudyId = useAppSelector(selectUserStudyId);
    const userId = useAppSelector(selectUserId);

    const { data: study } = useStudiesControllerGetStudyQuery(
      { id: userCurrentStudyId as string },
      { selectFromResult: ({ data }) => ({ data }) }
    );
    const { data: avatars } = useMediaControllerFindAllQuery(
      { study: userCurrentStudyId as string, gallery: "participants" },
      { selectFromResult: ({ data }) => ({ data }) }
    );

    const [getUserProfile] = useLazyUsersControllerGetProfileQuery({
      selectFromResult: () => ({}),
    });
    const [updateUserProfile] = useUsersControllerUpdateProfileMutation({
      selectFromResult: () => ({}),
    });

    const characteristics = useMemo<CharacteristicEntity[]>(() => {
      if (study?.characteristics) {
        return arrangeCharacteristics(
          study.characteristics.filter((study) =>
            Object.values(ProfileCharacteristics).some(
              (value) => value === study.name
            )
          )
        );
      }
      return [];
    }, [study]);

    return (
      <Screen
        profileCharacteristics={characteristics}
        isBusy={isBusy}
        avatars={avatars ? avatars.map((avatar) => avatar.url) : []}
        onUpdateProfileButtonPress={async (properties) => {
          try {
            setIsBusy(true);
            await updateUserProfile({
              createRealParticipantProfileDto: {
                properties:
                  properties as CreateRealParticipantProfileValuesDto[],
                study: userCurrentStudyId,
              },
              id: userId,
            }).unwrap();
            const profile = await getUserProfile({ id: userId }).unwrap();

            dispatch(userSlice.actions.userProfileFetched(profile));
            if (profile) {
              track(
                events.userSignedUp,
                {
                  participantCode: profile.participantCode as string,
                },
                true
              );
            }
            track(events.userCompletedProfileSetup);
            props.navigation.replace(Routes.APP_STACK__ONBOARDING_SURVEY);
          } catch (e) {
          } finally {
            setIsBusy(false);
          }
        }}
      />
    );
  };
};
