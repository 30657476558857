import * as React from "react";
import Svg, { Path, SvgProps } from "react-native-svg";
import { useTheme } from "native-base";
import { ColorValue } from "react-native";

const SvgComponent = (props: SvgProps) => {
  const theme = useTheme();
  return (
    <Svg width={24} height={24} fill="none" {...props}>
      <Path
        fill={theme.colors.primary as unknown as ColorValue}
        stroke={theme.colors.secondary as unknown as ColorValue}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M7.03 14.24 8 22l3-6L22 2 7.03 14.24Z"
      />
      <Path
        fill={theme.colors.secondary as unknown as ColorValue}
        stroke={theme.colors.primary as unknown as ColorValue}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m11 16-3 6 7.76-3.88L11 16ZM2 12l5.03 2.24L22 2 2 12ZM11 16l9 4 2-18-11 14Z"
      />
    </Svg>
  );
};

export { SvgComponent as PlaneIcon };
