import React from "react";
import { Center, HStack, Spinner, Text, VStack } from "native-base";
import WebView from "react-native-webview";
import { Platform } from "react-native";

export type OnboardingSurveyScreenProps = {
  qualtricsSurveyURL?: string;
  isLoading: boolean;
};

export const OnboardingSurveyScreen = (props: OnboardingSurveyScreenProps) => {
  return (
    <VStack flex="1" bg="xLightGrey" safeAreaTop={true} justifyContent="center">
      {(!props.qualtricsSurveyURL || props.isLoading) && (
        <Center>
          <HStack space="2" alignItems="center">
            <Spinner size="sm" color="black" />
            <Text variant="small" color="black">
              Loading...
            </Text>
          </HStack>
        </Center>
      )}
      {props.qualtricsSurveyURL && Platform.OS !== "web" && (
        <WebView
          incognito={true}
          source={{ uri: props.qualtricsSurveyURL }}
          style={{ flex: 1 }}
          androidLayerType="software"
        />
      )}
      {props.qualtricsSurveyURL && Platform.OS === "web" && (
        <iframe src={props.qualtricsSurveyURL} style={{ flex: 1 }} />
      )}
    </VStack>
  );
};
