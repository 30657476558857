import { api } from "../api-service";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    mediaControllerFindAll: build.query<
      MediaControllerFindAllApiResponse,
      MediaControllerFindAllRequest
    >({
      query: (queryArg) => ({
        url: `/media`,
        params: { study: queryArg.study, gallery: queryArg.gallery },
      }),
    }),
    mediaControllerCreate: build.mutation<
      MediaControllerCreateApiResponse,
      MediaControllerCreateRequest
    >({
      query: (queryArg) => ({
        url: `/media`,
        method: "POST",
        body: queryArg.createMediaDto,
      }),
    }),
    mediaControllerDelete: build.mutation<
      MediaControllerDeleteApiResponse,
      MediaControllerDeleteRequest
    >({
      query: (queryArg) => ({ url: `/media/${queryArg.id}`, method: "DELETE" }),
    }),
    mediaControllerCreateBulk: build.mutation<
      MediaControllerCreateBulkApiResponse,
      MediaControllerCreateBulkRequest
    >({
      query: (queryArg) => ({
        url: `/media/bulk`,
        method: "POST",
        body: queryArg.bulkCreateMediaDto,
      }),
    }),
    mediaControllerUpload: build.mutation<
      MediaControllerUploadApiResponse,
      MediaControllerUploadRequest
    >({
      query: (queryArg) => ({
        url: `/media/upload`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    mediaControllerBulkUpload: build.mutation<
      MediaControllerBulkUploadApiResponse,
      MediaControllerBulkUploadRequest
    >({
      query: (queryArg) => ({
        url: `/media/upload/bulk`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    mediaControllerFetchExternalFileAndSaveToS3: build.mutation<
      MediaControllerFetchExternalFileAndSaveToS3ApiResponse,
      MediaControllerFetchExternalFileAndSaveToS3Request
    >({
      query: (queryArg) => ({
        url: `/media/url`,
        method: "POST",
        body: queryArg.saveUrlToS3Dto,
      }),
    }),
    mediaControllerBatchFetchExternalFileAndSaveToS3: build.mutation<
      MediaControllerBatchFetchExternalFileAndSaveToS3ApiResponse,
      MediaControllerBatchFetchExternalFileAndSaveToS3Request
    >({
      query: (queryArg) => ({
        url: `/media/urls`,
        method: "POST",
        body: queryArg.saveUrlsToS3Dto,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type MediaControllerFindAllApiResponse = /** status 200  */ Media[];
export type MediaControllerFindAllRequest = {
  /** Query media files by study */
  study?: string;
  /** Filter media files by gallery. For ex. `gallery=participants` */
  gallery?: string;
};
export type MediaControllerCreateApiResponse = /** status 201  */ Media;
export type MediaControllerCreateRequest = {
  createMediaDto: CreateMediaDto;
};
export type MediaControllerDeleteApiResponse = /** status 200  */ Media;
export type MediaControllerDeleteRequest = {
  id: string;
};
export type MediaControllerCreateBulkApiResponse = /** status 201  */ Media[];
export type MediaControllerCreateBulkRequest = {
  bulkCreateMediaDto: BulkCreateMediaDto;
};
export type MediaControllerUploadApiResponse =
  /** status 201 File uploaded successfully */ UploadResponseDto;
export type MediaControllerUploadRequest = {
  body: {
    file?: Blob;
    path?: string;
  };
};
export type MediaControllerBulkUploadApiResponse =
  /** status 201 File uploaded successfully */ UploadResponseDto[];
export type MediaControllerBulkUploadRequest = {
  body: {
    files?: Blob[];
    path?: string;
  };
};
export type MediaControllerFetchExternalFileAndSaveToS3ApiResponse =
  /** status 201  */ string;
export type MediaControllerFetchExternalFileAndSaveToS3Request = {
  saveUrlToS3Dto: SaveUrlToS3Dto;
};
export type MediaControllerBatchFetchExternalFileAndSaveToS3ApiResponse =
  /** status 201  */ string[];
export type MediaControllerBatchFetchExternalFileAndSaveToS3Request = {
  saveUrlsToS3Dto: SaveUrlsToS3Dto;
};
export type Media = {
  _id: string;
  createdAt: string;
  updatedAt: string;
  study?: string;
  url: string;
  gallery?: string;
};
export type ApiUnauthorizedResponseDto = {
  statusCode: number;
  message: string;
};
export type ApiNotFoundResponseDto = {
  statusCode: number;
  message: string;
};
export type CreateMediaDto = {
  url: string;
  study?: string;
  gallery?: string;
};
export type BulkCreateMediaDto = {
  urls: string[];
  study?: string;
  gallery?: string;
};
export type UploadResponseDto = {
  url: string;
};
export type SaveUrlToS3Dto = {
  url: string;
  path: string;
};
export type SaveUrlsToS3Dto = {
  urls: string[];
  path: string;
};
export const {
  useMediaControllerFindAllQuery,
  useLazyMediaControllerFindAllQuery,
  useMediaControllerCreateMutation,
  useMediaControllerDeleteMutation,
  useMediaControllerCreateBulkMutation,
  useMediaControllerUploadMutation,
  useMediaControllerBulkUploadMutation,
  useMediaControllerFetchExternalFileAndSaveToS3Mutation,
  useMediaControllerBatchFetchExternalFileAndSaveToS3Mutation,
} = injectedRtkApi;
