export const events = {
  userSignedUp: "User signed up",
  userSignedIn: "User signed in",
  userEnteredInvalidInviteCode: "User entered an invalid invite code",
  userCreatedPost: "User created a post",
  userUnableToCreatePost: "User is unable to create a post",
  userCreatedComment: "User created a comment",
  userUnableToCreateComment: "User is unable to create a comment",
  userAddedImageToPost: "User added an image to a post",
  unableToUpdatesFromEAS: "Unable to perform EAS update",
  userReadTreatmentArmInstructions: "User read treatment arm instructions",
  userRefreshedFeed: "User refreshed feed",
  userNavigatedExternalLink: "User navigated to external link",
  userOpenedUrlFromApp: "User opened url from app",
  userClosedInAppBrowser: "User closed in app browser",
  userLoadedMoreNotifications: "User loaded more notifications",
  userReadNotification: "User read notification",
  userUnreadNotification: "User unread notification",
  userOpenedNotification: "User opened notification",
  userOpenedCommentsThead: "User opened comments thread",
  userFollowedProfile: "User followed profile",
  userUnfollowedProfile: "User unfollowed profile",
  userViewedProfile: "User viewed profile",
  userViewedOwnProfile: "User viewed own profile",
  useOpenedNewPostScreenFromProfile: "User opened new post screen from profile",
  userOpenedSinglePost: "User opened single post",
  userOpenedSettingsScreen: "User opened settings screen",
  userStartedSurvey: "User started survey",
  userCompletedSurvey: "User completed survey",
  userRedirectedFromAnExistingCompletedSurvey:
    "User redirected from an existing completed survey",
  userShownAnErrorMessage: "User is shown an error message",
  userCompletedProfileSetup: "User completed profile setup",
};
